.text-decoration-none {
  text-decoration: none;
}

.text-decoration-dashed {
  text-decoration: dashed;
}

.text-decoration-dotted {
  text-decoration: dotted;
}

.text-decoration-double {
  text-decoration: double;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-decoration-overline {
  text-decoration: overline;
}

.text-decoration-solid {
  text-decoration: solid;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-wavy {
  text-decoration: wavy;
}
