@import "./min-width.scss";
@import "./max-width.scss";
@import "./width-vw.scss";
@import "./width-vw-with-cal.scss";
@import "./width-percentage-with-cal.scss";
@import "./width-percentage.scss";
@import "./width-px.scss";

.width-inherit {
  width: inherit !important;
}
.width-auto {
  width: auto !important;
}
.width-max-content {
  width: max-content !important;
}

.width-fit-content {
  width: fit-content !important;
}
