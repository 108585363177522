.pl-0px,
.px-0px {
  padding-left: 0px !important;
}

.pl-1px,
.px-1px {
  padding-left: 1px !important;
}

.pl-2px,
.px-2px {
  padding-left: 2px !important;
}

.pl-3px,
.px-3px {
  padding-left: 3px !important;
}

.pl-4px,
.px-4px {
  padding-left: 4px !important;
}

.pl-5px,
.px-5px {
  padding-left: 5px !important;
}

.pl-6px,
.px-6px {
  padding-left: 6px !important;
}

.pl-7px,
.px-7px {
  padding-left: 7px !important;
}

.pl-8px,
.px-8px {
  padding-left: 8px !important;
}

.pl-9px,
.px-9px {
  padding-left: 9px !important;
}
.pl-9-point-6px,
.px-9-point-6px {
  padding-left: 9.6px !important;
}

.pl-10px,
.px-10px {
  padding-left: 10px !important;
}

.pl-11px,
.px-11px {
  padding-left: 11px !important;
}

.pl-12px,
.px-12px {
  padding-left: 12px !important;
}

.pl-13px,
.px-13px {
  padding-left: 13px !important;
}

.pl-14px,
.px-14px {
  padding-left: 14px !important;
}

.pl-15px,
.px-15px {
  padding-left: 15px !important;
}

.pl-16px,
.px-16px {
  padding-left: 16px !important;
}

.pl-17px,
.px-17px {
  padding-left: 17px !important;
}
.pl-17-point-6px,
.px-17-point-6px {
  padding-left: 17.6px !important;
}

.pl-18px,
.px-18px {
  padding-left: 18px !important;
}

.pl-19px,
.px-19px {
  padding-left: 19px !important;
}

.pl-20px,
.px-20px {
  padding-left: 20px !important;
}

.pl-21px,
.px-21px {
  padding-left: 21px !important;
}

.pl-22px,
.px-22px {
  padding-left: 22px !important;
}

.pl-23px,
.px-23px {
  padding-left: 23px !important;
}

.pl-24px,
.px-24px {
  padding-left: 24px !important;
}

.pl-25px,
.px-25px {
  padding-left: 25px !important;
}

.pl-26px,
.px-26px {
  padding-left: 26px !important;
}

.pl-27px,
.px-27px {
  padding-left: 27px !important;
}

.pl-28px,
.px-28px {
  padding-left: 28px !important;
}

.pl-29px,
.px-29px {
  padding-left: 29px !important;
}

.pl-30px,
.px-30px {
  padding-left: 30px !important;
}

.pl-31px,
.px-31px {
  padding-left: 31px !important;
}

.pl-32px,
.px-32px {
  padding-left: 32px !important;
}

.pl-33px,
.px-33px {
  padding-left: 33px !important;
}

.pl-34px,
.px-34px {
  padding-left: 34px !important;
}

.pl-35px,
.px-35px {
  padding-left: 35px !important;
}

.pl-36px,
.px-36px {
  padding-left: 36px !important;
}

.pl-37px,
.px-37px {
  padding-left: 37px !important;
}

.pl-38px,
.px-38px {
  padding-left: 38px !important;
}

.pl-39px,
.px-39px {
  padding-left: 39px !important;
}

.pl-40px,
.px-40px {
  padding-left: 40px !important;
}

.pl-41px,
.px-41px {
  padding-left: 41px !important;
}

.pl-42px,
.px-42px {
  padding-left: 42px !important;
}

.pl-43px,
.px-43px {
  padding-left: 43px !important;
}

.pl-44px,
.px-44px {
  padding-left: 44px !important;
}

.pl-45px,
.px-45px {
  padding-left: 45px !important;
}

.pl-46px,
.px-46px {
  padding-left: 46px !important;
}

.pl-47px,
.px-47px {
  padding-left: 47px !important;
}

.pl-48px,
.px-48px {
  padding-left: 48px !important;
}

.pl-49px,
.px-49px {
  padding-left: 49px !important;
}

.pl-50px,
.px-50px {
  padding-left: 50px !important;
}

.pl-51px,
.px-51px {
  padding-left: 51px !important;
}

.pl-52px,
.px-52px {
  padding-left: 52px !important;
}

.pl-53px,
.px-53px {
  padding-left: 53px !important;
}

.pl-54px,
.px-54px {
  padding-left: 54px !important;
}

.pl-55px,
.px-55px {
  padding-left: 55px !important;
}

.pl-56px,
.px-56px {
  padding-left: 56px !important;
}

.pl-57px,
.px-57px {
  padding-left: 57px !important;
}

.pl-58px,
.px-58px {
  padding-left: 58px !important;
}

.pl-59px,
.px-59px {
  padding-left: 59px !important;
}

.pl-60px,
.px-60px {
  padding-left: 60px !important;
}

.pl-61px,
.px-61px {
  padding-left: 61px !important;
}

.pl-62px,
.px-62px {
  padding-left: 62px !important;
}

.pl-63px,
.px-63px {
  padding-left: 63px !important;
}

.pl-64px,
.px-64px {
  padding-left: 64px !important;
}

.pl-65px,
.px-65px {
  padding-left: 65px !important;
}

.pl-66px,
.px-66px {
  padding-left: 66px !important;
}

.pl-67px,
.px-67px {
  padding-left: 67px !important;
}

.pl-68px,
.px-68px {
  padding-left: 68px !important;
}

.pl-69px,
.px-69px {
  padding-left: 69px !important;
}

.pl-70px,
.px-70px {
  padding-left: 70px !important;
}

.pl-71px,
.px-71px {
  padding-left: 71px !important;
}

.pl-72px,
.px-72px {
  padding-left: 72px !important;
}

.pl-73px,
.px-73px {
  padding-left: 73px !important;
}

.pl-74px,
.px-74px {
  padding-left: 74px !important;
}

.pl-75px,
.px-75px {
  padding-left: 75px !important;
}

.pl-76px,
.px-76px {
  padding-left: 76px !important;
}

.pl-77px,
.px-77px {
  padding-left: 77px !important;
}

.pl-78px,
.px-78px {
  padding-left: 78px !important;
}

.pl-79px,
.px-79px {
  padding-left: 79px !important;
}

.pl-80px,
.px-80px {
  padding-left: 80px !important;
}

.pl-81px,
.px-81px {
  padding-left: 81px !important;
}

.pl-82px,
.px-82px {
  padding-left: 82px !important;
}

.pl-83px,
.px-83px {
  padding-left: 83px !important;
}

.pl-84px,
.px-84px {
  padding-left: 84px !important;
}

.pl-85px,
.px-85px {
  padding-left: 85px !important;
}

.pl-86px,
.px-86px {
  padding-left: 86px !important;
}

.pl-87px,
.px-87px {
  padding-left: 87px !important;
}

.pl-88px,
.px-88px {
  padding-left: 88px !important;
}

.pl-89px,
.px-89px {
  padding-left: 89px !important;
}

.pl-90px,
.px-90px {
  padding-left: 90px !important;
}

.pl-91px,
.px-91px {
  padding-left: 91px !important;
}

.pl-92px,
.px-92px {
  padding-left: 92px !important;
}

.pl-93px,
.px-93px {
  padding-left: 93px !important;
}

.pl-94px,
.px-94px {
  padding-left: 94px !important;
}

.pl-95px,
.px-95px {
  padding-left: 95px !important;
}

.pl-96px,
.px-96px {
  padding-left: 96px !important;
}

.pl-97px,
.px-97px {
  padding-left: 97px !important;
}

.pl-98px,
.px-98px {
  padding-left: 98px !important;
}

.pl-99px,
.px-99px {
  padding-left: 99px !important;
}

.pl-100px,
.px-100px {
  padding-left: 100px !important;
}

.pl-101px,
.px-101px {
  padding-left: 101px !important;
}

.pl-102px,
.px-102px {
  padding-left: 102px !important;
}

.pl-103px,
.px-103px {
  padding-left: 103px !important;
}

.pl-104px,
.px-104px {
  padding-left: 104px !important;
}

.pl-105px,
.px-105px {
  padding-left: 105px !important;
}

.pl-106px,
.px-106px {
  padding-left: 106px !important;
}

.pl-107px,
.px-107px {
  padding-left: 107px !important;
}

.pl-108px,
.px-108px {
  padding-left: 108px !important;
}

.pl-109px,
.px-109px {
  padding-left: 109px !important;
}

.pl-110px,
.px-110px {
  padding-left: 110px !important;
}

.pl-111px,
.px-111px {
  padding-left: 111px !important;
}

.pl-112px,
.px-112px {
  padding-left: 112px !important;
}

.pl-113px,
.px-113px {
  padding-left: 113px !important;
}

.pl-114px,
.px-114px {
  padding-left: 114px !important;
}

.pl-115px,
.px-115px {
  padding-left: 115px !important;
}

.pl-116px,
.px-116px {
  padding-left: 116px !important;
}

.pl-117px,
.px-117px {
  padding-left: 117px !important;
}

.pl-118px,
.px-118px {
  padding-left: 118px !important;
}

.pl-119px,
.px-119px {
  padding-left: 119px !important;
}

.pl-120px,
.px-120px {
  padding-left: 120px !important;
}

.pl-121px,
.px-121px {
  padding-left: 121px !important;
}

.pl-122px,
.px-122px {
  padding-left: 122px !important;
}

.pl-123px,
.px-123px {
  padding-left: 123px !important;
}

.pl-124px,
.px-124px {
  padding-left: 124px !important;
}

.pl-125px,
.px-125px {
  padding-left: 125px !important;
}

.pl-126px,
.px-126px {
  padding-left: 126px !important;
}

.pl-127px,
.px-127px {
  padding-left: 127px !important;
}

.pl-128px,
.px-128px {
  padding-left: 128px !important;
}

.pl-129px,
.px-129px {
  padding-left: 129px !important;
}

.pl-130px,
.px-130px {
  padding-left: 130px !important;
}

.pl-131px,
.px-131px {
  padding-left: 131px !important;
}

.pl-132px,
.px-132px {
  padding-left: 132px !important;
}

.pl-133px,
.px-133px {
  padding-left: 133px !important;
}

.pl-134px,
.px-134px {
  padding-left: 134px !important;
}

.pl-135px,
.px-135px {
  padding-left: 135px !important;
}

.pl-136px,
.px-136px {
  padding-left: 136px !important;
}

.pl-137px,
.px-137px {
  padding-left: 137px !important;
}

.pl-138px,
.px-138px {
  padding-left: 138px !important;
}

.pl-139px,
.px-139px {
  padding-left: 139px !important;
}

.pl-140px,
.px-140px {
  padding-left: 140px !important;
}

.pl-141px,
.px-141px {
  padding-left: 141px !important;
}

.pl-142px,
.px-142px {
  padding-left: 142px !important;
}

.pl-143px,
.px-143px {
  padding-left: 143px !important;
}

.pl-144px,
.px-144px {
  padding-left: 144px !important;
}

.pl-145px,
.px-145px {
  padding-left: 145px !important;
}

.pl-146px,
.px-146px {
  padding-left: 146px !important;
}

.pl-147px,
.px-147px {
  padding-left: 147px !important;
}

.pl-148px,
.px-148px {
  padding-left: 148px !important;
}

.pl-149px,
.px-149px {
  padding-left: 149px !important;
}

.pl-150px,
.px-150px {
  padding-left: 150px !important;
}

.pl-151px,
.px-151px {
  padding-left: 151px !important;
}

.pl-152px,
.px-152px {
  padding-left: 152px !important;
}

.pl-153px,
.px-153px {
  padding-left: 153px !important;
}

.pl-154px,
.px-154px {
  padding-left: 154px !important;
}

.pl-155px,
.px-155px {
  padding-left: 155px !important;
}

.pl-156px,
.px-156px {
  padding-left: 156px !important;
}

.pl-157px,
.px-157px {
  padding-left: 157px !important;
}

.pl-158px,
.px-158px {
  padding-left: 158px !important;
}

.pl-159px,
.px-159px {
  padding-left: 159px !important;
}

.pl-160px,
.px-160px {
  padding-left: 160px !important;
}

.pl-161px,
.px-161px {
  padding-left: 161px !important;
}

.pl-162px,
.px-162px {
  padding-left: 162px !important;
}

.pl-163px,
.px-163px {
  padding-left: 163px !important;
}

.pl-164px,
.px-164px {
  padding-left: 164px !important;
}

.pl-165px,
.px-165px {
  padding-left: 165px !important;
}

.pl-166px,
.px-166px {
  padding-left: 166px !important;
}

.pl-167px,
.px-167px {
  padding-left: 167px !important;
}

.pl-168px,
.px-168px {
  padding-left: 168px !important;
}

.pl-169px,
.px-169px {
  padding-left: 169px !important;
}

.pl-170px,
.px-170px {
  padding-left: 170px !important;
}

.pl-171px,
.px-171px {
  padding-left: 171px !important;
}

.pl-172px,
.px-172px {
  padding-left: 172px !important;
}

.pl-173px,
.px-173px {
  padding-left: 173px !important;
}

.pl-174px,
.px-174px {
  padding-left: 174px !important;
}

.pl-175px,
.px-175px {
  padding-left: 175px !important;
}

.pl-176px,
.px-176px {
  padding-left: 176px !important;
}

.pl-177px,
.px-177px {
  padding-left: 177px !important;
}

.pl-178px,
.px-178px {
  padding-left: 178px !important;
}

.pl-179px,
.px-179px {
  padding-left: 179px !important;
}

.pl-180px,
.px-180px {
  padding-left: 180px !important;
}

.pl-181px,
.px-181px {
  padding-left: 181px !important;
}

.pl-182px,
.px-182px {
  padding-left: 182px !important;
}

.pl-183px,
.px-183px {
  padding-left: 183px !important;
}

.pl-184px,
.px-184px {
  padding-left: 184px !important;
}

.pl-185px,
.px-185px {
  padding-left: 185px !important;
}

.pl-186px,
.px-186px {
  padding-left: 186px !important;
}

.pl-187px,
.px-187px {
  padding-left: 187px !important;
}

.pl-188px,
.px-188px {
  padding-left: 188px !important;
}

.pl-189px,
.px-189px {
  padding-left: 189px !important;
}

.pl-190px,
.px-190px {
  padding-left: 190px !important;
}

.pl-191px,
.px-191px {
  padding-left: 191px !important;
}

.pl-192px,
.px-192px {
  padding-left: 192px !important;
}

.pl-193px,
.px-193px {
  padding-left: 193px !important;
}

.pl-194px,
.px-194px {
  padding-left: 194px !important;
}

.pl-195px,
.px-195px {
  padding-left: 195px !important;
}

.pl-196px,
.px-196px {
  padding-left: 196px !important;
}

.pl-197px,
.px-197px {
  padding-left: 197px !important;
}

.pl-198px,
.px-198px {
  padding-left: 198px !important;
}

.pl-199px,
.px-199px {
  padding-left: 199px !important;
}

.pl-200px,
.px-200px {
  padding-left: 200px !important;
}

// percent
.pl-0-percent {
  padding-left: 0% !important;
}
.pl-1-percent {
  padding-left: 1% !important;
}
.pl-2-percent {
  padding-left: 2% !important;
}
.pl-3-percent {
  padding-left: 3% !important;
}
.pl-4-percent {
  padding-left: 4% !important;
}
.pl-5-percent {
  padding-left: 5% !important;
}
.pl-6-percent {
  padding-left: 6% !important;
}
.pl-7-percent {
  padding-left: 7% !important;
}
.pl-8-percent {
  padding-left: 8% !important;
}
.pl-9-percent {
  padding-left: 9% !important;
}
.pl-10-percent {
  padding-left: 10% !important;
}
.pl-11-percent {
  padding-left: 11% !important;
}
.pl-12-percent {
  padding-left: 12% !important;
}
.pl-13-percent {
  padding-left: 13% !important;
}
.pl-14-percent {
  padding-left: 14% !important;
}
.pl-15-percent {
  padding-left: 15% !important;
}
.pl-16-percent {
  padding-left: 16% !important;
}
.pl-17-percent {
  padding-left: 17% !important;
}
.pl-18-percent {
  padding-left: 18% !important;
}
.pl-19-percent {
  padding-left: 19% !important;
}
.pl-20-percent {
  padding-left: 20% !important;
}
.pl-21-percent {
  padding-left: 21% !important;
}
.pl-22-percent {
  padding-left: 22% !important;
}
.pl-23-percent {
  padding-left: 23% !important;
}
.pl-24-percent {
  padding-left: 24% !important;
}
.pl-25-percent {
  padding-left: 25% !important;
}
.pl-26-percent {
  padding-left: 26% !important;
}
.pl-27-percent {
  padding-left: 27% !important;
}
.pl-28-percent {
  padding-left: 28% !important;
}
.pl-29-percent {
  padding-left: 29% !important;
}
.pl-30-percent {
  padding-left: 30% !important;
}
.pl-31-percent {
  padding-left: 31% !important;
}
.pl-32-percent {
  padding-left: 32% !important;
}
.pl-33-percent {
  padding-left: 33% !important;
}
.pl-34-percent {
  padding-left: 34% !important;
}
.pl-35-percent {
  padding-left: 35% !important;
}
.pl-36-percent {
  padding-left: 36% !important;
}
.pl-37-percent {
  padding-left: 37% !important;
}
.pl-38-percent {
  padding-left: 38% !important;
}
.pl-39-percent {
  padding-left: 39% !important;
}
.pl-40-percent {
  padding-left: 40% !important;
}
.pl-41-percent {
  padding-left: 41% !important;
}
.pl-42-percent {
  padding-left: 42% !important;
}
.pl-43-percent {
  padding-left: 43% !important;
}
.pl-44-percent {
  padding-left: 44% !important;
}
.pl-45-percent {
  padding-left: 45% !important;
}
.pl-46-percent {
  padding-left: 46% !important;
}
.pl-47-percent {
  padding-left: 47% !important;
}
.pl-48-percent {
  padding-left: 48% !important;
}
.pl-49-percent {
  padding-left: 49% !important;
}
.pl-50-percent {
  padding-left: 50% !important;
}
.pl-51-percent {
  padding-left: 51% !important;
}
.pl-52-percent {
  padding-left: 52% !important;
}
.pl-53-percent {
  padding-left: 53% !important;
}
.pl-54-percent {
  padding-left: 54% !important;
}
.pl-55-percent {
  padding-left: 55% !important;
}
.pl-56-percent {
  padding-left: 56% !important;
}
.pl-57-percent {
  padding-left: 57% !important;
}
.pl-58-percent {
  padding-left: 58% !important;
}
.pl-59-percent {
  padding-left: 59% !important;
}
.pl-60-percent {
  padding-left: 60% !important;
}
.pl-61-percent {
  padding-left: 61% !important;
}
.pl-62-percent {
  padding-left: 62% !important;
}
.pl-63-percent {
  padding-left: 63% !important;
}
.pl-64-percent {
  padding-left: 64% !important;
}
.pl-65-percent {
  padding-left: 65% !important;
}
.pl-66-percent {
  padding-left: 66% !important;
}
.pl-67-percent {
  padding-left: 67% !important;
}
.pl-68-percent {
  padding-left: 68% !important;
}
.pl-69-percent {
  padding-left: 69% !important;
}
.pl-70-percent {
  padding-left: 70% !important;
}
.pl-71-percent {
  padding-left: 71% !important;
}
.pl-72-percent {
  padding-left: 72% !important;
}
.pl-73-percent {
  padding-left: 73% !important;
}
.pl-74-percent {
  padding-left: 74% !important;
}
.pl-75-percent {
  padding-left: 75% !important;
}
.pl-76-percent {
  padding-left: 76% !important;
}
.pl-77-percent {
  padding-left: 77% !important;
}
.pl-78-percent {
  padding-left: 78% !important;
}
.pl-79-percent {
  padding-left: 79% !important;
}
.pl-80-percent {
  padding-left: 80% !important;
}
.pl-81-percent {
  padding-left: 81% !important;
}
.pl-82-percent {
  padding-left: 82% !important;
}
.pl-83-percent {
  padding-left: 83% !important;
}
.pl-84-percent {
  padding-left: 84% !important;
}
.pl-85-percent {
  padding-left: 85% !important;
}
.pl-86-percent {
  padding-left: 86% !important;
}
.pl-87-percent {
  padding-left: 87% !important;
}
.pl-88-percent {
  padding-left: 88% !important;
}
.pl-89-percent {
  padding-left: 89% !important;
}
.pl-90-percent {
  padding-left: 90% !important;
}
.pl-91-percent {
  padding-left: 91% !important;
}
.pl-92-percent {
  padding-left: 92% !important;
}
.pl-93-percent {
  padding-left: 93% !important;
}
.pl-94-percent {
  padding-left: 94% !important;
}
.pl-95-percent {
  padding-left: 95% !important;
}
.pl-96-percent {
  padding-left: 96% !important;
}
.pl-97-percent {
  padding-left: 97% !important;
}
.pl-98-percent {
  padding-left: 98% !important;
}
.pl-99-percent {
  padding-left: 99% !important;
}
.pl-100-percent {
  padding-left: 100% !important;
}

// rem
.pl-1-point-1rem,
.px-1-point-1rem {
  padding-left: 1.1rem !important;
}
