/* Height of % with cal*/
.h-100-50px {
  height: calc(100% - 50px) !important;
}

.h-100-58px {
  height: calc(100% - 58px) !important;
}

.h-100-70px {
  height: calc(100% - 70px) !important;
}

.h-100-73px {
  height: calc(100% - 73px) !important;
}

.h-100-80px {
  height: calc(100% - 80px) !important;
}

.h-100-120px {
  height: calc(100% - 120px) !important;
}

.h-100-155px {
  height: calc(100% - 155px) !important;
}

.h-100-160px {
  height: calc(100% - 160px) !important;
}

.h-100-161px {
  height: calc(100% - 161px) !important;
}

.h-100-162px {
  height: calc(100% - 162px) !important;
}

.h-100-163px {
  height: calc(100% - 163px) !important;
}

.h-100-170px {
  height: calc(100% - 170px) !important;
}

.h-100-176px {
  height: calc(100% - 176px) !important;
}

.h-100-180px {
  height: calc(100% - 180px) !important;
}

.h-100-195px {
  height: calc(100% - 195px) !important;
}

.h-100-200px {
  height: calc(100% - 200px) !important;
}

.h-100-220px {
  height: calc(100% - 220px) !important;
}

.h-100-230px {
  height: calc(100% - 230px) !important;
}

.h-100-250px {
  height: calc(100% - 250px) !important;
}

.h-100-400px {
  height: calc(100% - 400px) !important;
}

.h-100-450px {
  height: calc(100% - 450px) !important;
}

.h-100-510px {
  height: calc(100% - 510px) !important;
}
