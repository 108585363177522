.min-height-inherit {
  min-height: inherit;
}

.h-100-mn {
  min-height: 100% !important;
}

/* In vh */
.h-15vh-mn {
  min-height: 15vh !important;
}
.h-30vh-mn {
  min-height: 30vh !important;
}
.h-35vh-mn {
  min-height: 35vh !important;
}
.h-45vh-mn {
  min-height: 45vh !important;
}
.h-50vh-mn {
  min-height: 50vh !important;
}
.h-100vh-mn {
  min-height: 100vh !important;
}

/* In px*/
.h-0px-mn {
  min-height: 0px !important;
}
.h-38px-mn {
  min-height: 38px !important;
}
.h-40px-mn {
  min-height: 40px !important;
}
.h-50px-mn {
  min-height: 50px !important;
}
.h-200px-mn {
  min-height: 200px !important;
}
.h-350px-mn {
  min-height: 350px !important;
}
.h-500px-mn {
  min-height: 500px !important;
}

// calc with vh
.h-vh-100px-mn {
  min-height: calc(100vh - 100px) !important;
}
.h-vh-195px-mn {
  min-height: calc(100vh - 195px) !important;
}
.h-vh-200px-mn {
  min-height: calc(100vh - 200px) !important;
}
.h-vh-300px-mn {
  min-height: calc(100vh - 300px) !important;
}
