// left
.left-0 {
  left: 0 !important;
}
.left-10px {
  left: 10px !important;
}
.left-30px {
  left: 30px !important;
}

.left-88-percent {
  left: 88% !important;
}
.left-minus-90-percent {
  left: -90% !important;
}
