.pt-0px,
.py-0px {
  padding-top: 0px !important;
}

.pt-1px,
.py-1px {
  padding-top: 1px !important;
}

.pt-2px,
.py-2px {
  padding-top: 2px !important;
}

.pt-3px,
.py-3px {
  padding-top: 3px !important;
}

.pt-4px,
.py-4px {
  padding-top: 4px !important;
}

.pt-5px,
.py-5px {
  padding-top: 5px !important;
}

.pt-6px,
.py-6px {
  padding-top: 6px !important;
}

.pt-7px,
.py-7px {
  padding-top: 7px !important;
}

.pt-7px,
.py-7px {
  padding-top: 7px !important;
}

.pt-7-point-5px,
.py-7-point-5px {
  padding-top: 7.5px !important;
}

.pt-8px,
.py-8px {
  padding-top: 8px !important;
}

.pt-9px,
.py-9px {
  padding-top: 9px !important;
}

.pt-10px,
.py-10px {
  padding-top: 10px !important;
}
.pt-10-point-4px,
.py-10-point-4px {
  padding-top: 10.4px !important;
}

.pt-11px,
.py-11px {
  padding-top: 11px !important;
}

.pt-12px,
.py-12px {
  padding-top: 12px !important;
}

.pt-13px,
.py-13px {
  padding-top: 13px !important;
}

.pt-14px,
.py-14px {
  padding-top: 14px !important;
}

.pt-15px,
.py-15px {
  padding-top: 15px !important;
}

.pt-16px,
.py-16px {
  padding-top: 16px !important;
}

.pt-17px,
.py-17px {
  padding-top: 17px !important;
}

.pt-18px,
.py-18px {
  padding-top: 18px !important;
}

.pt-19px,
.py-19px {
  padding-top: 19px !important;
}

.pt-20px,
.py-20px {
  padding-top: 20px !important;
}

.pt-21px,
.py-21px {
  padding-top: 21px !important;
}

.pt-22px,
.py-22px {
  padding-top: 22px !important;
}

.pt-23px,
.py-23px {
  padding-top: 23px !important;
}

.pt-24px,
.py-24px {
  padding-top: 24px !important;
}

.pt-25px,
.py-25px {
  padding-top: 25px !important;
}

.pt-26px,
.py-26px {
  padding-top: 26px !important;
}

.pt-27px,
.py-27px {
  padding-top: 27px !important;
}

.pt-28px,
.py-28px {
  padding-top: 28px !important;
}

.pt-29px,
.py-29px {
  padding-top: 29px !important;
}

.pt-30px,
.py-30px {
  padding-top: 30px !important;
}

.pt-31px,
.py-31px {
  padding-top: 31px !important;
}

.pt-32px,
.py-32px {
  padding-top: 32px !important;
}

.pt-33px,
.py-33px {
  padding-top: 33px !important;
}

.pt-34px,
.py-34px {
  padding-top: 34px !important;
}

.pt-35px,
.py-35px {
  padding-top: 35px !important;
}

.pt-36px,
.py-36px {
  padding-top: 36px !important;
}

.pt-37px,
.py-37px {
  padding-top: 37px !important;
}

.pt-38px,
.py-38px {
  padding-top: 38px !important;
}

.pt-39px,
.py-39px {
  padding-top: 39px !important;
}

.pt-40px,
.py-40px {
  padding-top: 40px !important;
}

.pt-41px,
.py-41px {
  padding-top: 41px !important;
}

.pt-42px,
.py-42px {
  padding-top: 42px !important;
}

.pt-43px,
.py-43px {
  padding-top: 43px !important;
}

.pt-44px,
.py-44px {
  padding-top: 44px !important;
}

.pt-45px,
.py-45px {
  padding-top: 45px !important;
}

.pt-46px,
.py-46px {
  padding-top: 46px !important;
}

.pt-47px,
.py-47px {
  padding-top: 47px !important;
}

.pt-48px,
.py-48px {
  padding-top: 48px !important;
}

.pt-49px,
.py-49px {
  padding-top: 49px !important;
}

.pt-50px,
.py-50px {
  padding-top: 50px !important;
}

.pt-51px,
.py-51px {
  padding-top: 51px !important;
}

.pt-52px,
.py-52px {
  padding-top: 52px !important;
}

.pt-53px,
.py-53px {
  padding-top: 53px !important;
}

.pt-54px,
.py-54px {
  padding-top: 54px !important;
}

.pt-55px,
.py-55px {
  padding-top: 55px !important;
}

.pt-56px,
.py-56px {
  padding-top: 56px !important;
}

.pt-57px,
.py-57px {
  padding-top: 57px !important;
}

.pt-58px,
.py-58px {
  padding-top: 58px !important;
}

.pt-59px,
.py-59px {
  padding-top: 59px !important;
}

.pt-60px,
.py-60px {
  padding-top: 60px !important;
}

.pt-61px,
.py-61px {
  padding-top: 61px !important;
}

.pt-62px,
.py-62px {
  padding-top: 62px !important;
}

.pt-63px,
.py-63px {
  padding-top: 63px !important;
}

.pt-64px,
.py-64px {
  padding-top: 64px !important;
}

.pt-65px,
.py-65px {
  padding-top: 65px !important;
}

.pt-66px,
.py-66px {
  padding-top: 66px !important;
}

.pt-67px,
.py-67px {
  padding-top: 67px !important;
}

.pt-68px,
.py-68px {
  padding-top: 68px !important;
}

.pt-69px,
.py-69px {
  padding-top: 69px !important;
}

.pt-70px,
.py-70px {
  padding-top: 70px !important;
}

.pt-71px,
.py-71px {
  padding-top: 71px !important;
}

.pt-72px,
.py-72px {
  padding-top: 72px !important;
}

.pt-73px,
.py-73px {
  padding-top: 73px !important;
}

.pt-74px,
.py-74px {
  padding-top: 74px !important;
}

.pt-75px,
.py-75px {
  padding-top: 75px !important;
}

.pt-76px,
.py-76px {
  padding-top: 76px !important;
}

.pt-77px,
.py-77px {
  padding-top: 77px !important;
}

.pt-78px,
.py-78px {
  padding-top: 78px !important;
}

.pt-79px,
.py-79px {
  padding-top: 79px !important;
}

.pt-80px,
.py-80px {
  padding-top: 80px !important;
}

.pt-81px,
.py-81px {
  padding-top: 81px !important;
}

.pt-82px,
.py-82px {
  padding-top: 82px !important;
}

.pt-83px,
.py-83px {
  padding-top: 83px !important;
}

.pt-84px,
.py-84px {
  padding-top: 84px !important;
}

.pt-85px,
.py-85px {
  padding-top: 85px !important;
}

.pt-86px,
.py-86px {
  padding-top: 86px !important;
}

.pt-87px,
.py-87px {
  padding-top: 87px !important;
}

.pt-88px,
.py-88px {
  padding-top: 88px !important;
}

.pt-89px,
.py-89px {
  padding-top: 89px !important;
}

.pt-90px,
.py-90px {
  padding-top: 90px !important;
}

.pt-91px,
.py-91px {
  padding-top: 91px !important;
}

.pt-92px,
.py-92px {
  padding-top: 92px !important;
}

.pt-93px,
.py-93px {
  padding-top: 93px !important;
}

.pt-94px,
.py-94px {
  padding-top: 94px !important;
}

.pt-95px,
.py-95px {
  padding-top: 95px !important;
}

.pt-96px,
.py-96px {
  padding-top: 96px !important;
}

.pt-97px,
.py-97px {
  padding-top: 97px !important;
}

.pt-98px,
.py-98px {
  padding-top: 98px !important;
}

.pt-99px,
.py-99px {
  padding-top: 99px !important;
}

.pt-100px,
.py-100px {
  padding-top: 100px !important;
}

.pt-101px,
.py-101px {
  padding-top: 101px !important;
}

.pt-102px,
.py-102px {
  padding-top: 102px !important;
}

.pt-103px,
.py-103px {
  padding-top: 103px !important;
}

.pt-104px,
.py-104px {
  padding-top: 104px !important;
}

.pt-105px,
.py-105px {
  padding-top: 105px !important;
}

.pt-106px,
.py-106px {
  padding-top: 106px !important;
}

.pt-107px,
.py-107px {
  padding-top: 107px !important;
}

.pt-108px,
.py-108px {
  padding-top: 108px !important;
}

.pt-109px,
.py-109px {
  padding-top: 109px !important;
}

.pt-110px,
.py-110px {
  padding-top: 110px !important;
}

.pt-111px,
.py-111px {
  padding-top: 111px !important;
}

.pt-112px,
.py-112px {
  padding-top: 112px !important;
}

.pt-113px,
.py-113px {
  padding-top: 113px !important;
}

.pt-114px,
.py-114px {
  padding-top: 114px !important;
}

.pt-115px,
.py-115px {
  padding-top: 115px !important;
}

.pt-116px,
.py-116px {
  padding-top: 116px !important;
}

.pt-117px,
.py-117px {
  padding-top: 117px !important;
}

.pt-118px,
.py-118px {
  padding-top: 118px !important;
}

.pt-119px,
.py-119px {
  padding-top: 119px !important;
}

.pt-120px,
.py-120px {
  padding-top: 120px !important;
}

.pt-121px,
.py-121px {
  padding-top: 121px !important;
}

.pt-122px,
.py-122px {
  padding-top: 122px !important;
}

.pt-123px,
.py-123px {
  padding-top: 123px !important;
}

.pt-124px,
.py-124px {
  padding-top: 124px !important;
}

.pt-125px,
.py-125px {
  padding-top: 125px !important;
}

.pt-126px,
.py-126px {
  padding-top: 126px !important;
}

.pt-127px,
.py-127px {
  padding-top: 127px !important;
}

.pt-128px,
.py-128px {
  padding-top: 128px !important;
}

.pt-129px,
.py-129px {
  padding-top: 129px !important;
}

.pt-130px,
.py-130px {
  padding-top: 130px !important;
}

.pt-131px,
.py-131px {
  padding-top: 131px !important;
}

.pt-132px,
.py-132px {
  padding-top: 132px !important;
}

.pt-133px,
.py-133px {
  padding-top: 133px !important;
}

.pt-134px,
.py-134px {
  padding-top: 134px !important;
}

.pt-135px,
.py-135px {
  padding-top: 135px !important;
}

.pt-136px,
.py-136px {
  padding-top: 136px !important;
}

.pt-137px,
.py-137px {
  padding-top: 137px !important;
}

.pt-138px,
.py-138px {
  padding-top: 138px !important;
}

.pt-139px,
.py-139px {
  padding-top: 139px !important;
}

.pt-140px,
.py-140px {
  padding-top: 140px !important;
}

.pt-141px,
.py-141px {
  padding-top: 141px !important;
}

.pt-142px,
.py-142px {
  padding-top: 142px !important;
}

.pt-143px,
.py-143px {
  padding-top: 143px !important;
}

.pt-144px,
.py-144px {
  padding-top: 144px !important;
}

.pt-145px,
.py-145px {
  padding-top: 145px !important;
}

.pt-146px,
.py-146px {
  padding-top: 146px !important;
}

.pt-147px,
.py-147px {
  padding-top: 147px !important;
}

.pt-148px,
.py-148px {
  padding-top: 148px !important;
}

.pt-149px,
.py-149px {
  padding-top: 149px !important;
}

.pt-150px,
.py-150px {
  padding-top: 150px !important;
}

.pt-151px,
.py-151px {
  padding-top: 151px !important;
}

.pt-152px,
.py-152px {
  padding-top: 152px !important;
}

.pt-153px,
.py-153px {
  padding-top: 153px !important;
}

.pt-154px,
.py-154px {
  padding-top: 154px !important;
}

.pt-155px,
.py-155px {
  padding-top: 155px !important;
}

.pt-156px,
.py-156px {
  padding-top: 156px !important;
}

.pt-157px,
.py-157px {
  padding-top: 157px !important;
}

.pt-158px,
.py-158px {
  padding-top: 158px !important;
}

.pt-159px,
.py-159px {
  padding-top: 159px !important;
}

.pt-160px,
.py-160px {
  padding-top: 160px !important;
}

.pt-161px,
.py-161px {
  padding-top: 161px !important;
}

.pt-162px,
.py-162px {
  padding-top: 162px !important;
}

.pt-163px,
.py-163px {
  padding-top: 163px !important;
}

.pt-164px,
.py-164px {
  padding-top: 164px !important;
}

.pt-165px,
.py-165px {
  padding-top: 165px !important;
}

.pt-166px,
.py-166px {
  padding-top: 166px !important;
}

.pt-167px,
.py-167px {
  padding-top: 167px !important;
}

.pt-168px,
.py-168px {
  padding-top: 168px !important;
}

.pt-169px,
.py-169px {
  padding-top: 169px !important;
}

.pt-170px,
.py-170px {
  padding-top: 170px !important;
}

.pt-171px,
.py-171px {
  padding-top: 171px !important;
}

.pt-172px,
.py-172px {
  padding-top: 172px !important;
}

.pt-173px,
.py-173px {
  padding-top: 173px !important;
}

.pt-174px,
.py-174px {
  padding-top: 174px !important;
}

.pt-175px,
.py-175px {
  padding-top: 175px !important;
}

.pt-176px,
.py-176px {
  padding-top: 176px !important;
}

.pt-177px,
.py-177px {
  padding-top: 177px !important;
}

.pt-178px,
.py-178px {
  padding-top: 178px !important;
}

.pt-179px,
.py-179px {
  padding-top: 179px !important;
}

.pt-180px,
.py-180px {
  padding-top: 180px !important;
}

.pt-181px,
.py-181px {
  padding-top: 181px !important;
}

.pt-182px,
.py-182px {
  padding-top: 182px !important;
}

.pt-183px,
.py-183px {
  padding-top: 183px !important;
}

.pt-184px,
.py-184px {
  padding-top: 184px !important;
}

.pt-185px,
.py-185px {
  padding-top: 185px !important;
}

.pt-186px,
.py-186px {
  padding-top: 186px !important;
}

.pt-187px,
.py-187px {
  padding-top: 187px !important;
}

.pt-188px,
.py-188px {
  padding-top: 188px !important;
}

.pt-189px,
.py-189px {
  padding-top: 189px !important;
}

.pt-190px,
.py-190px {
  padding-top: 190px !important;
}

.pt-191px,
.py-191px {
  padding-top: 191px !important;
}

.pt-192px,
.py-192px {
  padding-top: 192px !important;
}

.pt-193px,
.py-193px {
  padding-top: 193px !important;
}

.pt-194px,
.py-194px {
  padding-top: 194px !important;
}

.pt-195px,
.py-195px {
  padding-top: 195px !important;
}

.pt-196px,
.py-196px {
  padding-top: 196px !important;
}

.pt-197px,
.py-197px {
  padding-top: 197px !important;
}

.pt-198px,
.py-198px {
  padding-top: 198px !important;
}

.pt-199px,
.py-199px {
  padding-top: 199px !important;
}

.pt-200px,
.py-200px {
  padding-top: 200px !important;
}

// rem
.pt-0-point-65rem,
.py-0-point-65rem {
  padding-top: 0.65rem !important;
}
