/* Width of vw with cal*/
.w-vw-50px {
    width: calc(100vw - 50px) !important;
}

.w-vw-58px {
    width: calc(100vw - 58px) !important;
}

.w-vw-70px {
    width: calc(100vw - 70px) !important;
}

.w-vw-73px {
    width: calc(100vw - 73px) !important;
}

.w-vw-80px {
    width: calc(100vw - 80px) !important;
}

.w-vw-120px {
    width: calc(100vw - 120px) !important;
}

.w-vw-155px {
    width: calc(100vw - 155px) !important;
}

.w-vw-160px {
    width: calc(100vw - 160px) !important;
}

.w-vw-161px {
    width: calc(100vw - 161px) !important;
}

.w-vw-162px {
    width: calc(100vw - 162px) !important;
}

.w-vw-163px {
    width: calc(100vw - 163px) !important;
}

.w-vw-170px {
    width: calc(100vw - 170px) !important;
}

.w-vw-176px {
    width: calc(100vw - 176px) !important;
}

.w-vw-180px {
    width: calc(100vw - 180px) !important;
}

.w-vw-195px {
    width: calc(100vw - 195px) !important;
}

.w-vw-200px {
    width: calc(100vw - 200px) !important;
}

.w-vw-220px {
    width: calc(100vw - 220px) !important;
}

.w-vw-230px {
    width: calc(100vw - 230px) !important;
}

.w-vw-250px {
    width: calc(100vw - 250px) !important;
}

.w-vw-400px {
    width: calc(100vw - 400px) !important;
}

.w-vw-450px {
    width: calc(100vw - 450px) !important;
}

.w-vw-510px {
    width: calc(100vw - 510px) !important;
}