.line-height-1-point-15 {
  line-height: 1.15 !important;
}

.line-height-24px {
  line-height: 24px !important;
}

.line-height-30px {
  line-height: 30px !important;
}
