.pb-0px,
.py-0px {
  padding-bottom: 0px !important;
}

.pb-1px,
.py-1px {
  padding-bottom: 1px !important;
}

.pb-2px,
.py-2px {
  padding-bottom: 2px !important;
}

.pb-3px,
.py-3px {
  padding-bottom: 3px !important;
}

.pb-4px,
.py-4px {
  padding-bottom: 4px !important;
}

.pb-5px,
.py-5px {
  padding-bottom: 5px !important;
}

.pb-6px,
.py-6px {
  padding-bottom: 6px !important;
}

.pb-7px,
.py-7px {
  padding-bottom: 7px !important;
}
.pb-7-point-5px,
.py-7-point-5px {
  padding-bottom: 7.5px !important;
}

.pb-8px,
.py-8px {
  padding-bottom: 8px !important;
}

.pb-9px,
.py-9px {
  padding-bottom: 9px !important;
}

.pb-10px,
.py-10px {
  padding-bottom: 10px !important;
}
.pb-10-point-4px,
.py-10-point-4px {
  padding-bottom: 10.4px !important;
}

.pb-11px,
.py-11px {
  padding-bottom: 11px !important;
}

.pb-12px,
.py-12px {
  padding-bottom: 12px !important;
}

.pb-13px,
.py-13px {
  padding-bottom: 13px !important;
}

.pb-14px,
.py-14px {
  padding-bottom: 14px !important;
}

.pb-15px,
.py-15px {
  padding-bottom: 15px !important;
}

.pb-16px,
.py-16px {
  padding-bottom: 16px !important;
}

.pb-17px,
.py-17px {
  padding-bottom: 17px !important;
}

.pb-18px,
.py-18px {
  padding-bottom: 18px !important;
}

.pb-19px,
.py-19px {
  padding-bottom: 19px !important;
}

.pb-20px,
.py-20px {
  padding-bottom: 20px !important;
}

.pb-21px,
.py-21px {
  padding-bottom: 21px !important;
}

.pb-22px,
.py-22px {
  padding-bottom: 22px !important;
}

.pb-23px,
.py-23px {
  padding-bottom: 23px !important;
}

.pb-24px,
.py-24px {
  padding-bottom: 24px !important;
}

.pb-25px,
.py-25px {
  padding-bottom: 25px !important;
}

.pb-26px,
.py-26px {
  padding-bottom: 26px !important;
}

.pb-27px,
.py-27px {
  padding-bottom: 27px !important;
}

.pb-28px,
.py-28px {
  padding-bottom: 28px !important;
}

.pb-29px,
.py-29px {
  padding-bottom: 29px !important;
}

.pb-30px,
.py-30px {
  padding-bottom: 30px !important;
}

.pb-31px,
.py-31px {
  padding-bottom: 31px !important;
}

.pb-32px,
.py-32px {
  padding-bottom: 32px !important;
}

.pb-33px,
.py-33px {
  padding-bottom: 33px !important;
}

.pb-34px,
.py-34px {
  padding-bottom: 34px !important;
}

.pb-35px,
.py-35px {
  padding-bottom: 35px !important;
}

.pb-36px,
.py-36px {
  padding-bottom: 36px !important;
}

.pb-37px,
.py-37px {
  padding-bottom: 37px !important;
}

.pb-38px,
.py-38px {
  padding-bottom: 38px !important;
}

.pb-39px,
.py-39px {
  padding-bottom: 39px !important;
}

.pb-40px,
.py-40px {
  padding-bottom: 40px !important;
}

.pb-41px,
.py-41px {
  padding-bottom: 41px !important;
}

.pb-42px,
.py-42px {
  padding-bottom: 42px !important;
}

.pb-43px,
.py-43px {
  padding-bottom: 43px !important;
}

.pb-44px,
.py-44px {
  padding-bottom: 44px !important;
}

.pb-45px,
.py-45px {
  padding-bottom: 45px !important;
}

.pb-46px,
.py-46px {
  padding-bottom: 46px !important;
}

.pb-47px,
.py-47px {
  padding-bottom: 47px !important;
}

.pb-48px,
.py-48px {
  padding-bottom: 48px !important;
}

.pb-49px,
.py-49px {
  padding-bottom: 49px !important;
}

.pb-50px,
.py-50px {
  padding-bottom: 50px !important;
}

.pb-51px,
.py-51px {
  padding-bottom: 51px !important;
}

.pb-52px,
.py-52px {
  padding-bottom: 52px !important;
}

.pb-53px,
.py-53px {
  padding-bottom: 53px !important;
}

.pb-54px,
.py-54px {
  padding-bottom: 54px !important;
}

.pb-55px,
.py-55px {
  padding-bottom: 55px !important;
}

.pb-56px,
.py-56px {
  padding-bottom: 56px !important;
}

.pb-57px,
.py-57px {
  padding-bottom: 57px !important;
}

.pb-58px,
.py-58px {
  padding-bottom: 58px !important;
}

.pb-59px,
.py-59px {
  padding-bottom: 59px !important;
}

.pb-60px,
.py-60px {
  padding-bottom: 60px !important;
}

.pb-61px,
.py-61px {
  padding-bottom: 61px !important;
}

.pb-62px,
.py-62px {
  padding-bottom: 62px !important;
}

.pb-63px,
.py-63px {
  padding-bottom: 63px !important;
}

.pb-64px,
.py-64px {
  padding-bottom: 64px !important;
}

.pb-65px,
.py-65px {
  padding-bottom: 65px !important;
}

.pb-66px,
.py-66px {
  padding-bottom: 66px !important;
}

.pb-67px,
.py-67px {
  padding-bottom: 67px !important;
}

.pb-68px,
.py-68px {
  padding-bottom: 68px !important;
}

.pb-69px,
.py-69px {
  padding-bottom: 69px !important;
}

.pb-70px,
.py-70px {
  padding-bottom: 70px !important;
}

.pb-71px,
.py-71px {
  padding-bottom: 71px !important;
}

.pb-72px,
.py-72px {
  padding-bottom: 72px !important;
}

.pb-73px,
.py-73px {
  padding-bottom: 73px !important;
}

.pb-74px,
.py-74px {
  padding-bottom: 74px !important;
}

.pb-75px,
.py-75px {
  padding-bottom: 75px !important;
}

.pb-76px,
.py-76px {
  padding-bottom: 76px !important;
}

.pb-77px,
.py-77px {
  padding-bottom: 77px !important;
}

.pb-78px,
.py-78px {
  padding-bottom: 78px !important;
}

.pb-79px,
.py-79px {
  padding-bottom: 79px !important;
}

.pb-80px,
.py-80px {
  padding-bottom: 80px !important;
}

.pb-81px,
.py-81px {
  padding-bottom: 81px !important;
}

.pb-82px,
.py-82px {
  padding-bottom: 82px !important;
}

.pb-83px,
.py-83px {
  padding-bottom: 83px !important;
}

.pb-84px,
.py-84px {
  padding-bottom: 84px !important;
}

.pb-85px,
.py-85px {
  padding-bottom: 85px !important;
}

.pb-86px,
.py-86px {
  padding-bottom: 86px !important;
}

.pb-87px,
.py-87px {
  padding-bottom: 87px !important;
}

.pb-88px,
.py-88px {
  padding-bottom: 88px !important;
}

.pb-89px,
.py-89px {
  padding-bottom: 89px !important;
}

.pb-90px,
.py-90px {
  padding-bottom: 90px !important;
}

.pb-91px,
.py-91px {
  padding-bottom: 91px !important;
}

.pb-92px,
.py-92px {
  padding-bottom: 92px !important;
}

.pb-93px,
.py-93px {
  padding-bottom: 93px !important;
}

.pb-94px,
.py-94px {
  padding-bottom: 94px !important;
}

.pb-95px,
.py-95px {
  padding-bottom: 95px !important;
}

.pb-96px,
.py-96px {
  padding-bottom: 96px !important;
}

.pb-97px,
.py-97px {
  padding-bottom: 97px !important;
}

.pb-98px,
.py-98px {
  padding-bottom: 98px !important;
}

.pb-99px,
.py-99px {
  padding-bottom: 99px !important;
}

.pb-100px,
.py-100px {
  padding-bottom: 100px !important;
}

.pb-101px,
.py-101px {
  padding-bottom: 101px !important;
}

.pb-102px,
.py-102px {
  padding-bottom: 102px !important;
}

.pb-103px,
.py-103px {
  padding-bottom: 103px !important;
}

.pb-104px,
.py-104px {
  padding-bottom: 104px !important;
}

.pb-105px,
.py-105px {
  padding-bottom: 105px !important;
}

.pb-106px,
.py-106px {
  padding-bottom: 106px !important;
}

.pb-107px,
.py-107px {
  padding-bottom: 107px !important;
}

.pb-108px,
.py-108px {
  padding-bottom: 108px !important;
}

.pb-109px,
.py-109px {
  padding-bottom: 109px !important;
}

.pb-110px,
.py-110px {
  padding-bottom: 110px !important;
}

.pb-111px,
.py-111px {
  padding-bottom: 111px !important;
}

.pb-112px,
.py-112px {
  padding-bottom: 112px !important;
}

.pb-113px,
.py-113px {
  padding-bottom: 113px !important;
}

.pb-114px,
.py-114px {
  padding-bottom: 114px !important;
}

.pb-115px,
.py-115px {
  padding-bottom: 115px !important;
}

.pb-116px,
.py-116px {
  padding-bottom: 116px !important;
}

.pb-117px,
.py-117px {
  padding-bottom: 117px !important;
}

.pb-118px,
.py-118px {
  padding-bottom: 118px !important;
}

.pb-119px,
.py-119px {
  padding-bottom: 119px !important;
}

.pb-120px,
.py-120px {
  padding-bottom: 120px !important;
}

.pb-121px,
.py-121px {
  padding-bottom: 121px !important;
}

.pb-122px,
.py-122px {
  padding-bottom: 122px !important;
}

.pb-123px,
.py-123px {
  padding-bottom: 123px !important;
}

.pb-124px,
.py-124px {
  padding-bottom: 124px !important;
}

.pb-125px,
.py-125px {
  padding-bottom: 125px !important;
}

.pb-126px,
.py-126px {
  padding-bottom: 126px !important;
}

.pb-127px,
.py-127px {
  padding-bottom: 127px !important;
}

.pb-128px,
.py-128px {
  padding-bottom: 128px !important;
}

.pb-129px,
.py-129px {
  padding-bottom: 129px !important;
}

.pb-130px,
.py-130px {
  padding-bottom: 130px !important;
}

.pb-131px,
.py-131px {
  padding-bottom: 131px !important;
}

.pb-132px,
.py-132px {
  padding-bottom: 132px !important;
}

.pb-133px,
.py-133px {
  padding-bottom: 133px !important;
}

.pb-134px,
.py-134px {
  padding-bottom: 134px !important;
}

.pb-135px,
.py-135px {
  padding-bottom: 135px !important;
}

.pb-136px,
.py-136px {
  padding-bottom: 136px !important;
}

.pb-137px,
.py-137px {
  padding-bottom: 137px !important;
}

.pb-138px,
.py-138px {
  padding-bottom: 138px !important;
}

.pb-139px,
.py-139px {
  padding-bottom: 139px !important;
}

.pb-140px,
.py-140px {
  padding-bottom: 140px !important;
}

.pb-141px,
.py-141px {
  padding-bottom: 141px !important;
}

.pb-142px,
.py-142px {
  padding-bottom: 142px !important;
}

.pb-143px,
.py-143px {
  padding-bottom: 143px !important;
}

.pb-144px,
.py-144px {
  padding-bottom: 144px !important;
}

.pb-145px,
.py-145px {
  padding-bottom: 145px !important;
}

.pb-146px,
.py-146px {
  padding-bottom: 146px !important;
}

.pb-147px,
.py-147px {
  padding-bottom: 147px !important;
}

.pb-148px,
.py-148px {
  padding-bottom: 148px !important;
}

.pb-149px,
.py-149px {
  padding-bottom: 149px !important;
}

.pb-150px,
.py-150px {
  padding-bottom: 150px !important;
}

.pb-151px,
.py-151px {
  padding-bottom: 151px !important;
}

.pb-152px,
.py-152px {
  padding-bottom: 152px !important;
}

.pb-153px,
.py-153px {
  padding-bottom: 153px !important;
}

.pb-154px,
.py-154px {
  padding-bottom: 154px !important;
}

.pb-155px,
.py-155px {
  padding-bottom: 155px !important;
}

.pb-156px,
.py-156px {
  padding-bottom: 156px !important;
}

.pb-157px,
.py-157px {
  padding-bottom: 157px !important;
}

.pb-158px,
.py-158px {
  padding-bottom: 158px !important;
}

.pb-159px,
.py-159px {
  padding-bottom: 159px !important;
}

.pb-160px,
.py-160px {
  padding-bottom: 160px !important;
}

.pb-161px,
.py-161px {
  padding-bottom: 161px !important;
}

.pb-162px,
.py-162px {
  padding-bottom: 162px !important;
}

.pb-163px,
.py-163px {
  padding-bottom: 163px !important;
}

.pb-164px,
.py-164px {
  padding-bottom: 164px !important;
}

.pb-165px,
.py-165px {
  padding-bottom: 165px !important;
}

.pb-166px,
.py-166px {
  padding-bottom: 166px !important;
}

.pb-167px,
.py-167px {
  padding-bottom: 167px !important;
}

.pb-168px,
.py-168px {
  padding-bottom: 168px !important;
}

.pb-169px,
.py-169px {
  padding-bottom: 169px !important;
}

.pb-170px,
.py-170px {
  padding-bottom: 170px !important;
}

.pb-171px,
.py-171px {
  padding-bottom: 171px !important;
}

.pb-172px,
.py-172px {
  padding-bottom: 172px !important;
}

.pb-173px,
.py-173px {
  padding-bottom: 173px !important;
}

.pb-174px,
.py-174px {
  padding-bottom: 174px !important;
}

.pb-175px,
.py-175px {
  padding-bottom: 175px !important;
}

.pb-176px,
.py-176px {
  padding-bottom: 176px !important;
}

.pb-177px,
.py-177px {
  padding-bottom: 177px !important;
}

.pb-178px,
.py-178px {
  padding-bottom: 178px !important;
}

.pb-179px,
.py-179px {
  padding-bottom: 179px !important;
}

.pb-180px,
.py-180px {
  padding-bottom: 180px !important;
}

.pb-181px,
.py-181px {
  padding-bottom: 181px !important;
}

.pb-182px,
.py-182px {
  padding-bottom: 182px !important;
}

.pb-183px,
.py-183px {
  padding-bottom: 183px !important;
}

.pb-184px,
.py-184px {
  padding-bottom: 184px !important;
}

.pb-185px,
.py-185px {
  padding-bottom: 185px !important;
}

.pb-186px,
.py-186px {
  padding-bottom: 186px !important;
}

.pb-187px,
.py-187px {
  padding-bottom: 187px !important;
}

.pb-188px,
.py-188px {
  padding-bottom: 188px !important;
}

.pb-189px,
.py-189px {
  padding-bottom: 189px !important;
}

.pb-190px,
.py-190px {
  padding-bottom: 190px !important;
}

.pb-191px,
.py-191px {
  padding-bottom: 191px !important;
}

.pb-192px,
.py-192px {
  padding-bottom: 192px !important;
}

.pb-193px,
.py-193px {
  padding-bottom: 193px !important;
}

.pb-194px,
.py-194px {
  padding-bottom: 194px !important;
}

.pb-195px,
.py-195px {
  padding-bottom: 195px !important;
}

.pb-196px,
.py-196px {
  padding-bottom: 196px !important;
}

.pb-197px,
.py-197px {
  padding-bottom: 197px !important;
}

.pb-198px,
.py-198px {
  padding-bottom: 198px !important;
}

.pb-199px,
.py-199px {
  padding-bottom: 199px !important;
}

.pb-200px,
.py-200px {
  padding-bottom: 200px !important;
}

// rem
.pb-0-point-65rem,
.py-0-point-65rem {
  padding-bottom: 0.65rem !important;
}
