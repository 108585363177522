.react-grid-item {
  /* border: 1px solid rgb(220, 221, 221);
    background: white;
    box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 10%); */
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0.42rem;
  box-shadow: 0 0 20px 0 rgb(76 87 125 / 2%);
}

.react-grid-layout.layout {
}

.react-grid-item {
}

.react-grid-item-arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.react-grid-item-arrow-right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.react-grid-item-arrow-left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.react-grid-item-arrow-up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.react-grid-item-arrow-down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
