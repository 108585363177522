.mt-0px,
.my-0px {
  margin-top: 0px !important;
}

.mt-1px,
.my-1px {
  margin-top: 1px !important;
}

.mt-2px,
.my-2px {
  margin-top: 2px !important;
}

.mt-3px,
.my-3px {
  margin-top: 3px !important;
}

.mt-4px,
.my-4px {
  margin-top: 4px !important;
}

.mt-5px,
.my-5px {
  margin-top: 5px !important;
}

.mt-6px,
.my-6px {
  margin-top: 6px !important;
}

.mt-7px,
.my-7px {
  margin-top: 7px !important;
}

.mt-8px,
.my-8px {
  margin-top: 8px !important;
}

.mt-9px,
.my-9px {
  margin-top: 9px !important;
}

.mt-10px,
.my-10px {
  margin-top: 10px !important;
}

.mt-11px,
.my-11px {
  margin-top: 11px !important;
}

.mt-12px,
.my-12px {
  margin-top: 12px !important;
}

.mt-13px,
.my-13px {
  margin-top: 13px !important;
}

.mt-14px,
.my-14px {
  margin-top: 14px !important;
}

.mt-15px,
.my-15px {
  margin-top: 15px !important;
}

.mt-16px,
.my-16px {
  margin-top: 16px !important;
}

.mt-17px,
.my-17px {
  margin-top: 17px !important;
}

.mt-18px,
.my-18px {
  margin-top: 18px !important;
}

.mt-19px,
.my-19px {
  margin-top: 19px !important;
}

.mt-20px,
.my-20px {
  margin-top: 20px !important;
}

.mt-21px,
.my-21px {
  margin-top: 21px !important;
}

.mt-22px,
.my-22px {
  margin-top: 22px !important;
}

.mt-23px,
.my-23px {
  margin-top: 23px !important;
}

.mt-24px,
.my-24px {
  margin-top: 24px !important;
}

.mt-25px,
.my-25px {
  margin-top: 25px !important;
}

.mt-26px,
.my-26px {
  margin-top: 26px !important;
}

.mt-27px,
.my-27px {
  margin-top: 27px !important;
}

.mt-28px,
.my-28px {
  margin-top: 28px !important;
}

.mt-29px,
.my-29px {
  margin-top: 29px !important;
}

.mt-30px,
.my-30px {
  margin-top: 30px !important;
}

.mt-31px,
.my-31px {
  margin-top: 31px !important;
}

.mt-32px,
.my-32px {
  margin-top: 32px !important;
}

.mt-33px,
.my-33px {
  margin-top: 33px !important;
}

.mt-34px,
.my-34px {
  margin-top: 34px !important;
}

.mt-35px,
.my-35px {
  margin-top: 35px !important;
}

.mt-36px,
.my-36px {
  margin-top: 36px !important;
}

.mt-37px,
.my-37px {
  margin-top: 37px !important;
}

.mt-38px,
.my-38px {
  margin-top: 38px !important;
}

.mt-39px,
.my-39px {
  margin-top: 39px !important;
}

.mt-40px,
.my-40px {
  margin-top: 40px !important;
}

.mt-41px,
.my-41px {
  margin-top: 41px !important;
}

.mt-42px,
.my-42px {
  margin-top: 42px !important;
}

.mt-43px,
.my-43px {
  margin-top: 43px !important;
}

.mt-44px,
.my-44px {
  margin-top: 44px !important;
}

.mt-45px,
.my-45px {
  margin-top: 45px !important;
}

.mt-46px,
.my-46px {
  margin-top: 46px !important;
}

.mt-47px,
.my-47px {
  margin-top: 47px !important;
}

.mt-48px,
.my-48px {
  margin-top: 48px !important;
}

.mt-49px,
.my-49px {
  margin-top: 49px !important;
}

.mt-50px,
.my-50px {
  margin-top: 50px !important;
}

.mt-51px,
.my-51px {
  margin-top: 51px !important;
}

.mt-52px,
.my-52px {
  margin-top: 52px !important;
}

.mt-53px,
.my-53px {
  margin-top: 53px !important;
}

.mt-54px,
.my-54px {
  margin-top: 54px !important;
}

.mt-55px,
.my-55px {
  margin-top: 55px !important;
}

.mt-56px,
.my-56px {
  margin-top: 56px !important;
}

.mt-57px,
.my-57px {
  margin-top: 57px !important;
}

.mt-58px,
.my-58px {
  margin-top: 58px !important;
}

.mt-59px,
.my-59px {
  margin-top: 59px !important;
}

.mt-60px,
.my-60px {
  margin-top: 60px !important;
}

.mt-61px,
.my-61px {
  margin-top: 61px !important;
}

.mt-62px,
.my-62px {
  margin-top: 62px !important;
}

.mt-63px,
.my-63px {
  margin-top: 63px !important;
}

.mt-64px,
.my-64px {
  margin-top: 64px !important;
}

.mt-65px,
.my-65px {
  margin-top: 65px !important;
}

.mt-66px,
.my-66px {
  margin-top: 66px !important;
}

.mt-67px,
.my-67px {
  margin-top: 67px !important;
}

.mt-68px,
.my-68px {
  margin-top: 68px !important;
}

.mt-69px,
.my-69px {
  margin-top: 69px !important;
}

.mt-70px,
.my-70px {
  margin-top: 70px !important;
}

.mt-71px,
.my-71px {
  margin-top: 71px !important;
}

.mt-72px,
.my-72px {
  margin-top: 72px !important;
}

.mt-73px,
.my-73px {
  margin-top: 73px !important;
}

.mt-74px,
.my-74px {
  margin-top: 74px !important;
}

.mt-75px,
.my-75px {
  margin-top: 75px !important;
}

.mt-76px,
.my-76px {
  margin-top: 76px !important;
}

.mt-77px,
.my-77px {
  margin-top: 77px !important;
}

.mt-78px,
.my-78px {
  margin-top: 78px !important;
}

.mt-79px,
.my-79px {
  margin-top: 79px !important;
}

.mt-80px,
.my-80px {
  margin-top: 80px !important;
}

.mt-81px,
.my-81px {
  margin-top: 81px !important;
}

.mt-82px,
.my-82px {
  margin-top: 82px !important;
}

.mt-83px,
.my-83px {
  margin-top: 83px !important;
}

.mt-84px,
.my-84px {
  margin-top: 84px !important;
}

.mt-85px,
.my-85px {
  margin-top: 85px !important;
}

.mt-86px,
.my-86px {
  margin-top: 86px !important;
}

.mt-87px,
.my-87px {
  margin-top: 87px !important;
}

.mt-88px,
.my-88px {
  margin-top: 88px !important;
}

.mt-89px,
.my-89px {
  margin-top: 89px !important;
}

.mt-90px,
.my-90px {
  margin-top: 90px !important;
}

.mt-91px,
.my-91px {
  margin-top: 91px !important;
}

.mt-92px,
.my-92px {
  margin-top: 92px !important;
}

.mt-93px,
.my-93px {
  margin-top: 93px !important;
}

.mt-94px,
.my-94px {
  margin-top: 94px !important;
}

.mt-95px,
.my-95px {
  margin-top: 95px !important;
}

.mt-96px,
.my-96px {
  margin-top: 96px !important;
}

.mt-97px,
.my-97px {
  margin-top: 97px !important;
}

.mt-98px,
.my-98px {
  margin-top: 98px !important;
}

.mt-99px,
.my-99px {
  margin-top: 99px !important;
}

.mt-100px,
.my-100px {
  margin-top: 100px !important;
}

.mt-101px,
.my-101px {
  margin-top: 101px !important;
}

.mt-102px,
.my-102px {
  margin-top: 102px !important;
}

.mt-103px,
.my-103px {
  margin-top: 103px !important;
}

.mt-104px,
.my-104px {
  margin-top: 104px !important;
}

.mt-105px,
.my-105px {
  margin-top: 105px !important;
}

.mt-106px,
.my-106px {
  margin-top: 106px !important;
}

.mt-107px,
.my-107px {
  margin-top: 107px !important;
}

.mt-108px,
.my-108px {
  margin-top: 108px !important;
}

.mt-109px,
.my-109px {
  margin-top: 109px !important;
}

.mt-110px,
.my-110px {
  margin-top: 110px !important;
}

.mt-111px,
.my-111px {
  margin-top: 111px !important;
}

.mt-112px,
.my-112px {
  margin-top: 112px !important;
}

.mt-113px,
.my-113px {
  margin-top: 113px !important;
}

.mt-114px,
.my-114px {
  margin-top: 114px !important;
}

.mt-115px,
.my-115px {
  margin-top: 115px !important;
}

.mt-116px,
.my-116px {
  margin-top: 116px !important;
}

.mt-117px,
.my-117px {
  margin-top: 117px !important;
}

.mt-118px,
.my-118px {
  margin-top: 118px !important;
}

.mt-119px,
.my-119px {
  margin-top: 119px !important;
}

.mt-120px,
.my-120px {
  margin-top: 120px !important;
}

.mt-121px,
.my-121px {
  margin-top: 121px !important;
}

.mt-122px,
.my-122px {
  margin-top: 122px !important;
}

.mt-123px,
.my-123px {
  margin-top: 123px !important;
}

.mt-124px,
.my-124px {
  margin-top: 124px !important;
}

.mt-125px,
.my-125px {
  margin-top: 125px !important;
}

.mt-126px,
.my-126px {
  margin-top: 126px !important;
}

.mt-127px,
.my-127px {
  margin-top: 127px !important;
}

.mt-128px,
.my-128px {
  margin-top: 128px !important;
}

.mt-129px,
.my-129px {
  margin-top: 129px !important;
}

.mt-130px,
.my-130px {
  margin-top: 130px !important;
}

.mt-131px,
.my-131px {
  margin-top: 131px !important;
}

.mt-132px,
.my-132px {
  margin-top: 132px !important;
}

.mt-133px,
.my-133px {
  margin-top: 133px !important;
}

.mt-134px,
.my-134px {
  margin-top: 134px !important;
}

.mt-135px,
.my-135px {
  margin-top: 135px !important;
}

.mt-136px,
.my-136px {
  margin-top: 136px !important;
}

.mt-137px,
.my-137px {
  margin-top: 137px !important;
}

.mt-138px,
.my-138px {
  margin-top: 138px !important;
}

.mt-139px,
.my-139px {
  margin-top: 139px !important;
}

.mt-140px,
.my-140px {
  margin-top: 140px !important;
}

.mt-141px,
.my-141px {
  margin-top: 141px !important;
}

.mt-142px,
.my-142px {
  margin-top: 142px !important;
}

.mt-143px,
.my-143px {
  margin-top: 143px !important;
}

.mt-144px,
.my-144px {
  margin-top: 144px !important;
}

.mt-145px,
.my-145px {
  margin-top: 145px !important;
}

.mt-146px,
.my-146px {
  margin-top: 146px !important;
}

.mt-147px,
.my-147px {
  margin-top: 147px !important;
}

.mt-148px,
.my-148px {
  margin-top: 148px !important;
}

.mt-149px,
.my-149px {
  margin-top: 149px !important;
}

.mt-150px,
.my-150px {
  margin-top: 150px !important;
}

.mt-151px,
.my-151px {
  margin-top: 151px !important;
}

.mt-152px,
.my-152px {
  margin-top: 152px !important;
}

.mt-153px,
.my-153px {
  margin-top: 153px !important;
}

.mt-154px,
.my-154px {
  margin-top: 154px !important;
}

.mt-155px,
.my-155px {
  margin-top: 155px !important;
}

.mt-156px,
.my-156px {
  margin-top: 156px !important;
}

.mt-157px,
.my-157px {
  margin-top: 157px !important;
}

.mt-158px,
.my-158px {
  margin-top: 158px !important;
}

.mt-159px,
.my-159px {
  margin-top: 159px !important;
}

.mt-160px,
.my-160px {
  margin-top: 160px !important;
}

.mt-161px,
.my-161px {
  margin-top: 161px !important;
}

.mt-162px,
.my-162px {
  margin-top: 162px !important;
}

.mt-163px,
.my-163px {
  margin-top: 163px !important;
}

.mt-164px,
.my-164px {
  margin-top: 164px !important;
}

.mt-165px,
.my-165px {
  margin-top: 165px !important;
}

.mt-166px,
.my-166px {
  margin-top: 166px !important;
}

.mt-167px,
.my-167px {
  margin-top: 167px !important;
}

.mt-168px,
.my-168px {
  margin-top: 168px !important;
}

.mt-169px,
.my-169px {
  margin-top: 169px !important;
}

.mt-170px,
.my-170px {
  margin-top: 170px !important;
}

.mt-171px,
.my-171px {
  margin-top: 171px !important;
}

.mt-172px,
.my-172px {
  margin-top: 172px !important;
}

.mt-173px,
.my-173px {
  margin-top: 173px !important;
}

.mt-174px,
.my-174px {
  margin-top: 174px !important;
}

.mt-175px,
.my-175px {
  margin-top: 175px !important;
}

.mt-176px,
.my-176px {
  margin-top: 176px !important;
}

.mt-177px,
.my-177px {
  margin-top: 177px !important;
}

.mt-178px,
.my-178px {
  margin-top: 178px !important;
}

.mt-179px,
.my-179px {
  margin-top: 179px !important;
}

.mt-180px,
.my-180px {
  margin-top: 180px !important;
}

.mt-181px,
.my-181px {
  margin-top: 181px !important;
}

.mt-182px,
.my-182px {
  margin-top: 182px !important;
}

.mt-183px,
.my-183px {
  margin-top: 183px !important;
}

.mt-184px,
.my-184px {
  margin-top: 184px !important;
}

.mt-185px,
.my-185px {
  margin-top: 185px !important;
}

.mt-186px,
.my-186px {
  margin-top: 186px !important;
}

.mt-187px,
.my-187px {
  margin-top: 187px !important;
}

.mt-188px,
.my-188px {
  margin-top: 188px !important;
}

.mt-189px,
.my-189px {
  margin-top: 189px !important;
}

.mt-190px,
.my-190px {
  margin-top: 190px !important;
}

.mt-191px,
.my-191px {
  margin-top: 191px !important;
}

.mt-192px,
.my-192px {
  margin-top: 192px !important;
}

.mt-193px,
.my-193px {
  margin-top: 193px !important;
}

.mt-194px,
.my-194px {
  margin-top: 194px !important;
}

.mt-195px,
.my-195px {
  margin-top: 195px !important;
}

.mt-196px,
.my-196px {
  margin-top: 196px !important;
}

.mt-197px,
.my-197px {
  margin-top: 197px !important;
}

.mt-198px,
.my-198px {
  margin-top: 198px !important;
}

.mt-199px,
.my-199px {
  margin-top: 199px !important;
}

.mt-200px,
.my-200px {
  margin-top: 200px !important;
}

// negative px
.mt-minus-21px {
  margin-top: -21px !important;
}
.mt-minus-50px {
  margin-top: -50px !important;
}
.mt-minus-58px {
  margin-top: -58px !important;
}
.mt-minus-62px {
  margin-top: -62px !important;
}

// percentage
.mt-3-percent {
  margin-top: 3% !important;
}
.mt-15-percent {
  margin-top: 15% !important;
}
.mt-20-percent {
  margin-top: 20% !important;
}
.mt-60-percent {
  margin-top: 60% !important;
}

// negative percent
.mt-minus-2-percent {
  margin-top: -2% !important;
}
