// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "./styles/index.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.custom-form-required:after {
  // color: red;
  content: "*";
  position: absolute;
  margin-left: 2px;
}

.activity-status-color {
  position: relative;
}

.activity-status-color::before {
  content: "";
  background: #ffffff;
  position: absolute;
  bottom: -1px;
  left: -5px;
  height: 15%;
  width: 5px;
}

.activity-status-color::after {
  content: "";
  background: #ffffff;
  position: absolute;
  top: 0;
  left: -5px;
  height: 15%;
  width: 5px;
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.OtpScreenStyle {
  width: 60px;
  height: 60px;
}

@media only screen and (max-width: 540px) {
  .authDesign {
    display: none !important;
  }

  .AuthLoginCard {
    width: 130% !important;
    position: relative !important;
  }

  .AuthLoginForm {
    position: relative !important;
    top: -50px !important;
  }

  .inputFormStyle {
    width: 109% !important;
    position: relative !important;
    left: -15px !important;
  }

  .OtpScreenStyle {
    width: 40px;
    height: 40px;
  }
}

.field-required:after {
  content: " *";
  color: red;
}

.custom-ellipsis {
  white-space: nowrap;
  max-width: var(--custom-width);
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone1 {
  height: 300px !important;
  width: 70%;
  margin-left: 15%;
}

.custom-secondory-cta {
  border: 1px solid #e4e6ef !important;
  background: #f8f8f8 !important;
}

.custom-secondory-cta-action {
  border: none;
  background: #f8f8f8 !important;
  border-radius: 0px !important;
}

.hide-dropdonw-icon {
  .dropdown-toggle.btn:after {
    display: none;
  }
}

.ss-custom-loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hide-dropdonw-icon-inline.dropdown-toggle.btn:after {
  display: none;
}

.white-toogle-arrow.dropdown-toggle.btn:after {
  color: white;
}

.modal {
  scrollbar-width: thin;
  scrollbar-color: #eff2f5 transparent;
}

.modal::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.modal::-webkit-scrollbar-thumb {
  background-color: #525252;
}
