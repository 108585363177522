.reorder-add-form-wrapper table {
  border-collapse: separate;
}
.reorder-add-form-wrapper td {
  border: solid 1px #000;
}
.reorder-add-form-wrapper tr:first-child td:first-child {
  border-top-left-radius: 10px;
}
.reorder-add-form-wrapper tr:first-child td:last-child {
  border-top-right-radius: 10px;
}
.reorder-add-form-wrapper tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.reorder-add-form-wrapper tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.reorder-add-form-wrapper .field-row {
  background: white;
  border: 1px solid #e5e3e3;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.reorder-add-form-wrapper .head-field {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
}
.reorder-add-form-wrapper {
  max-height: 70vh;
  overflow-y: auto;
}
