.cadence-timeline-step-wrapper {
  .custom-timeline-line {
    border-style: dashed !important;
    border-left-color: #eaecee !important;
    border-width: 0px 0px 0px 1px;
  }

  .template-content-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .template-display {
    .template-header {
      display: flex;
      justify-content: space-between;
    }

    .template-container {
      padding: 24px;
      background-color: white;
      border: 1px solid #eee;
    }
  }

  .timeline-content {
    overflow: unset;
  }

  .step-right-info {
    display: flex;
    align-items: center;

    .step-number {}

    .step-delay {
      background-color: white;
      border: 1px solid #ddd;
      padding: 2px 6px;
    }
  }


}