/* In % */
.w-25-mn {
  min-width: 25% !important;
}
.w-33-point-33-percent-mn {
  min-width: 33.33% !important;
}
.w-100-mn {
  min-width: 100% !important;
}

/* In vh */
.w-15vh-mn {
  min-width: 15vh !important;
}

/* In px*/
.w-40px-mn {
  min-width: 40px !important;
}

.w-50px-mn {
  min-width: 50px !important;
}

.w-70px-mn {
  min-width: 70px !important;
}

.w-80px-mn {
  min-width: 80px !important;
}

.w-100px-mn {
  min-width: 100px !important;
}

.w-110px-mn {
  min-width: 110px !important;
}

.w-120px-mn {
  min-width: 120px !important;
}

.w-125px-mn {
  min-width: 125px !important;
}

.w-130px-mn {
  min-width: 130px !important;
}

.w-140px-mn {
  min-width: 140px !important;
}

.w-150px-mn {
  min-width: 150px !important;
}

.w-170px-mn {
  min-width: 170px !important;
}

.w-175px-mn {
  min-width: 175px !important;
}

.w-200px-mn {
  min-width: 200px !important;
}

.w-250px-mn {
  min-width: 250px !important;
}

.w-300px-mn {
  min-width: 300px !important;
}
