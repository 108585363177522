.background-repeat-no-repeat {
  background-repeat: no-repeat !important;
}

// background position
.background-position-y-bottom {
  background-position-y: bottom;
}

.background-position-calc-100-percent-plus-8px-100-percent {
  background-position: calc(100% + 8px) 100%;
}

// background size
.background-size-contain {
  background-size: contain;
}

.background-size-100-percent-auto {
  background-size: 100% auto;
}

// .background-image-url-progress-dot-svg {
//   background-image: url(assets/media/svg/illustrations/progress.svg);
// }
