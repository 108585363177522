// align-items-center
// align-items-end
.align-self-flex-end {
  align-self: flex-end !important;
}

.justify-self-end {
  justify-self: flex-end !important;
}

// flex-column
.flex-direction-column {
  flex-direction: column !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex-1-0-auto {
  flex: 1 0 auto !important;
}
