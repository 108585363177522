.max-height-auto {
  max-height: auto !important;
}

// px
.h-200px-mx {
  max-height: 200px !important;
}
.h-220px-mx {
  max-height: 220px !important;
}
.h-300px-mx {
  max-height: 300px !important;
}

.h-325px-mx {
  max-height: 325px !important;
}

.h-400px-mx {
  max-height: 400px !important;
}
.h-420px-mx {
  max-height: 420px !important;
}
.h-500px-mx {
  max-height: 500px !important;
}

.h-650px-mx {
  max-height: 650px !important;
}

// max-height in percent
.h-95-percent-mx {
  max-height: 95% !important;
}

// max-height in vh

.h-25vh-mx {
  max-height: 25vh !important;
}
.h-30vh-mx {
  max-height: 30vh !important;
}
.h-35vh-mx {
  max-height: 35vh !important;
}
.h-45vh-mx {
  max-height: 45vh !important;
}
.h-50vh-mx {
  max-height: 50vh !important;
}
.h-55vh-mx {
  max-height: 55vh !important;
}
.h-60vh-mx {
  max-height: 60vh !important;
}
.h-70vh-mx {
  max-height: 70vh !important;
}

// max-height-calc-vh
.h-vh-120px-mx {
  max-height: calc(100vh - 120px) !important;
}
.h-vh-160px-mx {
  max-height: calc(100vh - 160px) !important;
}
.h-vh-163px-mx {
  max-height: calc(100vh - 163px) !important;
}
.h-vh-200px-mx {
  max-height: calc(100vh - 200px) !important;
}
.h-vh-220px-mx {
  max-height: calc(100vh - 220px) !important;
}
.h-vh-240px-mx {
  max-height: calc(100vh - 240px) !important;
}
.h-vh-260px-mx {
  max-height: calc(100vh - 260px) !important;
}
.h-vh-300px-mx {
  max-height: calc(100vh - 300px) !important;
}

// max-height-calc-percentage
.max-height-calc-100-percent-minus-38px-plus-38px {
  max-height: calc(100% - 38px + 38px) !important;
}
