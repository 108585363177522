.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-1 {
  box-shadow: 0px 0px 4px 0px rgb(82 63 105 / 15%) !important;
}

.box-shadow-2 {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1) !important;
}

.box-shadow-3 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px !important;
}

.box-shadow-4 {
  box-shadow: 0 0 0.4rem #666 !important;
}
