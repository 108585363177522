/* Height of vh with cal*/
.h-vh-50px {
  height: calc(100vh - 50px) !important;
}

.h-vh-58px {
  height: calc(100vh - 58px) !important;
}

.h-vh-70px {
  height: calc(100vh - 70px) !important;
}

.h-vh-73px {
  height: calc(100vh - 73px) !important;
}

.h-vh-80px {
  height: calc(100vh - 80px) !important;
}

.h-vh-120px {
  height: calc(100vh - 120px) !important;
}

.h-vh-155px {
  height: calc(100vh - 155px) !important;
}

.h-vh-160px {
  height: calc(100vh - 160px) !important;
}

.h-vh-161px {
  height: calc(100vh - 161px) !important;
}

.h-vh-162px {
  height: calc(100vh - 162px) !important;
}

.h-vh-163px {
  height: calc(100vh - 163px) !important;
}

.h-vh-170px {
  height: calc(100vh - 170px) !important;
}

.h-vh-176px {
  height: calc(100vh - 176px) !important;
}

.h-vh-180px {
  height: calc(100vh - 180px) !important;
}

.h-vh-195px {
  height: calc(100vh - 195px) !important;
}

.h-vh-200px {
  height: calc(100vh - 200px) !important;
}

.h-vh-220px {
  height: calc(100vh - 220px) !important;
}

.h-vh-230px {
  height: calc(100vh - 230px) !important;
}

.h-vh-250px {
  height: calc(100vh - 250px) !important;
}

.h-vh-400px {
  height: calc(100vh - 400px) !important;
}

.h-vh-450px {
  height: calc(100vh - 450px) !important;
}

.h-vh-510px {
  height: calc(100vh - 510px) !important;
}
