/* height in % */
.h-1 {
  height: 1% !important;
}

.h-2 {
  height: 2% !important;
}

.h-3 {
  height: 3% !important;
}

.h-4 {
  height: 4% !important;
}

.h-5 {
  height: 5% !important;
}

.h-6 {
  height: 6% !important;
}

.h-7 {
  height: 7% !important;
}

.h-8 {
  height: 8% !important;
}

.h-9 {
  height: 9% !important;
}

.h-10 {
  height: 10% !important;
}

.h-11 {
  height: 11% !important;
}

.h-12 {
  height: 12% !important;
}

.h-13 {
  height: 13% !important;
}

.h-14 {
  height: 14% !important;
}

.h-15 {
  height: 15% !important;
}

.h-16 {
  height: 16% !important;
}

.h-17 {
  height: 17% !important;
}

.h-18 {
  height: 18% !important;
}

.h-19 {
  height: 19% !important;
}

.h-20 {
  height: 20% !important;
}

.h-21 {
  height: 21% !important;
}

.h-22 {
  height: 22% !important;
}

.h-23 {
  height: 23% !important;
}

.h-24 {
  height: 24% !important;
}

.h-25 {
  height: 25% !important;
}

.h-26 {
  height: 26% !important;
}

.h-27 {
  height: 27% !important;
}

.h-28 {
  height: 28% !important;
}

.h-28 {
  height: 28% !important;
}

.h-30 {
  height: 30% !important;
}

.h-31 {
  height: 31% !important;
}

.h-32 {
  height: 32% !important;
}

.h-33 {
  height: 33% !important;
}

.h-34 {
  height: 34% !important;
}

.h-35 {
  height: 35% !important;
}

.h-36 {
  height: 36% !important;
}

.h-37 {
  height: 37% !important;
}

.h-38 {
  height: 38% !important;
}

.h-39 {
  height: 39% !important;
}

.h-40 {
  height: 40% !important;
}

.h-41 {
  height: 41% !important;
}

.h-42 {
  height: 42% !important;
}

.h-43 {
  height: 43% !important;
}

.h-44 {
  height: 44% !important;
}

.h-45 {
  height: 45% !important;
}

.h-46 {
  height: 46% !important;
}

.h-47 {
  height: 47% !important;
}

.h-48 {
  height: 48% !important;
}

.h-49 {
  height: 49% !important;
}

.h-50 {
  height: 50% !important;
}

.h-51 {
  height: 51% !important;
}

.h-52 {
  height: 52% !important;
}

.h-53 {
  height: 53% !important;
}

.h-54 {
  height: 54% !important;
}

.h-55 {
  height: 55% !important;
}

.h-56 {
  height: 56% !important;
}

.h-57 {
  height: 57% !important;
}

.h-58 {
  height: 58% !important;
}

.h-59 {
  height: 59% !important;
}

.h-60 {
  height: 60% !important;
}

.h-61 {
  height: 61% !important;
}

.h-62 {
  height: 62% !important;
}

.h-63 {
  height: 63% !important;
}

.h-64 {
  height: 64% !important;
}

.h-65 {
  height: 65% !important;
}

.h-66 {
  height: 66% !important;
}

.h-67 {
  height: 67% !important;
}

.h-68 {
  height: 68% !important;
}

.h-69 {
  height: 69% !important;
}

.h-70 {
  height: 70% !important;
}

.h-71 {
  height: 71% !important;
}

.h-72 {
  height: 72% !important;
}

.h-73 {
  height: 73% !important;
}

.h-74 {
  height: 74% !important;
}

.h-75 {
  height: 75% !important;
}

.h-76 {
  height: 76% !important;
}

.h-77 {
  height: 77% !important;
}

.h-78 {
  height: 78% !important;
}

.h-79 {
  height: 79% !important;
}

.h-80 {
  height: 80% !important;
}

.h-81 {
  height: 81% !important;
}

.h-82 {
  height: 82% !important;
}

.h-83 {
  height: 83% !important;
}

.h-84 {
  height: 84% !important;
}

.h-85 {
  height: 85% !important;
}

.h-86 {
  height: 86% !important;
}

.h-87 {
  height: 87% !important;
}

.h-88 {
  height: 88% !important;
}

.h-89 {
  height: 89% !important;
}

.h-90 {
  height: 90% !important;
}

.h-91 {
  height: 91% !important;
}

.h-92 {
  height: 92% !important;
}

.h-93 {
  height: 93% !important;
}

.h-94 {
  height: 94% !important;
}

.h-95 {
  height: 95% !important;
}

.h-96 {
  height: 96% !important;
}

.h-97 {
  height: 97% !important;
}

.h-98 {
  height: 98% !important;
}

.h-99 {
  height: 99% !important;
}

.h-100 {
  height: 100% !important;
}

// specific
.h-130 {
  height: 130% !important;
}
