//
// Radio
//

// Base
.radio {
  display: flex;
  align-items: center;
  position: relative;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  transition: get($radio-config, transition);
  margin: 0;

  > span {
    border-radius: 50%;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &:after {
      content: "";
      display: none; // Hide check
      width: get($radio-config, tick);
      height: get($radio-config, tick);
      border-radius: 100% !important;
    }
  }

  // Hide default browser input
  > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  // Hover & focus states
  &:hover > input:not([disabled]) ~ span,
  > input:focus ~ span {
    transition: get($radio-config, transition);
  }

  // Disabled state
  &.radio-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  // Checked state
  > input:checked ~ span {
    transition: get($radio-config, transition);
    background-color: none;

    &:after {
      display: block;
    }
  }

  // Square Style
  &.radio-square {
    > span {
      border-radius: 0;
    }
  }

  // Rounded Style
  &.radio-rounded {
    > span {
      @include border-radius($border-radius);
    }
  }

  // Outline type
  &.radio-outline {
    > span {
      border-width: 1px;
      border-style: solid;
    }
  }

  // Outline 2x type
  &.radio-outline-2x {
    > span {
      border-width: 2px !important;
    }
  }

  // Accent type
  &.radio-accent {
    > span {
      border-width: get($radio-config, types, accent, borderWidth) !important;
      border-style: solid !important;
      background-color: transparent !important;

      &:after {
        display: block;
      }
    }
  }
}

// Sizes
.radio {
  // Solid type
  @include radio-size(get($radio-config, types, solid, sizes, default, base), get($radio-config, types, solid, sizes, default, tick));
  &.radio-lg {
    @include radio-size(get($radio-config, types, solid, sizes, lg, base), get($radio-config, types, solid, sizes, lg, tick));
  }

  // Outline type
  &.radio-outline {
    @include radio-size(get($radio-config, types, outline, sizes, default, base), get($radio-config, types, outline, sizes, default, tick));

    &.radio-lg {
      @include radio-size(get($radio-config, types, outline, sizes, lg, base), get($radio-config, types, outline, sizes, lg, tick));
    }
  }

  // Outline type
  &.radio-accent {
    @include radio-size(get($radio-config, types, accent, sizes, default, base), get($radio-config, types, accent, sizes, default, tick));

    &.radio-lg {
      @include radio-size(get($radio-config, types, accent, sizes, lg, base), get($radio-config, types, accent, sizes, lg, tick));
    }
  }
}

// Theme colors
.radio {
  // Default style
  @include radio-solid-theme(get($radio-config, types, solid, theme, base-color), $primary, $white);

  // Outline style
  &.radio-outline {
    @include radio-outline-theme(get($radio-config, types, outline, theme, base-color), $primary, $primary);
  }

  // Color options
  @each $name, $color in $theme-colors {
    // Default style
    &.radio-#{$name} {
      @include radio-solid-theme(get($radio-config, color), $color, $white);
    }

    // Light style
    &.checkbox-light-#{$name} {
      @include checkbox-solid-theme(theme-light-color($name), $color, theme-inverse-color($name));
    }

    // Outline style
    &.radio-outline.radio-#{$name} {
      @include radio-outline-theme(get($radio-config, types, outline, theme, base-color), $color, $color);
    }

    // Accent style
    &.radio-accent.radio-#{$name} {
      @include radio-accent-theme($color, theme-light-color($name));
    }
  }
}

// Inline radio
.radio-inline {
  display: flex;
  flex-wrap: wrap;

  .radio {
    margin-right: get($radio-config, itemSpace);
    margin-bottom: get($radio-config, inlineItemSpace);

    span {
      margin-right: get($radio-config, labelSpace);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

// List radio
.radio-list {
  display: flex;
  flex-direction: column;

  .radio {
    margin-bottom: get($radio-config, itemSpace);

    span {
      margin-right: get($radio-config, labelSpace);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Input group
.input-group {
  .radio {
    margin-bottom: 0 !important;
    padding-left: 0;
  }
}

// Form integration
.form-inline {
  .radio {
    margin-left: 15px;
    margin-right: 15px;
  }
}
