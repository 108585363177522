.overflow-scroll {
  overflow: scroll !important;
}
.overflow-visible {
  overflow: visible !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-x-scroll {
  overflow-x: scroll !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.overflow-y-scroll {
  overflow-y: scroll !important;
}
.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere !important;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}
.resize-none {
  resize: none !important;
}

.white-space-pre-line {
  white-space: pre-line !important;
}
