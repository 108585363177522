.widget-container {
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: right;
  display: flex;
  flex-direction: column;
  .chat-history {
    display: flex;
    align-self: flex-end;
    max-height: 600px;
    margin-top: auto;
    max-width: 376px;
    min-width: 376px;
    height: calc(100% - 55px - 20px);
    box-shadow: rgb(0 0 0 / 16%) 0px 0px 11px;
    opacity: 1;
    border-radius: 8px;
    overflow: hidden;
    transition: width 200ms ease 0s, height ease 0s, max-height ease 0s;
    background-color: white;
    position: relative;
  }

  .bot-icon-container {
    display: flex;
    align-self: flex-end;
    margin-top: 10px;
    width: 55px;
    height: 55px;
    background: var(--header-background);
    border-radius: 50%;
    right: 20px;
    bottom: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    color: var(--bot-color);
  }
}
