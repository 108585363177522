$color-02507a: #02507a;
$color-1BC5BD: #1bc5bd;
$color-F64E60: #f64e60;

.text-color-inherit {
  color: inherit !important;
}
.text-black {
  color: black !important;
}

$color-blue: blue;
.text-blue {
  color: $color-blue;
}

.text-02507a {
  color: $color-02507a !important;
}

.text-1BC5BD {
  color: $color-1BC5BD !important;
}

.text-F64E60 {
  color: $color-F64E60 !important;
}

$color-7e8299: #7e8299;
.text-7e8299 {
  color: $color-7e8299 !important;
}

$color-26292c: #26292c;
.text-26292c {
  color: $color-26292c !important;
}

$color-008fbd: #008fbd;
.text-008fbd {
  color: $color-008fbd !important;
}

$color-darkgray: darkgray;
.text-darkgray {
  color: $color-darkgray !important;
}

$color-333: #333;
.text-333 {
  color: $color-333 !important;
}

$color-9197b3: #9197b3;
.text-9197b3 {
  color: $color-9197b3 !important;
}

$color-red: red;
.text-red {
  color: $color-red !important;
}

$color-e09a16: #e09a16;
.text-e09a16 {
  color: $color-e09a16 !important;
}

$color-00a6bc: #00a6bc;
.text-00a6bc {
  color: $color-00a6bc !important;
}

$color-3699ff: #3699ff;
.text-3699ff {
  color: $color-3699ff !important;
}

$color-00acff: #00acff;
.text-00acff {
  color: $color-00acff !important;
}

$color-f1416c: #f1416c;
.text-f1416c {
  color: $color-f1416c !important;
}

$color-019bec: #019bec;
.text-019bec {
  color: $color-019bec !important;
}

$color-a1a5b7: #a1a5b7;
.text-a1a5b7 {
  color: $color-a1a5b7 !important;
}

$color-009ef7: #009ef7;
.text-009ef7 {
  color: $color-009ef7 !important;
}

$color-3f4254: #3f4254;
.text-3f4254 {
  color: $color-3f4254 !important;
}

$color-333333: #333333;
.text-333333 {
  color: $color-333333 !important;
}

$color-red: red;
.text-red {
  color: $color-red !important;
}

$color-green: green;
.text-green {
  color: $color-green !important;
}

$color-e34e6c: #e34e6c;
.text-e34e6c {
  color: $color-e34e6c !important;
}

$color-cornflowerblue: cornflowerblue;
.text-cornflowerblue {
  color: $color-cornflowerblue !important;
}

$color-53617c: #53617c;
.text-53617c {
  color: $color-53617c !important;
}

$color-419eff: #419eff;
.text-419eff {
  color: $color-419eff !important;
}

$color-skyblue: skyblue;
.text-skyblue {
  color: $color-skyblue !important;
}

$color-skyblue: skyblue;
.text-skyblue {
  color: $color-skyblue !important;
}

$color-747678: #747678;
.text-747678 {
  color: $color-747678 !important;
}

$color-0893bf: #0893bf;
.text-0893bf {
  color: $color-0893bf !important;
}

$color-18944a: #18944a;
.text-18944a {
  color: $color-18944a !important;
}

$color-f6c61b: #f6c61b;
.text-f6c61b {
  color: $color-f6c61b !important;
}

$color-4a4d4f: #4a4d4f;
.text-4a4d4f {
  color: $color-4a4d4f !important;
}

$color-d99209: #d99209;
.text-d99209 {
  color: $color-d99209 !important;
}

$color-3999fe: #3999fe;
.text-3999fe {
  color: $color-3999fe !important;
}

$color-373737: #373737;
.text-373737 {
  color: $color-373737 !important;
}

$color-888: #888;
.text-888 {
  color: $color-888 !important;
}

$color-ffa800: #ffa800;
.text-ffa800 {
  color: $color-ffa800 !important;
}

$color-000000a8: #000000a8;
.text-000000a8 {
  color: $color-000000a8 !important;
}

.text-4791FF {
  color: #4791ff !important;
}

.text-02BC77 {
  color: #02bc77 !important;
}

.text-FF2366 {
  color: #ff2366 !important;
}

$color: #000000a8;
