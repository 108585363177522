/* Width in vw */
.w-10vw {
    width: 10vw !important;
}

.w-20vw {
    width: 20vw !important;
}

.w-30vw {
    width: 30vw !important;
}

.w-40vw {
    width: 40vw !important;
}

.w-50vw {
    width: 50vw !important;
}

.w-60vw {
    width: 60vw !important;
}

.w-70vw {
    width: 70vw !important;
}

.w-80vw {
    width: 80vw !important;
}

.w-90vw {
    width: 90vw !important;
}

.w-100vw {
    width: 100vw !important;
}