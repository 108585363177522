// right
.right-1px {
  right: 1px !important;
}
.right-2px {
  right: 2px !important;
}
.right-3px {
  right: 3px !important;
}
.right-4px {
  right: 4px !important;
}
.right-5px {
  right: 5px !important;
}
.right-6px {
  right: 6px !important;
}
.right-7px {
  right: 7px !important;
}
.right-8px {
  right: 8px !important;
}
.right-9px {
  right: 9px !important;
}
.right-10px {
  right: 10px !important;
}
.right-11px {
  right: 11px !important;
}
.right-12px {
  right: 12px !important;
}
.right-13px {
  right: 13px !important;
}
.right-14px {
  right: 14px !important;
}
.right-15px {
  right: 15px !important;
}
.right-16px {
  right: 16px !important;
}
.right-17px {
  right: 17px !important;
}
.right-18px {
  right: 18px !important;
}
.right-19px {
  right: 19px !important;
}
.right-20px {
  right: 20px !important;
}
.right-21px {
  right: 21px !important;
}
.right-22px {
  right: 22px !important;
}
.right-23px {
  right: 23px !important;
}
.right-24px {
  right: 24px !important;
}
.right-25px {
  right: 25px !important;
}
.right-26px {
  right: 26px !important;
}
.right-27px {
  right: 27px !important;
}
.right-28px {
  right: 28px !important;
}
.right-29px {
  right: 29px !important;
}
.right-30px {
  right: 30px !important;
}
.right-31px {
  right: 31px !important;
}
.right-32px {
  right: 32px !important;
}
.right-33px {
  right: 33px !important;
}
.right-34px {
  right: 34px !important;
}
.right-35px {
  right: 35px !important;
}
.right-36px {
  right: 36px !important;
}
.right-37px {
  right: 37px !important;
}
.right-38px {
  right: 38px !important;
}
.right-39px {
  right: 39px !important;
}
.right-40px {
  right: 40px !important;
}
.right-41px {
  right: 41px !important;
}
.right-42px {
  right: 42px !important;
}
.right-43px {
  right: 43px !important;
}
.right-44px {
  right: 44px !important;
}
.right-45px {
  right: 45px !important;
}
.right-46px {
  right: 46px !important;
}
.right-47px {
  right: 47px !important;
}
.right-48px {
  right: 48px !important;
}
.right-49px {
  right: 49px !important;
}
.right-50px {
  right: 50px !important;
}
.right-51px {
  right: 51px !important;
}
.right-52px {
  right: 52px !important;
}
.right-53px {
  right: 53px !important;
}
.right-54px {
  right: 54px !important;
}
.right-55px {
  right: 55px !important;
}
.right-56px {
  right: 56px !important;
}
.right-57px {
  right: 57px !important;
}
.right-58px {
  right: 58px !important;
}
.right-59px {
  right: 59px !important;
}
.right-60px {
  right: 60px !important;
}
.right-61px {
  right: 61px !important;
}
.right-62px {
  right: 62px !important;
}
.right-63px {
  right: 63px !important;
}
.right-64px {
  right: 64px !important;
}
.right-65px {
  right: 65px !important;
}
.right-66px {
  right: 66px !important;
}
.right-67px {
  right: 67px !important;
}
.right-68px {
  right: 68px !important;
}
.right-69px {
  right: 69px !important;
}
.right-70px {
  right: 70px !important;
}
.right-71px {
  right: 71px !important;
}
.right-72px {
  right: 72px !important;
}
.right-73px {
  right: 73px !important;
}
.right-74px {
  right: 74px !important;
}
.right-75px {
  right: 75px !important;
}
.right-76px {
  right: 76px !important;
}
.right-77px {
  right: 77px !important;
}
.right-78px {
  right: 78px !important;
}
.right-79px {
  right: 79px !important;
}
.right-80px {
  right: 80px !important;
}
.right-81px {
  right: 81px !important;
}
.right-82px {
  right: 82px !important;
}
.right-83px {
  right: 83px !important;
}
.right-84px {
  right: 84px !important;
}
.right-85px {
  right: 85px !important;
}
.right-86px {
  right: 86px !important;
}
.right-87px {
  right: 87px !important;
}
.right-88px {
  right: 88px !important;
}
.right-89px {
  right: 89px !important;
}
.right-90px {
  right: 90px !important;
}
.right-91px {
  right: 91px !important;
}
.right-92px {
  right: 92px !important;
}
.right-93px {
  right: 93px !important;
}
.right-94px {
  right: 94px !important;
}
.right-95px {
  right: 95px !important;
}
.right-96px {
  right: 96px !important;
}
.right-97px {
  right: 97px !important;
}
.right-98px {
  right: 98px !important;
}
.right-99px {
  right: 99px !important;
}
.right-100px {
  right: 100px !important;
}

// minus px
.right-minus-1px {
  right: -1px !important;
}
.right-minus-2px {
  right: -2px !important;
}
.right-minus-3px {
  right: -3px !important;
}
.right-minus-4px {
  right: -4px !important;
}
.right-minus-5px {
  right: -5px !important;
}
.right-minus-6px {
  right: -6px !important;
}
.right-minus-7px {
  right: -7px !important;
}
.right-minus-8px {
  right: -8px !important;
}
.right-minus-9px {
  right: -9px !important;
}
.right-minus-10px {
  right: -10px !important;
}
.right-minus-11px {
  right: -11px !important;
}
.right-minus-12px {
  right: -12px !important;
}
.right-minus-13px {
  right: -13px !important;
}
.right-minus-14px {
  right: -14px !important;
}
.right-minus-15px {
  right: -15px !important;
}
.right-minus-16px {
  right: -16px !important;
}
.right-minus-17px {
  right: -17px !important;
}
.right-minus-18px {
  right: -18px !important;
}
.right-minus-19px {
  right: -19px !important;
}
.right-minus-20px {
  right: -20px !important;
}
.right-minus-21px {
  right: -21px !important;
}
.right-minus-22px {
  right: -22px !important;
}
.right-minus-23px {
  right: -23px !important;
}
.right-minus-24px {
  right: -24px !important;
}
.right-minus-25px {
  right: -25px !important;
}
.right-minus-26px {
  right: -26px !important;
}
.right-minus-27px {
  right: -27px !important;
}
.right-minus-28px {
  right: -28px !important;
}
.right-minus-29px {
  right: -29px !important;
}
.right-minus-30px {
  right: -30px !important;
}
.right-minus-31px {
  right: -31px !important;
}
.right-minus-32px {
  right: -32px !important;
}
.right-minus-33px {
  right: -33px !important;
}
.right-minus-34px {
  right: -34px !important;
}
.right-minus-35px {
  right: -35px !important;
}
.right-minus-36px {
  right: -36px !important;
}
.right-minus-37px {
  right: -37px !important;
}
.right-minus-38px {
  right: -38px !important;
}
.right-minus-39px {
  right: -39px !important;
}
.right-minus-40px {
  right: -40px !important;
}
.right-minus-41px {
  right: -41px !important;
}
.right-minus-42px {
  right: -42px !important;
}
.right-minus-43px {
  right: -43px !important;
}
.right-minus-44px {
  right: -44px !important;
}
.right-minus-45px {
  right: -45px !important;
}
.right-minus-46px {
  right: -46px !important;
}
.right-minus-47px {
  right: -47px !important;
}
.right-minus-48px {
  right: -48px !important;
}
.right-minus-49px {
  right: -49px !important;
}
.right-minus-50px {
  right: -50px !important;
}
.right-minus-51px {
  right: -51px !important;
}
.right-minus-52px {
  right: -52px !important;
}
.right-minus-53px {
  right: -53px !important;
}
.right-minus-54px {
  right: -54px !important;
}
.right-minus-55px {
  right: -55px !important;
}
.right-minus-56px {
  right: -56px !important;
}
.right-minus-57px {
  right: -57px !important;
}
.right-minus-58px {
  right: -58px !important;
}
.right-minus-59px {
  right: -59px !important;
}
.right-minus-60px {
  right: -60px !important;
}
.right-minus-61px {
  right: -61px !important;
}
.right-minus-62px {
  right: -62px !important;
}
.right-minus-63px {
  right: -63px !important;
}
.right-minus-64px {
  right: -64px !important;
}
.right-minus-65px {
  right: -65px !important;
}
.right-minus-66px {
  right: -66px !important;
}
.right-minus-67px {
  right: -67px !important;
}
.right-minus-68px {
  right: -68px !important;
}
.right-minus-69px {
  right: -69px !important;
}
.right-minus-70px {
  right: -70px !important;
}
.right-minus-71px {
  right: -71px !important;
}
.right-minus-72px {
  right: -72px !important;
}
.right-minus-73px {
  right: -73px !important;
}
.right-minus-74px {
  right: -74px !important;
}
.right-minus-75px {
  right: -75px !important;
}
.right-minus-76px {
  right: -76px !important;
}
.right-minus-77px {
  right: -77px !important;
}
.right-minus-78px {
  right: -78px !important;
}
.right-minus-79px {
  right: -79px !important;
}
.right-minus-80px {
  right: -80px !important;
}
.right-minus-81px {
  right: -81px !important;
}
.right-minus-82px {
  right: -82px !important;
}
.right-minus-83px {
  right: -83px !important;
}
.right-minus-84px {
  right: -84px !important;
}
.right-minus-85px {
  right: -85px !important;
}
.right-minus-86px {
  right: -86px !important;
}
.right-minus-87px {
  right: -87px !important;
}
.right-minus-88px {
  right: -88px !important;
}
.right-minus-89px {
  right: -89px !important;
}
.right-minus-90px {
  right: -90px !important;
}
.right-minus-91px {
  right: -91px !important;
}
.right-minus-92px {
  right: -92px !important;
}
.right-minus-93px {
  right: -93px !important;
}
.right-minus-94px {
  right: -94px !important;
}
.right-minus-95px {
  right: -95px !important;
}
.right-minus-96px {
  right: -96px !important;
}
.right-minus-97px {
  right: -97px !important;
}
.right-minus-98px {
  right: -98px !important;
}
.right-minus-99px {
  right: -99px !important;
}
.right-minus-100px {
  right: -100px !important;
}

// percent

.right-2-percent {
  right: 2% !important;
}
.right-20-percent {
  right: 20% !important;
}
.right-35-percent {
  right: 35% !important;
}

// vw

.right-0vw {
  right: 0vw !important;
}
.right-1vw {
  right: 1vw !important;
}
.right-2vw {
  right: 2vw !important;
}
.right-3vw {
  right: 3vw !important;
}
.right-4vw {
  right: 4vw !important;
}
.right-5vw {
  right: 5vw !important;
}
.right-6vw {
  right: 6vw !important;
}
.right-7vw {
  right: 7vw !important;
}
.right-8vw {
  right: 8vw !important;
}
.right-9vw {
  right: 9vw !important;
}
.right-10vw {
  right: 10vw !important;
}
.right-11vw {
  right: 11vw !important;
}
.right-12vw {
  right: 12vw !important;
}
.right-13vw {
  right: 13vw !important;
}
.right-14vw {
  right: 14vw !important;
}
.right-15vw {
  right: 15vw !important;
}
.right-16vw {
  right: 16vw !important;
}
.right-17vw {
  right: 17vw !important;
}
.right-18vw {
  right: 18vw !important;
}
.right-19vw {
  right: 19vw !important;
}
.right-20vw {
  right: 20vw !important;
}
.right-21vw {
  right: 21vw !important;
}
.right-22vw {
  right: 22vw !important;
}
.right-23vw {
  right: 23vw !important;
}
.right-24vw {
  right: 24vw !important;
}
.right-25vw {
  right: 25vw !important;
}
.right-26vw {
  right: 26vw !important;
}
.right-27vw {
  right: 27vw !important;
}
.right-28vw {
  right: 28vw !important;
}
.right-29vw {
  right: 29vw !important;
}
.right-30vw {
  right: 30vw !important;
}
.right-31vw {
  right: 31vw !important;
}
.right-32vw {
  right: 32vw !important;
}
.right-33vw {
  right: 33vw !important;
}
.right-34vw {
  right: 34vw !important;
}
.right-35vw {
  right: 35vw !important;
}
.right-36vw {
  right: 36vw !important;
}
.right-37vw {
  right: 37vw !important;
}
.right-38vw {
  right: 38vw !important;
}
.right-39vw {
  right: 39vw !important;
}
.right-40vw {
  right: 40vw !important;
}
.right-41vw {
  right: 41vw !important;
}
.right-42vw {
  right: 42vw !important;
}
.right-43vw {
  right: 43vw !important;
}
.right-44vw {
  right: 44vw !important;
}
.right-45vw {
  right: 45vw !important;
}
.right-46vw {
  right: 46vw !important;
}
.right-47vw {
  right: 47vw !important;
}
.right-48vw {
  right: 48vw !important;
}
.right-49vw {
  right: 49vw !important;
}
.right-50vw {
  right: 50vw !important;
}
.right-51vw {
  right: 51vw !important;
}
.right-52vw {
  right: 52vw !important;
}
.right-53vw {
  right: 53vw !important;
}
.right-54vw {
  right: 54vw !important;
}
.right-55vw {
  right: 55vw !important;
}
.right-56vw {
  right: 56vw !important;
}
.right-57vw {
  right: 57vw !important;
}
.right-58vw {
  right: 58vw !important;
}
.right-59vw {
  right: 59vw !important;
}
.right-60vw {
  right: 60vw !important;
}
.right-61vw {
  right: 61vw !important;
}
.right-62vw {
  right: 62vw !important;
}
.right-63vw {
  right: 63vw !important;
}
.right-64vw {
  right: 64vw !important;
}
.right-65vw {
  right: 65vw !important;
}
.right-66vw {
  right: 66vw !important;
}
.right-67vw {
  right: 67vw !important;
}
.right-68vw {
  right: 68vw !important;
}
.right-69vw {
  right: 69vw !important;
}
.right-70vw {
  right: 70vw !important;
}
.right-71vw {
  right: 71vw !important;
}
.right-72vw {
  right: 72vw !important;
}
.right-73vw {
  right: 73vw !important;
}
.right-74vw {
  right: 74vw !important;
}
.right-75vw {
  right: 75vw !important;
}
.right-76vw {
  right: 76vw !important;
}
.right-77vw {
  right: 77vw !important;
}
.right-78vw {
  right: 78vw !important;
}
.right-79vw {
  right: 79vw !important;
}
.right-80vw {
  right: 80vw !important;
}
.right-81vw {
  right: 81vw !important;
}
.right-82vw {
  right: 82vw !important;
}
.right-83vw {
  right: 83vw !important;
}
.right-84vw {
  right: 84vw !important;
}
.right-85vw {
  right: 85vw !important;
}
.right-86vw {
  right: 86vw !important;
}
.right-87vw {
  right: 87vw !important;
}
.right-88vw {
  right: 88vw !important;
}
.right-89vw {
  right: 89vw !important;
}
.right-90vw {
  right: 90vw !important;
}
.right-91vw {
  right: 91vw !important;
}
.right-92vw {
  right: 92vw !important;
}
.right-93vw {
  right: 93vw !important;
}
.right-94vw {
  right: 94vw !important;
}
.right-95vw {
  right: 95vw !important;
}
.right-96vw {
  right: 96vw !important;
}
.right-97vw {
  right: 97vw !important;
}
.right-98vw {
  right: 98vw !important;
}
.right-99vw {
  right: 99vw !important;
}
.right-100vw {
  right: 100vw !important;
}

// minus vw
.right-minus-0vw {
  right: -0vw !important;
}
.right-minus-1vw {
  right: -1vw !important;
}
.right-minus-2vw {
  right: -2vw !important;
}
.right-minus-3vw {
  right: -3vw !important;
}
.right-minus-4vw {
  right: -4vw !important;
}
.right-minus-5vw {
  right: -5vw !important;
}
.right-minus-6vw {
  right: -6vw !important;
}
.right-minus-7vw {
  right: -7vw !important;
}
.right-minus-8vw {
  right: -8vw !important;
}
.right-minus-9vw {
  right: -9vw !important;
}
.right-minus-10vw {
  right: -10vw !important;
}
.right-minus-11vw {
  right: -11vw !important;
}
.right-minus-12vw {
  right: -12vw !important;
}
.right-minus-13vw {
  right: -13vw !important;
}
.right-minus-14vw {
  right: -14vw !important;
}
.right-minus-15vw {
  right: -15vw !important;
}
.right-minus-16vw {
  right: -16vw !important;
}
.right-minus-17vw {
  right: -17vw !important;
}
.right-minus-18vw {
  right: -18vw !important;
}
.right-minus-19vw {
  right: -19vw !important;
}
.right-minus-20vw {
  right: -20vw !important;
}
.right-minus-21vw {
  right: -21vw !important;
}
.right-minus-22vw {
  right: -22vw !important;
}
.right-minus-23vw {
  right: -23vw !important;
}
.right-minus-24vw {
  right: -24vw !important;
}
.right-minus-25vw {
  right: -25vw !important;
}
.right-minus-26vw {
  right: -26vw !important;
}
.right-minus-27vw {
  right: -27vw !important;
}
.right-minus-28vw {
  right: -28vw !important;
}
.right-minus-29vw {
  right: -29vw !important;
}
.right-minus-30vw {
  right: -30vw !important;
}
.right-minus-31vw {
  right: -31vw !important;
}
.right-minus-32vw {
  right: -32vw !important;
}
.right-minus-33vw {
  right: -33vw !important;
}
.right-minus-34vw {
  right: -34vw !important;
}
.right-minus-35vw {
  right: -35vw !important;
}
.right-minus-36vw {
  right: -36vw !important;
}
.right-minus-37vw {
  right: -37vw !important;
}
.right-minus-38vw {
  right: -38vw !important;
}
.right-minus-39vw {
  right: -39vw !important;
}
.right-minus-40vw {
  right: -40vw !important;
}
.right-minus-41vw {
  right: -41vw !important;
}
.right-minus-42vw {
  right: -42vw !important;
}
.right-minus-43vw {
  right: -43vw !important;
}
.right-minus-44vw {
  right: -44vw !important;
}
.right-minus-45vw {
  right: -45vw !important;
}
.right-minus-46vw {
  right: -46vw !important;
}
.right-minus-47vw {
  right: -47vw !important;
}
.right-minus-48vw {
  right: -48vw !important;
}
.right-minus-49vw {
  right: -49vw !important;
}
.right-minus-50vw {
  right: -50vw !important;
}
.right-minus-51vw {
  right: -51vw !important;
}
.right-minus-52vw {
  right: -52vw !important;
}
.right-minus-53vw {
  right: -53vw !important;
}
.right-minus-54vw {
  right: -54vw !important;
}
.right-minus-55vw {
  right: -55vw !important;
}
.right-minus-56vw {
  right: -56vw !important;
}
.right-minus-57vw {
  right: -57vw !important;
}
.right-minus-58vw {
  right: -58vw !important;
}
.right-minus-59vw {
  right: -59vw !important;
}
.right-minus-60vw {
  right: -60vw !important;
}
.right-minus-61vw {
  right: -61vw !important;
}
.right-minus-62vw {
  right: -62vw !important;
}
.right-minus-63vw {
  right: -63vw !important;
}
.right-minus-64vw {
  right: -64vw !important;
}
.right-minus-65vw {
  right: -65vw !important;
}
.right-minus-66vw {
  right: -66vw !important;
}
.right-minus-67vw {
  right: -67vw !important;
}
.right-minus-68vw {
  right: -68vw !important;
}
.right-minus-69vw {
  right: -69vw !important;
}
.right-minus-70vw {
  right: -70vw !important;
}
.right-minus-71vw {
  right: -71vw !important;
}
.right-minus-72vw {
  right: -72vw !important;
}
.right-minus-73vw {
  right: -73vw !important;
}
.right-minus-74vw {
  right: -74vw !important;
}
.right-minus-75vw {
  right: -75vw !important;
}
.right-minus-76vw {
  right: -76vw !important;
}
.right-minus-77vw {
  right: -77vw !important;
}
.right-minus-78vw {
  right: -78vw !important;
}
.right-minus-79vw {
  right: -79vw !important;
}
.right-minus-80vw {
  right: -80vw !important;
}
.right-minus-81vw {
  right: -81vw !important;
}
.right-minus-82vw {
  right: -82vw !important;
}
.right-minus-83vw {
  right: -83vw !important;
}
.right-minus-84vw {
  right: -84vw !important;
}
.right-minus-85vw {
  right: -85vw !important;
}
.right-minus-86vw {
  right: -86vw !important;
}
.right-minus-87vw {
  right: -87vw !important;
}
.right-minus-88vw {
  right: -88vw !important;
}
.right-minus-89vw {
  right: -89vw !important;
}
.right-minus-90vw {
  right: -90vw !important;
}
.right-minus-91vw {
  right: -91vw !important;
}
.right-minus-92vw {
  right: -92vw !important;
}
.right-minus-93vw {
  right: -93vw !important;
}
.right-minus-94vw {
  right: -94vw !important;
}
.right-minus-95vw {
  right: -95vw !important;
}
.right-minus-96vw {
  right: -96vw !important;
}
.right-minus-97vw {
  right: -97vw !important;
}
.right-minus-98vw {
  right: -98vw !important;
}
.right-minus-99vw {
  right: -99vw !important;
}
.right-minus-100vw {
  right: -100vw !important;
}
