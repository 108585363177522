@import "./padding-bottom.scss";
@import "./padding-right.scss";
@import "./padding-top.scss";
@import "./padding-left.scss";

.p-0px {
  padding: 0px !important;
}

.p-1px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.p-3px {
  padding: 3px !important;
}

.p-4px {
  padding: 4px !important;
}

.p-5px {
  padding: 5px !important;
}

.p-6px {
  padding: 6px !important;
}

.p-7px {
  padding: 7px !important;
}
.p-7-point-5px {
  padding: 7.5px !important;
}

.p-8px {
  padding: 8px !important;
}

.p-9px {
  padding: 9px !important;
}

.p-10px {
  padding: 10px !important;
}

.p-11px {
  padding: 11px !important;
}

.p-12px {
  padding: 12px !important;
}

.p-13px {
  padding: 13px !important;
}

.p-14px {
  padding: 14px !important;
}

.p-15px {
  padding: 15px !important;
}

.p-16px {
  padding: 16px !important;
}

.p-17px {
  padding: 17px !important;
}

.p-18px {
  padding: 18px !important;
}

.p-19px {
  padding: 19px !important;
}

.p-20px {
  padding: 20px !important;
}

.p-21px {
  padding: 21px !important;
}

.p-22px {
  padding: 22px !important;
}

.p-23px {
  padding: 23px !important;
}

.p-24px {
  padding: 24px !important;
}

.p-25px {
  padding: 25px !important;
}

.p-26px {
  padding: 26px !important;
}

.p-27px {
  padding: 27px !important;
}

.p-28px {
  padding: 28px !important;
}

.p-29px {
  padding: 29px !important;
}

.p-30px {
  padding: 30px !important;
}

.p-31px {
  padding: 31px !important;
}

.p-32px {
  padding: 32px !important;
}

.p-33px {
  padding: 33px !important;
}

.p-34px {
  padding: 34px !important;
}

.p-35px {
  padding: 35px !important;
}

.p-36px {
  padding: 36px !important;
}

.p-37px {
  padding: 37px !important;
}

.p-38px {
  padding: 38px !important;
}

.p-39px {
  padding: 39px !important;
}

.p-40px {
  padding: 40px !important;
}

.p-41px {
  padding: 41px !important;
}

.p-42px {
  padding: 42px !important;
}

.p-43px {
  padding: 43px !important;
}

.p-44px {
  padding: 44px !important;
}

.p-45px {
  padding: 45px !important;
}

.p-46px {
  padding: 46px !important;
}

.p-47px {
  padding: 47px !important;
}

.p-48px {
  padding: 48px !important;
}

.p-49px {
  padding: 49px !important;
}

.p-50px {
  padding: 50px !important;
}

.p-51px {
  padding: 51px !important;
}

.p-52px {
  padding: 52px !important;
}

.p-53px {
  padding: 53px !important;
}

.p-54px {
  padding: 54px !important;
}

.p-55px {
  padding: 55px !important;
}

.p-56px {
  padding: 56px !important;
}

.p-57px {
  padding: 57px !important;
}

.p-58px {
  padding: 58px !important;
}

.p-59px {
  padding: 59px !important;
}

.p-60px {
  padding: 60px !important;
}

.p-61px {
  padding: 61px !important;
}

.p-62px {
  padding: 62px !important;
}

.p-63px {
  padding: 63px !important;
}

.p-64px {
  padding: 64px !important;
}

.p-65px {
  padding: 65px !important;
}

.p-66px {
  padding: 66px !important;
}

.p-67px {
  padding: 67px !important;
}

.p-68px {
  padding: 68px !important;
}

.p-69px {
  padding: 69px !important;
}

.p-70px {
  padding: 70px !important;
}

.p-71px {
  padding: 71px !important;
}

.p-72px {
  padding: 72px !important;
}

.p-73px {
  padding: 73px !important;
}

.p-74px {
  padding: 74px !important;
}

.p-75px {
  padding: 75px !important;
}

.p-76px {
  padding: 76px !important;
}

.p-77px {
  padding: 77px !important;
}

.p-78px {
  padding: 78px !important;
}

.p-79px {
  padding: 79px !important;
}

.p-80px {
  padding: 80px !important;
}

.p-81px {
  padding: 81px !important;
}

.p-82px {
  padding: 82px !important;
}

.p-83px {
  padding: 83px !important;
}

.p-84px {
  padding: 84px !important;
}

.p-85px {
  padding: 85px !important;
}

.p-86px {
  padding: 86px !important;
}

.p-87px {
  padding: 87px !important;
}

.p-88px {
  padding: 88px !important;
}

.p-89px {
  padding: 89px !important;
}

.p-90px {
  padding: 90px !important;
}

.p-91px {
  padding: 91px !important;
}

.p-92px {
  padding: 92px !important;
}

.p-93px {
  padding: 93px !important;
}

.p-94px {
  padding: 94px !important;
}

.p-95px {
  padding: 95px !important;
}

.p-96px {
  padding: 96px !important;
}

.p-97px {
  padding: 97px !important;
}

.p-98px {
  padding: 98px !important;
}

.p-99px {
  padding: 99px !important;
}

.p-100px {
  padding: 100px !important;
}

.p-101px {
  padding: 101px !important;
}

.p-102px {
  padding: 102px !important;
}

.p-103px {
  padding: 103px !important;
}

.p-104px {
  padding: 104px !important;
}

.p-105px {
  padding: 105px !important;
}

.p-106px {
  padding: 106px !important;
}

.p-107px {
  padding: 107px !important;
}

.p-108px {
  padding: 108px !important;
}

.p-109px {
  padding: 109px !important;
}

.p-110px {
  padding: 110px !important;
}

.p-111px {
  padding: 111px !important;
}

.p-112px {
  padding: 112px !important;
}

.p-113px {
  padding: 113px !important;
}

.p-114px {
  padding: 114px !important;
}

.p-115px {
  padding: 115px !important;
}

.p-116px {
  padding: 116px !important;
}

.p-117px {
  padding: 117px !important;
}

.p-118px {
  padding: 118px !important;
}

.p-119px {
  padding: 119px !important;
}

.p-120px {
  padding: 120px !important;
}

.p-121px {
  padding: 121px !important;
}

.p-122px {
  padding: 122px !important;
}

.p-123px {
  padding: 123px !important;
}

.p-124px {
  padding: 124px !important;
}

.p-125px {
  padding: 125px !important;
}

.p-126px {
  padding: 126px !important;
}

.p-127px {
  padding: 127px !important;
}

.p-128px {
  padding: 128px !important;
}

.p-129px {
  padding: 129px !important;
}

.p-130px {
  padding: 130px !important;
}

.p-131px {
  padding: 131px !important;
}

.p-132px {
  padding: 132px !important;
}

.p-133px {
  padding: 133px !important;
}

.p-134px {
  padding: 134px !important;
}

.p-135px {
  padding: 135px !important;
}

.p-136px {
  padding: 136px !important;
}

.p-137px {
  padding: 137px !important;
}

.p-138px {
  padding: 138px !important;
}

.p-139px {
  padding: 139px !important;
}

.p-140px {
  padding: 140px !important;
}

.p-141px {
  padding: 141px !important;
}

.p-142px {
  padding: 142px !important;
}

.p-143px {
  padding: 143px !important;
}

.p-144px {
  padding: 144px !important;
}

.p-145px {
  padding: 145px !important;
}

.p-146px {
  padding: 146px !important;
}

.p-147px {
  padding: 147px !important;
}

.p-148px {
  padding: 148px !important;
}

.p-149px {
  padding: 149px !important;
}

.p-150px {
  padding: 150px !important;
}

.p-151px {
  padding: 151px !important;
}

.p-152px {
  padding: 152px !important;
}

.p-153px {
  padding: 153px !important;
}

.p-154px {
  padding: 154px !important;
}

.p-155px {
  padding: 155px !important;
}

.p-156px {
  padding: 156px !important;
}

.p-157px {
  padding: 157px !important;
}

.p-158px {
  padding: 158px !important;
}

.p-159px {
  padding: 159px !important;
}

.p-160px {
  padding: 160px !important;
}

.p-161px {
  padding: 161px !important;
}

.p-162px {
  padding: 162px !important;
}

.p-163px {
  padding: 163px !important;
}

.p-164px {
  padding: 164px !important;
}

.p-165px {
  padding: 165px !important;
}

.p-166px {
  padding: 166px !important;
}

.p-167px {
  padding: 167px !important;
}

.p-168px {
  padding: 168px !important;
}

.p-169px {
  padding: 169px !important;
}

.p-170px {
  padding: 170px !important;
}

.p-171px {
  padding: 171px !important;
}

.p-172px {
  padding: 172px !important;
}

.p-173px {
  padding: 173px !important;
}

.p-174px {
  padding: 174px !important;
}

.p-175px {
  padding: 175px !important;
}

.p-176px {
  padding: 176px !important;
}

.p-177px {
  padding: 177px !important;
}

.p-178px {
  padding: 178px !important;
}

.p-179px {
  padding: 179px !important;
}

.p-180px {
  padding: 180px !important;
}

.p-181px {
  padding: 181px !important;
}

.p-182px {
  padding: 182px !important;
}

.p-183px {
  padding: 183px !important;
}

.p-184px {
  padding: 184px !important;
}

.p-185px {
  padding: 185px !important;
}

.p-186px {
  padding: 186px !important;
}

.p-187px {
  padding: 187px !important;
}

.p-188px {
  padding: 188px !important;
}

.p-189px {
  padding: 189px !important;
}

.p-190px {
  padding: 190px !important;
}

.p-191px {
  padding: 191px !important;
}

.p-192px {
  padding: 192px !important;
}

.p-193px {
  padding: 193px !important;
}

.p-194px {
  padding: 194px !important;
}

.p-195px {
  padding: 195px !important;
}

.p-196px {
  padding: 196px !important;
}

.p-197px {
  padding: 197px !important;
}

.p-198px {
  padding: 198px !important;
}

.p-199px {
  padding: 199px !important;
}

.p-200px {
  padding: 200px !important;
}

// percent
.p-0-percent {
  padding: 0% !important;
}
.p-1-percent {
  padding: 1% !important;
}
.p-2-percent {
  padding: 2% !important;
}
.p-3-percent {
  padding: 3% !important;
}
.p-4-percent {
  padding: 4% !important;
}
.p-5-percent {
  padding: 5% !important;
}
.p-6-percent {
  padding: 6% !important;
}
.p-7-percent {
  padding: 7% !important;
}
.p-8-percent {
  padding: 8% !important;
}
.p-9-percent {
  padding: 9% !important;
}
.p-10-percent {
  padding: 10% !important;
}
.p-11-percent {
  padding: 11% !important;
}
.p-12-percent {
  padding: 12% !important;
}
.p-13-percent {
  padding: 13% !important;
}
.p-14-percent {
  padding: 14% !important;
}
.p-15-percent {
  padding: 15% !important;
}
.p-16-percent {
  padding: 16% !important;
}
.p-17-percent {
  padding: 17% !important;
}
.p-18-percent {
  padding: 18% !important;
}
.p-19-percent {
  padding: 19% !important;
}
.p-20-percent {
  padding: 20% !important;
}
.p-21-percent {
  padding: 21% !important;
}
.p-22-percent {
  padding: 22% !important;
}
.p-23-percent {
  padding: 23% !important;
}
.p-24-percent {
  padding: 24% !important;
}
.p-25-percent {
  padding: 25% !important;
}
.p-26-percent {
  padding: 26% !important;
}
.p-27-percent {
  padding: 27% !important;
}
.p-28-percent {
  padding: 28% !important;
}
.p-29-percent {
  padding: 29% !important;
}
.p-30-percent {
  padding: 30% !important;
}
.p-31-percent {
  padding: 31% !important;
}
.p-32-percent {
  padding: 32% !important;
}
.p-33-percent {
  padding: 33% !important;
}
.p-34-percent {
  padding: 34% !important;
}
.p-35-percent {
  padding: 35% !important;
}
.p-36-percent {
  padding: 36% !important;
}
.p-37-percent {
  padding: 37% !important;
}
.p-38-percent {
  padding: 38% !important;
}
.p-39-percent {
  padding: 39% !important;
}
.p-40-percent {
  padding: 40% !important;
}
.p-41-percent {
  padding: 41% !important;
}
.p-42-percent {
  padding: 42% !important;
}
.p-43-percent {
  padding: 43% !important;
}
.p-44-percent {
  padding: 44% !important;
}
.p-45-percent {
  padding: 45% !important;
}
.p-46-percent {
  padding: 46% !important;
}
.p-47-percent {
  padding: 47% !important;
}
.p-48-percent {
  padding: 48% !important;
}
.p-49-percent {
  padding: 49% !important;
}
.p-50-percent {
  padding: 50% !important;
}
.p-51-percent {
  padding: 51% !important;
}
.p-52-percent {
  padding: 52% !important;
}
.p-53-percent {
  padding: 53% !important;
}
.p-54-percent {
  padding: 54% !important;
}
.p-55-percent {
  padding: 55% !important;
}
.p-56-percent {
  padding: 56% !important;
}
.p-57-percent {
  padding: 57% !important;
}
.p-58-percent {
  padding: 58% !important;
}
.p-59-percent {
  padding: 59% !important;
}
.p-60-percent {
  padding: 60% !important;
}
.p-61-percent {
  padding: 61% !important;
}
.p-62-percent {
  padding: 62% !important;
}
.p-63-percent {
  padding: 63% !important;
}
.p-64-percent {
  padding: 64% !important;
}
.p-65-percent {
  padding: 65% !important;
}
.p-66-percent {
  padding: 66% !important;
}
.p-67-percent {
  padding: 67% !important;
}
.p-68-percent {
  padding: 68% !important;
}
.p-69-percent {
  padding: 69% !important;
}
.p-70-percent {
  padding: 70% !important;
}
.p-71-percent {
  padding: 71% !important;
}
.p-72-percent {
  padding: 72% !important;
}
.p-73-percent {
  padding: 73% !important;
}
.p-74-percent {
  padding: 74% !important;
}
.p-75-percent {
  padding: 75% !important;
}
.p-76-percent {
  padding: 76% !important;
}
.p-77-percent {
  padding: 77% !important;
}
.p-78-percent {
  padding: 78% !important;
}
.p-79-percent {
  padding: 79% !important;
}
.p-80-percent {
  padding: 80% !important;
}
.p-81-percent {
  padding: 81% !important;
}
.p-82-percent {
  padding: 82% !important;
}
.p-83-percent {
  padding: 83% !important;
}
.p-84-percent {
  padding: 84% !important;
}
.p-85-percent {
  padding: 85% !important;
}
.p-86-percent {
  padding: 86% !important;
}
.p-87-percent {
  padding: 87% !important;
}
.p-88-percent {
  padding: 88% !important;
}
.p-89-percent {
  padding: 89% !important;
}
.p-90-percent {
  padding: 90% !important;
}
.p-91-percent {
  padding: 91% !important;
}
.p-92-percent {
  padding: 92% !important;
}
.p-93-percent {
  padding: 93% !important;
}
.p-94-percent {
  padding: 94% !important;
}
.p-95-percent {
  padding: 95% !important;
}
.p-96-percent {
  padding: 96% !important;
}
.p-97-percent {
  padding: 97% !important;
}
.p-98-percent {
  padding: 98% !important;
}
.p-99-percent {
  padding: 99% !important;
}
.p-100-percent {
  padding: 100% !important;
}

// rem
