.w-40-60px {
  width: calc(40% - 60px) !important;
}
.w-50-10px {
  width: calc(50% - 10px) !important;
}
.w-50-50px {
  width: calc(50% - 50px) !important;
}

/* Width of % with cal*/
.w-100-30px {
  width: calc(100% - 30px) !important;
}

.w-100-40px {
  width: calc(100% - 40px) !important;
}

.w-100-50px {
  width: calc(100% - 50px) !important;
}

.w-100-55px {
  width: calc(100% - 55px) !important;
}

.w-100-56px {
  width: calc(100% - 56px) !important;
}

.w-100-58px {
  width: calc(100% - 58px) !important;
}

.w-100-60px {
  width: calc(100% - 60px) !important;
}

.w-100-70px {
  width: calc(100% - 70px) !important;
}

.w-100-73px {
  width: calc(100% - 73px) !important;
}

.w-100-80px {
  width: calc(100% - 80px) !important;
}

.w-100-110px {
  width: calc(100% - 110px) !important;
}
.w-100-115px {
  width: calc(100% - 115px) !important;
}

.w-100-120px {
  width: calc(100% - 120px) !important;
}

.w-100-140px {
  width: calc(100% - 140px) !important;
}

.w-100-155px {
  width: calc(100% - 155px) !important;
}

.w-100-160px {
  width: calc(100% - 160px) !important;
}

.w-100-161px {
  width: calc(100% - 161px) !important;
}

.w-100-162px {
  width: calc(100% - 162px) !important;
}

.w-100-163px {
  width: calc(100% - 163px) !important;
}

.w-100-170px {
  width: calc(100% - 170px) !important;
}

.w-100-176px {
  width: calc(100% - 176px) !important;
}

.w-100-180px {
  width: calc(100% - 180px) !important;
}

.w-100-195px {
  width: calc(100% - 195px) !important;
}

.w-100-200px {
  width: calc(100% - 200px) !important;
}

.w-100-220px {
  width: calc(100% - 220px) !important;
}

.w-100-230px {
  width: calc(100% - 230px) !important;
}

.w-100-250px {
  width: calc(100% - 250px) !important;
}

.w-100-360px {
  width: calc(100% - 360px) !important;
}

.w-100-400px {
  width: calc(100% - 400px) !important;
}

.w-100-420px {
  width: calc(100% - 420px) !important;
}

.w-100-450px {
  width: calc(100% - 450px) !important;
}

.w-100-510px {
  width: calc(100% - 510px) !important;
}
