.ss-daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  // z-index: 3001;
  /* display: none; */
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}
.ss-daterangepicker:after,
.ss-daterangepicker:before {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}
.ss-daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.ss-daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.ss-daterangepicker.opensleft:before {
  right: 9px;
}
.ss-daterangepicker.opensleft:after {
  right: 10px;
}
.ss-daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.ss-daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.ss-daterangepicker.opensright:before {
  left: 9px;
}
.ss-daterangepicker.opensright:after {
  left: 10px;
}
.ss-daterangepicker.drop-up {
  margin-top: -7px;
}
.ss-daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.ss-daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}
.ss-daterangepicker.single .ss-daterangepicker .ranges,
.ss-daterangepicker.single .drp-calendar {
  float: none;
}
.ss-daterangepicker.single .drp-selected {
  display: none;
}
.ss-daterangepicker.show-calendar .drp-calendar {
  display: block;
}
.ss-daterangepicker.show-calendar .drp-buttons {
  display: block;
}
.ss-daterangepicker.auto-apply .drp-buttons {
  display: none;
}
.ss-daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}
.ss-daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}
.ss-daterangepicker .drp-calendar.right {
  padding: 8px;
}
.ss-daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}
.ss-daterangepicker .calendar-table .next span,
.ss-daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.ss-daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.ss-daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.ss-daterangepicker .calendar-table td,
.ss-daterangepicker .calendar-table th {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.ss-daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}
.ss-daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.ss-daterangepicker td.available:hover,
.ss-daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.ss-daterangepicker td.week,
.ss-daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.ss-daterangepicker td.off,
.ss-daterangepicker td.off.end-date,
.ss-daterangepicker td.off.in-range,
.ss-daterangepicker td.off.start-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}
.ss-daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}
.ss-daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.ss-daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.ss-daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.ss-daterangepicker td.active,
.ss-daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}
.ss-daterangepicker th.month {
  width: auto;
}
.ss-daterangepicker option.disabled,
.ss-daterangepicker td.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.ss-daterangepicker select.monthselect,
.ss-daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.ss-daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.ss-daterangepicker select.yearselect {
  width: 40%;
}
.ss-daterangepicker select.ampmselect,
.ss-daterangepicker select.hourselect,
.ss-daterangepicker select.minuteselect,
.ss-daterangepicker select.secondselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}
.ss-daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}
.ss-daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.ss-daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}
.ss-daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}
.ss-daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
}
.ss-daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}
.ss-daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.ss-daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}
.ss-daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}
.ss-daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}
.ss-daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}
.ss-daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.ss-daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}
.ss-daterangepicker .ranges li:hover {
  background-color: #eee;
}
.ss-daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}
@media (min-width: 564px) {
  .ss-daterangepicker {
    width: auto;
  }
  .ss-daterangepicker .ranges ul {
    width: 140px;
  }
  .ss-daterangepicker.single .ranges ul {
    width: 100%;
  }
  .ss-daterangepicker.single .drp-calendar.left {
    clear: none;
  }
  .ss-daterangepicker.single .drp-calendar,
  .ss-daterangepicker.single .ranges {
    float: left;
  }
  .ss-daterangepicker {
    direction: ltr;
    text-align: left;
  }
  .ss-daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }
  .ss-daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ss-daterangepicker .drp-calendar.right {
    margin-left: 0;
  }
  .ss-daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ss-daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }
  .ss-daterangepicker .drp-calendar,
  .ss-daterangepicker .ranges {
    float: left;
  }
}
@media (min-width: 730px) {
  .ss-daterangepicker .ranges {
    width: auto;
  }
  .ss-daterangepicker .ranges {
    float: left;
  }
  .ss-daterangepicker.rtl .ranges {
    float: right;
  }
  .ss-daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}
.ss-daterangepicker {
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  font-family: Poppins, Helvetica, sans-serif;
  z-index: 1000;
  border-radius: 0.475rem;
}
.ss-daterangepicker:after,
.ss-daterangepicker:before {
  display: none;
}
.modal-open .ss-daterangepicker {
  z-index: 1061;
}
.ss-daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 150px;
}
.ss-daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: #7e8299;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.ss-daterangepicker .ranges li:hover {
  background-color: #ecf8ff;
  color: #009ef7;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.ss-daterangepicker .ranges li.active {
  background-color: #009ef7;
  color: #fff;
  transition: color 0.2s ease, background-color 0.2s ease;
}
.ss-daterangepicker.show-calendar .ranges {
  border-right: 1px solid #eff2f5;
  margin-top: 0;
  /* height: 297px; */
}
.ss-daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.ss-daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid #eff2f5;
}
.ss-daterangepicker .drp-buttons .btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.ss-daterangepicker .drp-buttons .cancelBtn {
  color: #7e8299;
  border-color: #f5f8fa;
  background-color: #f5f8fa;
}
.ss-daterangepicker .drp-buttons .cancelBtn i {
  color: #7e8299;
}
.ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.ss-daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: #7e8299;
}
.btn-check:active + .ss-daterangepicker .drp-buttons .cancelBtn,
.btn-check:checked + .ss-daterangepicker .drp-buttons .cancelBtn,
.ss-daterangepicker .drp-buttons .cancelBtn.active,
.ss-daterangepicker .drp-buttons .cancelBtn.show,
.ss-daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active),
.ss-daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active),
.ss-daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active),
.show > .ss-daterangepicker .drp-buttons .cancelBtn {
  color: #7e8299;
  border-color: #e4e6ef;
  background-color: #e4e6ef;
}
.btn-check:active + .ss-daterangepicker .drp-buttons .cancelBtn i,
.btn-check:checked + .ss-daterangepicker .drp-buttons .cancelBtn i,
.ss-daterangepicker .drp-buttons .cancelBtn.active i,
.ss-daterangepicker .drp-buttons .cancelBtn.show i,
.ss-daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) i,
.ss-daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) i,
.ss-daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) i,
.show > .ss-daterangepicker .drp-buttons .cancelBtn i {
  color: #7e8299;
}
.btn-check:active + .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g),
.btn-check:checked + .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn.active .svg-icon svg [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn.show .svg-icon svg [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g),
.show > .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #7e8299;
}
.btn-check:active + .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g),
.btn-check:checked + .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn.active .svg-icon svg:hover [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn.show .svg-icon svg:hover [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.ss-daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) .svg-icon svg:hover [fill]:not(.permanent):not(g),
.show > .ss-daterangepicker .drp-buttons .cancelBtn .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}
.btn-check:active + .ss-daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after,
.btn-check:checked + .ss-daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after,
.ss-daterangepicker .drp-buttons .cancelBtn.active.dropdown-toggle:after,
.ss-daterangepicker .drp-buttons .cancelBtn.show.dropdown-toggle:after,
.ss-daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active).dropdown-toggle:after,
.ss-daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active).dropdown-toggle:after,
.ss-daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active).dropdown-toggle:after,
.show > .ss-daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: #7e8299;
}
.ss-daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.ss-daterangepicker .drp-calendar.left,
.ss-daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.ss-daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.ss-daterangepicker .drp-calendar td,
.ss-daterangepicker .drp-calendar th {
  font-size: 1rem;
  font-weight: 400;
  width: 33px;
  height: 33px;
}
.ss-daterangepicker .drp-calendar td.available:hover,
.ss-daterangepicker .drp-calendar th.available:hover {
  border-radius: 0.475rem;
  background-color: #ecf8ff;
  color: #009ef7;
}
.ss-daterangepicker .drp-calendar th {
  font-weight: 500;
  color: #3f4254;
}
.ss-daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: #3f4254;
}
.ss-daterangepicker .drp-calendar th.next span,
.ss-daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: #7e8299;
}
.ss-daterangepicker .drp-calendar th.next.available:hover span,
.ss-daterangepicker .drp-calendar th.prev.available:hover span {
  border-color: #009ef7;
}
.ss-daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.ss-daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.ss-daterangepicker .drp-calendar td {
  color: #5e6278;
}
.ss-daterangepicker .drp-calendar td.available.off {
  color: #b5b5c3;
}
.ss-daterangepicker .drp-calendar td.active {
  background-color: #009ef7 !important;
  color: #fff !important;
  border-radius: 0.475rem;
}
.ss-daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ss-daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ss-daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.475rem;
}
.ss-daterangepicker .drp-calendar td.today,
.ss-daterangepicker .drp-calendar td.today.active {
  background: #ecf8ff !important;
  color: #009ef7 !important;
  border-radius: 0.475rem;
}
.ss-daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: #ecf8ff;
  color: #009ef7;
}
.ss-daterangepicker .drp-calendar td:hover {
  background-color: #ecf8ff;
  color: #009ef7;
}
.ss-daterangepicker select.ampmselect,
.ss-daterangepicker select.hourselect,
.ss-daterangepicker select.minuteselect,
.ss-daterangepicker select.monthselect,
.ss-daterangepicker select.yearselect {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  border-radius: 0.475rem;
  background: 0 0;
  border-color: transparent;
  color: #181c32;
  font-weight: 500;
  outline: 0 !important;
}
.ss-daterangepicker select.ampmselect:focus,
.ss-daterangepicker select.hourselect:focus,
.ss-daterangepicker select.minuteselect:focus,
.ss-daterangepicker select.monthselect:focus,
.ss-daterangepicker select.yearselect:focus {
  background: #f5f8fa;
}
@media (max-width: 767.98px) {
  .ss-daterangepicker.show-calendar .ranges {
    float: none !important;
    height: auto !important;
  }
  .ss-daterangepicker.show-calendar .ranges ul {
    width: 100%;
  }
  .ss-daterangepicker.show-calendar .drp-calendar {
    float: none !important;
    max-width: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.ss-daterangepicker-container .form-group {
  margin: 0;
}
.ss-daterangepicker-container .form-control {
  font-size: 1.1rem;
}
