.border-width-1px {
  border-width: 1px !important;
}

.border-width-2px {
  border-width: 2px !important;
}

.border-width-3px {
  border-width: 3px !important;
}

// bottom
.border-bottom-width-1px {
  border-bottom-width: 1px !important;
}

.border-bottom-width-2px {
  border-bottom-width: 2px !important;
}

.border-bottom-width-3px {
  border-bottom-width: 3px !important;
}

// top
.border-top-width-1px {
  border-top-width: 1px !important;
}

// left
.border-left-width-1px {
  border-left-width: 1px !important;
}

.border-left-width-6px {
  border-left-width: 6px !important;
}

// right
.border-right-width-1px {
  border-right-width: 1px !important;
}
