.font-size-0px {
  font-size: 0px !important;
}

.font-size-1px {
  font-size: 1px !important;
}

.font-size-2px {
  font-size: 2px !important;
}

.font-size-3px {
  font-size: 3px !important;
}

.font-size-4px {
  font-size: 4px !important;
}

.font-size-5px {
  font-size: 5px !important;
}

.font-size-6px {
  font-size: 6px !important;
}

.font-size-7px {
  font-size: 7px !important;
}

.font-size-8px {
  font-size: 8px !important;
}

.font-size-9px {
  font-size: 9px !important;
}

.font-size-10px {
  font-size: 10px !important;
}

.font-size-11px {
  font-size: 11px !important;
}

.font-size-12px {
  font-size: 12px !important;
}

.font-size-13px {
  font-size: 13px !important;
}

.font-size-13-point-6px {
  // font-size: 13.6px !important;
  font-size: 0.85rem !important;
}

.font-size-14px {
  font-size: 14px !important;
}

.font-size-15px {
  font-size: 15px !important;
}

.font-size-15-point-2px {
  // font-size: 15.2px !important;
  font-size: 0.95rem !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.font-size-17px {
  font-size: 17px !important;
}

.font-size-17-point-2px {
  // font-size: 17.2px !important;
  font-size: 1.075rem !important;
}

.font-size-18px {
  font-size: 18px !important;
}

.font-size-18-point-4px {
  // font-size: 18.4px !important;
  font-size: 1.15rem !important;
}

.font-size-19px {
  font-size: 19px !important;
}

.font-size-20px {
  font-size: 20px !important;
}

.font-size-21px {
  font-size: 21px !important;
}

.font-size-22px {
  font-size: 22px !important;
}

.font-size-23px {
  font-size: 23px !important;
}

.font-size-24px {
  font-size: 24px !important;
}

.font-size-25px {
  font-size: 25px !important;
}

.font-size-26px {
  font-size: 26px !important;
}

.font-size-27px {
  font-size: 27px !important;
}

.font-size-28px {
  font-size: 28px !important;
}

.font-size-29px {
  font-size: 29px !important;
}

.font-size-30px {
  font-size: 30px !important;
}

.font-size-31px {
  font-size: 31px !important;
}

.font-size-32px {
  font-size: 32px !important;
}

.font-size-33px {
  font-size: 33px !important;
}

.font-size-34px {
  font-size: 34px !important;
}

.font-size-35px {
  font-size: 35px !important;
}

.font-size-36px {
  font-size: 36px !important;
}

.font-size-37px {
  font-size: 37px !important;
}

.font-size-38px {
  font-size: 38px !important;
}

.font-size-39px {
  font-size: 39px !important;
}

.font-size-40px {
  font-size: 40px !important;
}

.font-size-41px {
  font-size: 41px !important;
}

.font-size-42px {
  font-size: 42px !important;
}

.font-size-43px {
  font-size: 43px !important;
}

.font-size-44px {
  font-size: 44px !important;
}

.font-size-45px {
  font-size: 45px !important;
}

.font-size-46px {
  font-size: 46px !important;
}

.font-size-47px {
  font-size: 47px !important;
}

.font-size-48px {
  font-size: 48px !important;
}

.font-size-49px {
  font-size: 49px !important;
}

.font-size-50px {
  font-size: 50px !important;
}

// specific
.font-size-150px {
  font-size: 150px !important;
}