.border-radius-0px {
  border-radius: 0px !important;
}
.border-radius-1px {
  border-radius: 1px !important;
}
.border-radius-2px {
  border-radius: 2px !important;
}
.border-radius-3px {
  border-radius: 3px !important;
}
.border-radius-4px {
  border-radius: 4px !important;
}
.border-radius-5px {
  border-radius: 5px !important;
}
.border-radius-6px {
  border-radius: 6px !important;
}
.border-radius-7px {
  border-radius: 7px !important;
}
.border-radius-8px {
  border-radius: 8px !important;
}
.border-radius-9px {
  border-radius: 9px !important;
}
.border-radius-10px {
  border-radius: 10px !important;
}
.border-radius-11px {
  border-radius: 11px !important;
}
.border-radius-12px {
  border-radius: 12px !important;
}
.border-radius-13px {
  border-radius: 13px !important;
}
.border-radius-14px {
  border-radius: 14px !important;
}
.border-radius-15px {
  border-radius: 15px !important;
}
.border-radius-16px {
  border-radius: 16px !important;
}
.border-radius-17px {
  border-radius: 17px !important;
}
.border-radius-18px {
  border-radius: 18px !important;
}
.border-radius-19px {
  border-radius: 19px !important;
}
.border-radius-20px {
  border-radius: 20px !important;
}
.border-radius-21px {
  border-radius: 21px !important;
}
.border-radius-22px {
  border-radius: 22px !important;
}
.border-radius-23px {
  border-radius: 23px !important;
}
.border-radius-24px {
  border-radius: 24px !important;
}
.border-radius-25px {
  border-radius: 25px !important;
}
.border-radius-26px {
  border-radius: 26px !important;
}
.border-radius-27px {
  border-radius: 27px !important;
}
.border-radius-28px {
  border-radius: 28px !important;
}
.border-radius-29px {
  border-radius: 29px !important;
}
.border-radius-30px {
  border-radius: 30px !important;
}
.border-radius-31px {
  border-radius: 31px !important;
}
.border-radius-32px {
  border-radius: 32px !important;
}
.border-radius-33px {
  border-radius: 33px !important;
}
.border-radius-34px {
  border-radius: 34px !important;
}
.border-radius-35px {
  border-radius: 35px !important;
}
.border-radius-36px {
  border-radius: 36px !important;
}
.border-radius-37px {
  border-radius: 37px !important;
}
.border-radius-38px {
  border-radius: 38px !important;
}
.border-radius-39px {
  border-radius: 39px !important;
}
.border-radius-40px {
  border-radius: 40px !important;
}
.border-radius-41px {
  border-radius: 41px !important;
}
.border-radius-42px {
  border-radius: 42px !important;
}
.border-radius-43px {
  border-radius: 43px !important;
}
.border-radius-44px {
  border-radius: 44px !important;
}
.border-radius-45px {
  border-radius: 45px !important;
}
.border-radius-46px {
  border-radius: 46px !important;
}
.border-radius-47px {
  border-radius: 47px !important;
}
.border-radius-48px {
  border-radius: 48px !important;
}
.border-radius-49px {
  border-radius: 49px !important;
}
.border-radius-50px {
  border-radius: 50px !important;
}
.border-radius-51px {
  border-radius: 51px !important;
}
.border-radius-52px {
  border-radius: 52px !important;
}
.border-radius-53px {
  border-radius: 53px !important;
}
.border-radius-54px {
  border-radius: 54px !important;
}
.border-radius-55px {
  border-radius: 55px !important;
}
.border-radius-56px {
  border-radius: 56px !important;
}
.border-radius-57px {
  border-radius: 57px !important;
}
.border-radius-58px {
  border-radius: 58px !important;
}
.border-radius-59px {
  border-radius: 59px !important;
}
.border-radius-60px {
  border-radius: 60px !important;
}
.border-radius-61px {
  border-radius: 61px !important;
}
.border-radius-62px {
  border-radius: 62px !important;
}
.border-radius-63px {
  border-radius: 63px !important;
}
.border-radius-64px {
  border-radius: 64px !important;
}
.border-radius-65px {
  border-radius: 65px !important;
}
.border-radius-66px {
  border-radius: 66px !important;
}
.border-radius-67px {
  border-radius: 67px !important;
}
.border-radius-68px {
  border-radius: 68px !important;
}
.border-radius-69px {
  border-radius: 69px !important;
}
.border-radius-70px {
  border-radius: 70px !important;
}
.border-radius-71px {
  border-radius: 71px !important;
}
.border-radius-72px {
  border-radius: 72px !important;
}
.border-radius-73px {
  border-radius: 73px !important;
}
.border-radius-74px {
  border-radius: 74px !important;
}
.border-radius-75px {
  border-radius: 75px !important;
}
.border-radius-76px {
  border-radius: 76px !important;
}
.border-radius-77px {
  border-radius: 77px !important;
}
.border-radius-78px {
  border-radius: 78px !important;
}
.border-radius-79px {
  border-radius: 79px !important;
}
.border-radius-80px {
  border-radius: 80px !important;
}
.border-radius-81px {
  border-radius: 81px !important;
}
.border-radius-82px {
  border-radius: 82px !important;
}
.border-radius-83px {
  border-radius: 83px !important;
}
.border-radius-84px {
  border-radius: 84px !important;
}
.border-radius-85px {
  border-radius: 85px !important;
}
.border-radius-86px {
  border-radius: 86px !important;
}
.border-radius-87px {
  border-radius: 87px !important;
}
.border-radius-88px {
  border-radius: 88px !important;
}
.border-radius-89px {
  border-radius: 89px !important;
}
.border-radius-90px {
  border-radius: 90px !important;
}
.border-radius-91px {
  border-radius: 91px !important;
}
.border-radius-92px {
  border-radius: 92px !important;
}
.border-radius-93px {
  border-radius: 93px !important;
}
.border-radius-94px {
  border-radius: 94px !important;
}
.border-radius-95px {
  border-radius: 95px !important;
}
.border-radius-96px {
  border-radius: 96px !important;
}
.border-radius-97px {
  border-radius: 97px !important;
}
.border-radius-98px {
  border-radius: 98px !important;
}
.border-radius-99px {
  border-radius: 99px !important;
}
.border-radius-100px {
  border-radius: 100px !important;
}

// in percent %
.border-radius-0-percent {
  border-radius: 0% !important;
}
.border-radius-1-percent {
  border-radius: 1% !important;
}
.border-radius-2-percent {
  border-radius: 2% !important;
}
.border-radius-3-percent {
  border-radius: 3% !important;
}
.border-radius-4-percent {
  border-radius: 4% !important;
}
.border-radius-5-percent {
  border-radius: 5% !important;
}
.border-radius-6-percent {
  border-radius: 6% !important;
}
.border-radius-7-percent {
  border-radius: 7% !important;
}
.border-radius-8-percent {
  border-radius: 8% !important;
}
.border-radius-9-percent {
  border-radius: 9% !important;
}
.border-radius-10-percent {
  border-radius: 10% !important;
}
.border-radius-11-percent {
  border-radius: 11% !important;
}
.border-radius-12-percent {
  border-radius: 12% !important;
}
.border-radius-13-percent {
  border-radius: 13% !important;
}
.border-radius-14-percent {
  border-radius: 14% !important;
}
.border-radius-15-percent {
  border-radius: 15% !important;
}
.border-radius-16-percent {
  border-radius: 16% !important;
}
.border-radius-17-percent {
  border-radius: 17% !important;
}
.border-radius-18-percent {
  border-radius: 18% !important;
}
.border-radius-19-percent {
  border-radius: 19% !important;
}
.border-radius-20-percent {
  border-radius: 20% !important;
}
.border-radius-21-percent {
  border-radius: 21% !important;
}
.border-radius-22-percent {
  border-radius: 22% !important;
}
.border-radius-23-percent {
  border-radius: 23% !important;
}
.border-radius-24-percent {
  border-radius: 24% !important;
}
.border-radius-25-percent {
  border-radius: 25% !important;
}
.border-radius-26-percent {
  border-radius: 26% !important;
}
.border-radius-27-percent {
  border-radius: 27% !important;
}
.border-radius-28-percent {
  border-radius: 28% !important;
}
.border-radius-29-percent {
  border-radius: 29% !important;
}
.border-radius-30-percent {
  border-radius: 30% !important;
}
.border-radius-31-percent {
  border-radius: 31% !important;
}
.border-radius-32-percent {
  border-radius: 32% !important;
}
.border-radius-33-percent {
  border-radius: 33% !important;
}
.border-radius-34-percent {
  border-radius: 34% !important;
}
.border-radius-35-percent {
  border-radius: 35% !important;
}
.border-radius-36-percent {
  border-radius: 36% !important;
}
.border-radius-37-percent {
  border-radius: 37% !important;
}
.border-radius-38-percent {
  border-radius: 38% !important;
}
.border-radius-39-percent {
  border-radius: 39% !important;
}
.border-radius-40-percent {
  border-radius: 40% !important;
}
.border-radius-41-percent {
  border-radius: 41% !important;
}
.border-radius-42-percent {
  border-radius: 42% !important;
}
.border-radius-43-percent {
  border-radius: 43% !important;
}
.border-radius-44-percent {
  border-radius: 44% !important;
}
.border-radius-45-percent {
  border-radius: 45% !important;
}
.border-radius-46-percent {
  border-radius: 46% !important;
}
.border-radius-47-percent {
  border-radius: 47% !important;
}
.border-radius-48-percent {
  border-radius: 48% !important;
}
.border-radius-49-percent {
  border-radius: 49% !important;
}
.border-radius-50-percent {
  border-radius: 50% !important;
}
.border-radius-51-percent {
  border-radius: 51% !important;
}
.border-radius-52-percent {
  border-radius: 52% !important;
}
.border-radius-53-percent {
  border-radius: 53% !important;
}
.border-radius-54-percent {
  border-radius: 54% !important;
}
.border-radius-55-percent {
  border-radius: 55% !important;
}
.border-radius-56-percent {
  border-radius: 56% !important;
}
.border-radius-57-percent {
  border-radius: 57% !important;
}
.border-radius-58-percent {
  border-radius: 58% !important;
}
.border-radius-59-percent {
  border-radius: 59% !important;
}
.border-radius-60-percent {
  border-radius: 60% !important;
}
.border-radius-61-percent {
  border-radius: 61% !important;
}
.border-radius-62-percent {
  border-radius: 62% !important;
}
.border-radius-63-percent {
  border-radius: 63% !important;
}
.border-radius-64-percent {
  border-radius: 64% !important;
}
.border-radius-65-percent {
  border-radius: 65% !important;
}
.border-radius-66-percent {
  border-radius: 66% !important;
}
.border-radius-67-percent {
  border-radius: 67% !important;
}
.border-radius-68-percent {
  border-radius: 68% !important;
}
.border-radius-69-percent {
  border-radius: 69% !important;
}
.border-radius-70-percent {
  border-radius: 70% !important;
}
.border-radius-71-percent {
  border-radius: 71% !important;
}
.border-radius-72-percent {
  border-radius: 72% !important;
}
.border-radius-73-percent {
  border-radius: 73% !important;
}
.border-radius-74-percent {
  border-radius: 74% !important;
}
.border-radius-75-percent {
  border-radius: 75% !important;
}
.border-radius-76-percent {
  border-radius: 76% !important;
}
.border-radius-77-percent {
  border-radius: 77% !important;
}
.border-radius-78-percent {
  border-radius: 78% !important;
}
.border-radius-79-percent {
  border-radius: 79% !important;
}
.border-radius-80-percent {
  border-radius: 80% !important;
}
.border-radius-81-percent {
  border-radius: 81% !important;
}
.border-radius-82-percent {
  border-radius: 82% !important;
}
.border-radius-83-percent {
  border-radius: 83% !important;
}
.border-radius-84-percent {
  border-radius: 84% !important;
}
.border-radius-85-percent {
  border-radius: 85% !important;
}
.border-radius-86-percent {
  border-radius: 86% !important;
}
.border-radius-87-percent {
  border-radius: 87% !important;
}
.border-radius-88-percent {
  border-radius: 88% !important;
}
.border-radius-89-percent {
  border-radius: 89% !important;
}
.border-radius-90-percent {
  border-radius: 90% !important;
}
.border-radius-91-percent {
  border-radius: 91% !important;
}
.border-radius-92-percent {
  border-radius: 92% !important;
}
.border-radius-93-percent {
  border-radius: 93% !important;
}
.border-radius-94-percent {
  border-radius: 94% !important;
}
.border-radius-95-percent {
  border-radius: 95% !important;
}
.border-radius-96-percent {
  border-radius: 96% !important;
}
.border-radius-97-percent {
  border-radius: 97% !important;
}
.border-radius-98-percent {
  border-radius: 98% !important;
}
.border-radius-99-percent {
  border-radius: 99% !important;
}
.border-radius-100-percent {
  border-radius: 100% !important;
}
