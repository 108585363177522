/* *************************************** */
.switch1 {
  position: relative !important;
  display: inline-block !important;
  width: 55px !important;
  height: 30px !important;
}

.switch1 input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 22px !important;
  width: 22px !important;
  left: 4px !important;
  bottom: 4px !important;
  background-color: white !important;
  -webkit-transition: 0.4s !important;
  transition: 0.4s !important;
}

input:checked + .slider {
  background-color: #1bc5bd !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1bc5bd !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px) !important;
  -ms-transform: translateX(26px) !important;
  transform: translateX(26px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}
/********************************************************/

/***********************DropDown************************/
.dropdown-hover:focus,
.dropdown-hover:hover,
.dropdown-hover:active {
  color: #009ef7 !important;
  text-decoration: none;
  background-color: #ecf8ff !important;
}

.dropdown-hover {
  margin: 5;
}
.dropdown-item.active,
.dropdown-item:active {
  color: black !important;
  background-color: white !important;
}

/*********************************************************/

/* .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    /* border: solid 1px !important; */
/* padding: calc(.75rem + 1px) calc(1.5rem + 1px); */
/* } */
*/ .active-bg-color {
  background-color: #4badfe !important;
}
.beta-container {
  background-color: #ffa800;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 10px;
  line-height: 10px;
  padding: 4px 6px;
}

.custom-currency-container {
  background-color: #f3f6f9;
  border-color: #f3f6f9 !important;
  color: #3f4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.new-tag {
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  font-family: Arial, sans-serif;
  font-size: 10px;
  line-height: 13px;
  padding: 4px 13px;
  position: absolute;
  right: 12px;
  top: 9px;
  /* right: 30px;
  top: 34px; */
  background: #317ae2;
}

.table-td {
  width: 22%;
}
.table-td > div {
  overflow: hidden;
  word-wrap: break-word;
}

.setting-currency-dropdown {
  top: 3px !important;
}
