.opacity-0 {
  opacity: 0 !important;
}

.opacity-0-point-1 {
  opacity: 0.1 !important;
}

.opacity-0-point-2 {
  opacity: 0.2 !important;
}
.opacity-0-point-3 {
  opacity: 0.3 !important;
}

.opacity-0-point-4 {
  opacity: 0.4 !important;
}

.opacity-0-point-5 {
  opacity: 0.5 !important;
}

.opacity-0-point-6 {
  opacity: 0.6 !important;
}

.opacity-0-point-7 {
  opacity: 0.7 !important;
}

.opacity-0-point-8 {
  opacity: 0.8 !important;
}

.opacity-0-point-9 {
  opacity: 0.9 !important;
}

.opacity-1 {
  opacity: 1 !important;
}
