.box-sizing-border-box {
    box-sizing: border-box;
}
.w-50prc {
    width: 50%;
}
.w-25prc {
    width: 25%;
}
.w-15prc {
    width: 15%;
}