// top
.top-0 {
  top: 0 !important;
}
.top-1px {
  top: 1px;
}
.top-10px {
  top: 10px !important;
}
.top-15px {
  top: 15px !important;
}
.top-18px {
  top: 18px !important;
}
.top-30px {
  top: 30px !important;
}
.top-70px {
  top: 70px !important;
}

.top-minus-7px {
  top: -7px !important;
}
.top-minus-10px {
  top: -10px !important;
}
.top-minus-20px {
  top: -20px !important;
}
.top-minus-35px {
  top: -35px !important;
}
.top-minus-39px {
  top: -39px !important;
}
.top-minus-55px {
  top: -55px !important;
}
