.pr-0px,
.px-0px {
  padding-right: 0px !important;
}

.pr-1px,
.px-1px {
  padding-right: 1px !important;
}

.pr-2px,
.px-2px {
  padding-right: 2px !important;
}

.pr-3px,
.px-3px {
  padding-right: 3px !important;
}

.pr-4px,
.px-4px {
  padding-right: 4px !important;
}

.pr-5px,
.px-5px {
  padding-right: 5px !important;
}

.pr-6px,
.px-6px {
  padding-right: 6px !important;
}

.pr-7px,
.px-7px {
  padding-right: 7px !important;
}

.pr-8px,
.px-8px {
  padding-right: 8px !important;
}

.pr-9px,
.px-9px {
  padding-right: 9px !important;
}
.pr-9-point-6px,
.px-9-point-6px {
  padding-right: 9.6px !important;
}

.pr-10px,
.px-10px {
  padding-right: 10px !important;
}

.pr-11px,
.px-11px {
  padding-right: 11px !important;
}

.pr-12px,
.px-12px {
  padding-right: 12px !important;
}

.pr-13px,
.px-13px {
  padding-right: 13px !important;
}

.pr-14px,
.px-14px {
  padding-right: 14px !important;
}

.pr-15px,
.px-15px {
  padding-right: 15px !important;
}

.pr-16px,
.px-16px {
  padding-right: 16px !important;
}

.pr-17px,
.px-17px {
  padding-right: 17px !important;
}
.pr-17-point-6px,
.px-17-point-6px {
  padding-right: 17.6px !important;
}

.pr-18px,
.px-18px {
  padding-right: 18px !important;
}

.pr-19px,
.px-19px {
  padding-right: 19px !important;
}

.pr-20px,
.px-20px {
  padding-right: 20px !important;
}

.pr-21px,
.px-21px {
  padding-right: 21px !important;
}

.pr-22px,
.px-22px {
  padding-right: 22px !important;
}

.pr-23px,
.px-23px {
  padding-right: 23px !important;
}

.pr-24px,
.px-24px {
  padding-right: 24px !important;
}

.pr-25px,
.px-25px {
  padding-right: 25px !important;
}

.pr-26px,
.px-26px {
  padding-right: 26px !important;
}

.pr-27px,
.px-27px {
  padding-right: 27px !important;
}

.pr-28px,
.px-28px {
  padding-right: 28px !important;
}

.pr-29px,
.px-29px {
  padding-right: 29px !important;
}

.pr-30px,
.px-30px {
  padding-right: 30px !important;
}

.pr-31px,
.px-31px {
  padding-right: 31px !important;
}

.pr-32px,
.px-32px {
  padding-right: 32px !important;
}

.pr-33px,
.px-33px {
  padding-right: 33px !important;
}

.pr-34px,
.px-34px {
  padding-right: 34px !important;
}

.pr-35px,
.px-35px {
  padding-right: 35px !important;
}

.pr-36px,
.px-36px {
  padding-right: 36px !important;
}

.pr-37px,
.px-37px {
  padding-right: 37px !important;
}

.pr-38px,
.px-38px {
  padding-right: 38px !important;
}

.pr-39px,
.px-39px {
  padding-right: 39px !important;
}

.pr-40px,
.px-40px {
  padding-right: 40px !important;
}

.pr-41px,
.px-41px {
  padding-right: 41px !important;
}

.pr-42px,
.px-42px {
  padding-right: 42px !important;
}

.pr-43px,
.px-43px {
  padding-right: 43px !important;
}

.pr-44px,
.px-44px {
  padding-right: 44px !important;
}

.pr-45px,
.px-45px {
  padding-right: 45px !important;
}

.pr-46px,
.px-46px {
  padding-right: 46px !important;
}

.pr-47px,
.px-47px {
  padding-right: 47px !important;
}

.pr-48px,
.px-48px {
  padding-right: 48px !important;
}

.pr-49px,
.px-49px {
  padding-right: 49px !important;
}

.pr-50px,
.px-50px {
  padding-right: 50px !important;
}

.pr-51px,
.px-51px {
  padding-right: 51px !important;
}

.pr-52px,
.px-52px {
  padding-right: 52px !important;
}

.pr-53px,
.px-53px {
  padding-right: 53px !important;
}

.pr-54px,
.px-54px {
  padding-right: 54px !important;
}

.pr-55px,
.px-55px {
  padding-right: 55px !important;
}

.pr-56px,
.px-56px {
  padding-right: 56px !important;
}

.pr-57px,
.px-57px {
  padding-right: 57px !important;
}

.pr-58px,
.px-58px {
  padding-right: 58px !important;
}

.pr-59px,
.px-59px {
  padding-right: 59px !important;
}

.pr-60px,
.px-60px {
  padding-right: 60px !important;
}

.pr-61px,
.px-61px {
  padding-right: 61px !important;
}

.pr-62px,
.px-62px {
  padding-right: 62px !important;
}

.pr-63px,
.px-63px {
  padding-right: 63px !important;
}

.pr-64px,
.px-64px {
  padding-right: 64px !important;
}

.pr-65px,
.px-65px {
  padding-right: 65px !important;
}

.pr-66px,
.px-66px {
  padding-right: 66px !important;
}

.pr-67px,
.px-67px {
  padding-right: 67px !important;
}

.pr-68px,
.px-68px {
  padding-right: 68px !important;
}

.pr-69px,
.px-69px {
  padding-right: 69px !important;
}

.pr-70px,
.px-70px {
  padding-right: 70px !important;
}

.pr-71px,
.px-71px {
  padding-right: 71px !important;
}

.pr-72px,
.px-72px {
  padding-right: 72px !important;
}

.pr-73px,
.px-73px {
  padding-right: 73px !important;
}

.pr-74px,
.px-74px {
  padding-right: 74px !important;
}

.pr-75px,
.px-75px {
  padding-right: 75px !important;
}

.pr-76px,
.px-76px {
  padding-right: 76px !important;
}

.pr-77px,
.px-77px {
  padding-right: 77px !important;
}

.pr-78px,
.px-78px {
  padding-right: 78px !important;
}

.pr-79px,
.px-79px {
  padding-right: 79px !important;
}

.pr-80px,
.px-80px {
  padding-right: 80px !important;
}

.pr-81px,
.px-81px {
  padding-right: 81px !important;
}

.pr-82px,
.px-82px {
  padding-right: 82px !important;
}

.pr-83px,
.px-83px {
  padding-right: 83px !important;
}

.pr-84px,
.px-84px {
  padding-right: 84px !important;
}

.pr-85px,
.px-85px {
  padding-right: 85px !important;
}

.pr-86px,
.px-86px {
  padding-right: 86px !important;
}

.pr-87px,
.px-87px {
  padding-right: 87px !important;
}

.pr-88px,
.px-88px {
  padding-right: 88px !important;
}

.pr-89px,
.px-89px {
  padding-right: 89px !important;
}

.pr-90px,
.px-90px {
  padding-right: 90px !important;
}

.pr-91px,
.px-91px {
  padding-right: 91px !important;
}

.pr-92px,
.px-92px {
  padding-right: 92px !important;
}

.pr-93px,
.px-93px {
  padding-right: 93px !important;
}

.pr-94px,
.px-94px {
  padding-right: 94px !important;
}

.pr-95px,
.px-95px {
  padding-right: 95px !important;
}

.pr-96px,
.px-96px {
  padding-right: 96px !important;
}

.pr-97px,
.px-97px {
  padding-right: 97px !important;
}

.pr-98px,
.px-98px {
  padding-right: 98px !important;
}

.pr-99px,
.px-99px {
  padding-right: 99px !important;
}

.pr-100px,
.px-100px {
  padding-right: 100px !important;
}

.pr-101px,
.px-101px {
  padding-right: 101px !important;
}

.pr-102px,
.px-102px {
  padding-right: 102px !important;
}

.pr-103px,
.px-103px {
  padding-right: 103px !important;
}

.pr-104px,
.px-104px {
  padding-right: 104px !important;
}

.pr-105px,
.px-105px {
  padding-right: 105px !important;
}

.pr-106px,
.px-106px {
  padding-right: 106px !important;
}

.pr-107px,
.px-107px {
  padding-right: 107px !important;
}

.pr-108px,
.px-108px {
  padding-right: 108px !important;
}

.pr-109px,
.px-109px {
  padding-right: 109px !important;
}

.pr-110px,
.px-110px {
  padding-right: 110px !important;
}

.pr-111px,
.px-111px {
  padding-right: 111px !important;
}

.pr-112px,
.px-112px {
  padding-right: 112px !important;
}

.pr-113px,
.px-113px {
  padding-right: 113px !important;
}

.pr-114px,
.px-114px {
  padding-right: 114px !important;
}

.pr-115px,
.px-115px {
  padding-right: 115px !important;
}

.pr-116px,
.px-116px {
  padding-right: 116px !important;
}

.pr-117px,
.px-117px {
  padding-right: 117px !important;
}

.pr-118px,
.px-118px {
  padding-right: 118px !important;
}

.pr-119px,
.px-119px {
  padding-right: 119px !important;
}

.pr-120px,
.px-120px {
  padding-right: 120px !important;
}

.pr-121px,
.px-121px {
  padding-right: 121px !important;
}

.pr-122px,
.px-122px {
  padding-right: 122px !important;
}

.pr-123px,
.px-123px {
  padding-right: 123px !important;
}

.pr-124px,
.px-124px {
  padding-right: 124px !important;
}

.pr-125px,
.px-125px {
  padding-right: 125px !important;
}

.pr-126px,
.px-126px {
  padding-right: 126px !important;
}

.pr-127px,
.px-127px {
  padding-right: 127px !important;
}

.pr-128px,
.px-128px {
  padding-right: 128px !important;
}

.pr-129px,
.px-129px {
  padding-right: 129px !important;
}

.pr-130px,
.px-130px {
  padding-right: 130px !important;
}

.pr-131px,
.px-131px {
  padding-right: 131px !important;
}

.pr-132px,
.px-132px {
  padding-right: 132px !important;
}

.pr-133px,
.px-133px {
  padding-right: 133px !important;
}

.pr-134px,
.px-134px {
  padding-right: 134px !important;
}

.pr-135px,
.px-135px {
  padding-right: 135px !important;
}

.pr-136px,
.px-136px {
  padding-right: 136px !important;
}

.pr-137px,
.px-137px {
  padding-right: 137px !important;
}

.pr-138px,
.px-138px {
  padding-right: 138px !important;
}

.pr-139px,
.px-139px {
  padding-right: 139px !important;
}

.pr-140px,
.px-140px {
  padding-right: 140px !important;
}

.pr-141px,
.px-141px {
  padding-right: 141px !important;
}

.pr-142px,
.px-142px {
  padding-right: 142px !important;
}

.pr-143px,
.px-143px {
  padding-right: 143px !important;
}

.pr-144px,
.px-144px {
  padding-right: 144px !important;
}

.pr-145px,
.px-145px {
  padding-right: 145px !important;
}

.pr-146px,
.px-146px {
  padding-right: 146px !important;
}

.pr-147px,
.px-147px {
  padding-right: 147px !important;
}

.pr-148px,
.px-148px {
  padding-right: 148px !important;
}

.pr-149px,
.px-149px {
  padding-right: 149px !important;
}

.pr-150px,
.px-150px {
  padding-right: 150px !important;
}

.pr-151px,
.px-151px {
  padding-right: 151px !important;
}

.pr-152px,
.px-152px {
  padding-right: 152px !important;
}

.pr-153px,
.px-153px {
  padding-right: 153px !important;
}

.pr-154px,
.px-154px {
  padding-right: 154px !important;
}

.pr-155px,
.px-155px {
  padding-right: 155px !important;
}

.pr-156px,
.px-156px {
  padding-right: 156px !important;
}

.pr-157px,
.px-157px {
  padding-right: 157px !important;
}

.pr-158px,
.px-158px {
  padding-right: 158px !important;
}

.pr-159px,
.px-159px {
  padding-right: 159px !important;
}

.pr-160px,
.px-160px {
  padding-right: 160px !important;
}

.pr-161px,
.px-161px {
  padding-right: 161px !important;
}

.pr-162px,
.px-162px {
  padding-right: 162px !important;
}

.pr-163px,
.px-163px {
  padding-right: 163px !important;
}

.pr-164px,
.px-164px {
  padding-right: 164px !important;
}

.pr-165px,
.px-165px {
  padding-right: 165px !important;
}

.pr-166px,
.px-166px {
  padding-right: 166px !important;
}

.pr-167px,
.px-167px {
  padding-right: 167px !important;
}

.pr-168px,
.px-168px {
  padding-right: 168px !important;
}

.pr-169px,
.px-169px {
  padding-right: 169px !important;
}

.pr-170px,
.px-170px {
  padding-right: 170px !important;
}

.pr-171px,
.px-171px {
  padding-right: 171px !important;
}

.pr-172px,
.px-172px {
  padding-right: 172px !important;
}

.pr-173px,
.px-173px {
  padding-right: 173px !important;
}

.pr-174px,
.px-174px {
  padding-right: 174px !important;
}

.pr-175px,
.px-175px {
  padding-right: 175px !important;
}

.pr-176px,
.px-176px {
  padding-right: 176px !important;
}

.pr-177px,
.px-177px {
  padding-right: 177px !important;
}

.pr-178px,
.px-178px {
  padding-right: 178px !important;
}

.pr-179px,
.px-179px {
  padding-right: 179px !important;
}

.pr-180px,
.px-180px {
  padding-right: 180px !important;
}

.pr-181px,
.px-181px {
  padding-right: 181px !important;
}

.pr-182px,
.px-182px {
  padding-right: 182px !important;
}

.pr-183px,
.px-183px {
  padding-right: 183px !important;
}

.pr-184px,
.px-184px {
  padding-right: 184px !important;
}

.pr-185px,
.px-185px {
  padding-right: 185px !important;
}

.pr-186px,
.px-186px {
  padding-right: 186px !important;
}

.pr-187px,
.px-187px {
  padding-right: 187px !important;
}

.pr-188px,
.px-188px {
  padding-right: 188px !important;
}

.pr-189px,
.px-189px {
  padding-right: 189px !important;
}

.pr-190px,
.px-190px {
  padding-right: 190px !important;
}

.pr-191px,
.px-191px {
  padding-right: 191px !important;
}

.pr-192px,
.px-192px {
  padding-right: 192px !important;
}

.pr-193px,
.px-193px {
  padding-right: 193px !important;
}

.pr-194px,
.px-194px {
  padding-right: 194px !important;
}

.pr-195px,
.px-195px {
  padding-right: 195px !important;
}

.pr-196px,
.px-196px {
  padding-right: 196px !important;
}

.pr-197px,
.px-197px {
  padding-right: 197px !important;
}

.pr-198px,
.px-198px {
  padding-right: 198px !important;
}

.pr-199px,
.px-199px {
  padding-right: 199px !important;
}

.pr-200px,
.px-200px {
  padding-right: 200px !important;
}

// percent
.pr-0-percent {
  padding-right: 0% !important;
}
.pr-1-percent {
  padding-right: 1% !important;
}
.pr-2-percent {
  padding-right: 2% !important;
}
.pr-3-percent {
  padding-right: 3% !important;
}
.pr-4-percent {
  padding-right: 4% !important;
}
.pr-5-percent {
  padding-right: 5% !important;
}
.pr-6-percent {
  padding-right: 6% !important;
}
.pr-7-percent {
  padding-right: 7% !important;
}
.pr-8-percent {
  padding-right: 8% !important;
}
.pr-9-percent {
  padding-right: 9% !important;
}
.pr-10-percent {
  padding-right: 10% !important;
}
.pr-11-percent {
  padding-right: 11% !important;
}
.pr-12-percent {
  padding-right: 12% !important;
}
.pr-13-percent {
  padding-right: 13% !important;
}
.pr-14-percent {
  padding-right: 14% !important;
}
.pr-15-percent {
  padding-right: 15% !important;
}
.pr-16-percent {
  padding-right: 16% !important;
}
.pr-17-percent {
  padding-right: 17% !important;
}
.pr-18-percent {
  padding-right: 18% !important;
}
.pr-19-percent {
  padding-right: 19% !important;
}
.pr-20-percent {
  padding-right: 20% !important;
}
.pr-21-percent {
  padding-right: 21% !important;
}
.pr-22-percent {
  padding-right: 22% !important;
}
.pr-23-percent {
  padding-right: 23% !important;
}
.pr-24-percent {
  padding-right: 24% !important;
}
.pr-25-percent {
  padding-right: 25% !important;
}
.pr-26-percent {
  padding-right: 26% !important;
}
.pr-27-percent {
  padding-right: 27% !important;
}
.pr-28-percent {
  padding-right: 28% !important;
}
.pr-29-percent {
  padding-right: 29% !important;
}
.pr-30-percent {
  padding-right: 30% !important;
}
.pr-31-percent {
  padding-right: 31% !important;
}
.pr-32-percent {
  padding-right: 32% !important;
}
.pr-33-percent {
  padding-right: 33% !important;
}
.pr-34-percent {
  padding-right: 34% !important;
}
.pr-35-percent {
  padding-right: 35% !important;
}
.pr-36-percent {
  padding-right: 36% !important;
}
.pr-37-percent {
  padding-right: 37% !important;
}
.pr-38-percent {
  padding-right: 38% !important;
}
.pr-39-percent {
  padding-right: 39% !important;
}
.pr-40-percent {
  padding-right: 40% !important;
}
.pr-41-percent {
  padding-right: 41% !important;
}
.pr-42-percent {
  padding-right: 42% !important;
}
.pr-43-percent {
  padding-right: 43% !important;
}
.pr-44-percent {
  padding-right: 44% !important;
}
.pr-45-percent {
  padding-right: 45% !important;
}
.pr-46-percent {
  padding-right: 46% !important;
}
.pr-47-percent {
  padding-right: 47% !important;
}
.pr-48-percent {
  padding-right: 48% !important;
}
.pr-49-percent {
  padding-right: 49% !important;
}
.pr-50-percent {
  padding-right: 50% !important;
}
.pr-51-percent {
  padding-right: 51% !important;
}
.pr-52-percent {
  padding-right: 52% !important;
}
.pr-53-percent {
  padding-right: 53% !important;
}
.pr-54-percent {
  padding-right: 54% !important;
}
.pr-55-percent {
  padding-right: 55% !important;
}
.pr-56-percent {
  padding-right: 56% !important;
}
.pr-57-percent {
  padding-right: 57% !important;
}
.pr-58-percent {
  padding-right: 58% !important;
}
.pr-59-percent {
  padding-right: 59% !important;
}
.pr-60-percent {
  padding-right: 60% !important;
}
.pr-61-percent {
  padding-right: 61% !important;
}
.pr-62-percent {
  padding-right: 62% !important;
}
.pr-63-percent {
  padding-right: 63% !important;
}
.pr-64-percent {
  padding-right: 64% !important;
}
.pr-65-percent {
  padding-right: 65% !important;
}
.pr-66-percent {
  padding-right: 66% !important;
}
.pr-67-percent {
  padding-right: 67% !important;
}
.pr-68-percent {
  padding-right: 68% !important;
}
.pr-69-percent {
  padding-right: 69% !important;
}
.pr-70-percent {
  padding-right: 70% !important;
}
.pr-71-percent {
  padding-right: 71% !important;
}
.pr-72-percent {
  padding-right: 72% !important;
}
.pr-73-percent {
  padding-right: 73% !important;
}
.pr-74-percent {
  padding-right: 74% !important;
}
.pr-75-percent {
  padding-right: 75% !important;
}
.pr-76-percent {
  padding-right: 76% !important;
}
.pr-77-percent {
  padding-right: 77% !important;
}
.pr-78-percent {
  padding-right: 78% !important;
}
.pr-79-percent {
  padding-right: 79% !important;
}
.pr-80-percent {
  padding-right: 80% !important;
}
.pr-81-percent {
  padding-right: 81% !important;
}
.pr-82-percent {
  padding-right: 82% !important;
}
.pr-83-percent {
  padding-right: 83% !important;
}
.pr-84-percent {
  padding-right: 84% !important;
}
.pr-85-percent {
  padding-right: 85% !important;
}
.pr-86-percent {
  padding-right: 86% !important;
}
.pr-87-percent {
  padding-right: 87% !important;
}
.pr-88-percent {
  padding-right: 88% !important;
}
.pr-89-percent {
  padding-right: 89% !important;
}
.pr-90-percent {
  padding-right: 90% !important;
}
.pr-91-percent {
  padding-right: 91% !important;
}
.pr-92-percent {
  padding-right: 92% !important;
}
.pr-93-percent {
  padding-right: 93% !important;
}
.pr-94-percent {
  padding-right: 94% !important;
}
.pr-95-percent {
  padding-right: 95% !important;
}
.pr-96-percent {
  padding-right: 96% !important;
}
.pr-97-percent {
  padding-right: 97% !important;
}
.pr-98-percent {
  padding-right: 98% !important;
}
.pr-99-percent {
  padding-right: 99% !important;
}
.pr-100-percent {
  padding-right: 100% !important;
}

// rem
.pr-1-point-1rem,
.px-1-point-1rem {
  padding-right: 1.1rem !important;
}
