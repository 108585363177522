.border-style-hidden {
  border-style: hidden !important;
}
.border-style-dotted {
  border-style: dotted !important;
}
.border-style-solid {
  border-style: solid !important;
}
.border-style-dashed {
  border-style: dashed !important;
}

// bottom

.border-bottom-style-hidden {
  border-bottom-style: hidden !important;
}
.border-bottom-style-dotted {
  border-bottom-style: dotted !important;
}
.border-bottom-style-solid {
  border-bottom-style: solid !important;
}

// top

.border-top-style-hidden {
  border-top-style: hidden !important;
}
.border-top-style-dotted {
  border-top-style: dotted !important;
}
.border-top-style-solid {
  border-top-style: solid !important;
}

// left

.border-left-style-hidden {
  border-left-style: hidden !important;
}
.border-left-style-dotted {
  border-left-style: dotted !important;
}
.border-left-style-solid {
  border-left-style: solid !important;
}

// right

.border-right-style-hidden {
  border-right-style: hidden !important;
}
.border-right-style-dotted {
  border-right-style: dotted !important;
}
.border-right-style-solid {
  border-right-style: solid !important;
}
