$color-black: #000;
$color-aliceblue: aliceblue;
$color-f3f6f9: #f3f6f9;
$color-f1f4f7: #f1f4f7;
$color-f5f8fa: #f5f8fa;
$color-121212: #121212;
$color-fff5f8: #fff5f8;
$color-f9feff: #f9feff;
$color-f8f8f8: #f8f8f8;
$color-f5f8facc: #f5f8facc;
$color-eaf2ff: #eaf2ff;
$color-00000066: #00000066;

.bg-none {
  background: none !important;
}
.bg-transparent {
  background: transparent !important;
}

.bg-black {
  background: $color-black !important;
}
.bg-00000066 {
  background-color: $color-00000066 !important;
}

.bg-aliceblue {
  background: $color-aliceblue !important;
}
.bg-f3f6f9 {
  background: $color-f3f6f9 !important;
}

.bg-f1f4f7 {
  background: $color-f1f4f7 !important;
}

.bg-f5f8fa {
  background: $color-f5f8fa !important;
}

.bg-121212 {
  background: $color-121212 !important;
}

.bg-fff5f8 {
  background: $color-fff5f8 !important;
}

.bg-f9feff {
  background: $color-f9feff !important;
}

.bg-f8f8f8 {
  background: $color-f8f8f8 !important;
}
.bg-f5f8facc {
  background: $color-f5f8facc !important;
}

.bg-eaf2ff {
  background: $color-eaf2ff !important;
}

$color-f8f8f8: #f8f8f8;
.bg-f8f8f8 {
  background: $color-f8f8f8 !important;
}

$color-f1f1f1: #f1f1f1;
.bg-f1f1f1 {
  background: $color-f1f1f1 !important;
}

$color-1d8d5f: #1d8d5f;
.bg-1d8d5f {
  background: $color-1d8d5f !important;
}

$color-fffdf9: #fffdf9;
.bg-fffdf9 {
  background: $color-fffdf9 !important;
}

$color-fcfcfc: #fcfcfc;
.bg-fcfcfc {
  background: $color-fcfcfc !important;
}

$color-f7fafc: #f7fafc;
.bg-f7fafc {
  background: $color-f7fafc !important;
}

$color-ccf3ff: #ccf3ff;
.bg-ccf3ff {
  background: $color-ccf3ff !important;
}

$color-f9f9f9: #f9f9f9;
.bg-f9f9f9 {
  background: $color-f9f9f9 !important;
}

$color-fff4de: #fff4de;
.bg-fff4de {
  background: $color-fff4de !important;
}

$color-e0ecff: #e0ecff;
.bg-e0ecff {
  background: $color-e0ecff !important;
}

$color-d4f4e8: #d4f4e8;
.bg-d4f4e8 {
  background: $color-d4f4e8 !important;
}

$color-ffdae5: #ffdae5;
.bg-ffdae5 {
  background: $color-ffdae5 !important;
}

$color-ffeed3: #ffeed3;
.bg-ffeed3 {
  background: $color-ffeed3 !important;
}

$color-e5efff: #e5efff;
.bg-e5efff {
  background: $color-e5efff !important;
}

$color-ffdfff: #ffdfff;
.bg-ffdfff {
  background: $color-ffdfff !important;
}

$color-9898fa: #9898fa;
.bg-9898fa {
  background: $color-9898fa !important;
}

$color-ccd8ff: #ccd8ff;
.bg-ccd8ff {
  background: $color-ccd8ff !important;
}

$color-fecce6: #fecce6;
.bg-fecce6 {
  background: $color-fecce6 !important;
}

$color-d2fbf0: #d2fbf0;
.bg-d2fbf0 {
  background: $color-d2fbf0 !important;
}

$color-ffccb0: #ffccb0;
.bg-ffccb0 {
  background: $color-ffccb0 !important;
}

$color-0095f6: #0095f6;
.bg-0095f6 {
  background: $color-0095f6 !important;
}

$color-a3ffa6: #a3ffa6;
.bg-a3ffa6 {
  background: $color-a3ffa6 !important;
}

$color-ff94cc: #ff94cc;
.bg-ff94cc {
  background: $color-ff94cc !important;
}

$color-cfdaff: #cfdaff;
.bg-cfdaff {
  background: $color-cfdaff !important;
}

$color-4badfe: #4badfe;
.bg-4badfe {
  background: $color-4badfe !important;
}

$color-663259: #663259;
.bg-663259 {
  background-color: $color-663259 !important;
}

$color-73ce89: #73ce89;
.bg-73ce89 {
  background-color: $color-73ce89 !important;
}

$color-cdcdcd: #cdcdcd;
.bg-cdcdcd {
  background-color: $color-cdcdcd !important;
}

$color-ecf8ff: #ecf8ff;
.bg-ecf8ff {
  background-color: $color-ecf8ff !important;
}

$color-f5f8fa: #f5f8fa;
.bg-f5f8fa {
  background: $color-f5f8fa !important;
}

$color-ecf8ff: #ecf8ff;
.bg-ecf8ff {
  background-color: $color-ecf8ff !important;
}

$color-fafafc: #fafafc;
.bg-fafafc {
  background-color: $color-fafafc !important;
}

$color-f5f8fa99: #f5f8fa99;
.bg-f5f8fa99 {
  background-color: $color-f5f8fa99 !important;
}
