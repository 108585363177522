$color: #f5f5f5;
.border-color-e4e6ef {
  border-color: #e4e6ef !important;
}

.border-color-523f6914 {
  border-color: #523f6914 !important;
}

.border-color-lightgray {
  border-color: lightgray !important;
}

.border-color-ebedf3 {
  border-color: #ebedf3 !important;
}

.border-color-eeeeee {
  border-color: #eeeeee !important;
}

.border-color-0177c9 {
  border-color: #0177c9 !important;
}

.border-color-ffa800 {
  border-color: #ffa800 !important;
}

.border-color-018cf9 {
  border-color: #018cf9 !important;
}

.border-color-8950fc {
  border-color: #8950fc !important;
}

.border-color-e0e0e0 {
  border-color: #e0e0e0 !important;
}

.border-color-aee7f7 {
  border-color: #aee7f7 !important;
}

.border-color-3699FF {
  border-color: #3699ff !important;
}

.border-color-0095F6 {
  border-color: #0095f6 !important;
}

.border-color-E1E1E1 {
  border-color: #e1e1e1 !important;
}

.border-color-009ef7 {
  border-color: #009ef7 !important;
}

.border-color-cdcdcd {
  border-color: #cdcdcd !important;
}

.border-color-f1416c {
  border-color: #f1416c !important;
}

.border-color-f5f5f5 {
  border-color: #f5f5f5 !important;
}

// bottom
.border-bottom-color-dcdddd {
  border-bottom-color: #dcdddd !important;
}

.border-bottom-color-ebedf3 {
  border-bottom-color: #ebedf3 !important;
}

.border-bottom-color-000 {
  border-bottom-color: #000 !important;
}

.border-bottom-color-02507a {
  border-bottom-color: #02507a !important;
}

// top
.border-top-color-523f6914 {
  border-top-color: #523f6914 !important;
}

.border-top-color-lightgray {
  border-top-color: lightgray !important;
}

.border-top-color-ebedf3 {
  border-top-color: #ebedf3 !important;
}

.border-top-color-eeeeee {
  border-top-color: #eeeeee !important;
}

// left
.border-left-color-523f6914 {
  border-left-color: #523f6914 !important;
}

.border-left-color-lightgray {
  border-left-color: lightgray !important;
}

.border-left-color-ebedf3 {
  border-left-color: #ebedf3 !important;
}

.border-left-color-eeeeee {
  border-left-color: #eeeeee !important;
}

.border-left-color-0177c9 {
  border-left-color: #0177c9 !important;
}

// right
.border-right-color-lightgrey {
  border-right-color: lightgrey !important;
}

.border-right-color-eaecee {
  border-right-color: #eaecee !important;
}
