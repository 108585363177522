.mr-0px,
.mx-0px {
  margin-right: 0px !important;
}

.mr-1px,
.mx-1px {
  margin-right: 1px !important;
}

.mr-2px,
.mx-2px {
  margin-right: 2px !important;
}

.mr-3px,
.mx-3px {
  margin-right: 3px !important;
}

.mr-4px,
.mx-4px {
  margin-right: 4px !important;
}

.mr-5px,
.mx-5px {
  margin-right: 5px !important;
}

.mr-6px,
.mx-6px {
  margin-right: 6px !important;
}

.mr-7px,
.mx-7px {
  margin-right: 7px !important;
}

.mr-8px,
.mx-8px {
  margin-right: 8px !important;
}

.mr-9px,
.mx-9px {
  margin-right: 9px !important;
}

.mr-10px,
.mx-10px {
  margin-right: 10px !important;
}

.mr-11px,
.mx-11px {
  margin-right: 11px !important;
}

.mr-12px,
.mx-12px {
  margin-right: 12px !important;
}

.mr-13px,
.mx-13px {
  margin-right: 13px !important;
}

.mr-14px,
.mx-14px {
  margin-right: 14px !important;
}

.mr-15px,
.mx-15px {
  margin-right: 15px !important;
}

.mr-16px,
.mx-16px {
  margin-right: 16px !important;
}

.mr-17px,
.mx-17px {
  margin-right: 17px !important;
}

.mr-18px,
.mx-18px {
  margin-right: 18px !important;
}

.mr-19px,
.mx-19px {
  margin-right: 19px !important;
}

.mr-20px,
.mx-20px {
  margin-right: 20px !important;
}

.mr-21px,
.mx-21px {
  margin-right: 21px !important;
}

.mr-22px,
.mx-22px {
  margin-right: 22px !important;
}

.mr-23px,
.mx-23px {
  margin-right: 23px !important;
}

.mr-24px,
.mx-24px {
  margin-right: 24px !important;
}

.mr-25px,
.mx-25px {
  margin-right: 25px !important;
}

.mr-26px,
.mx-26px {
  margin-right: 26px !important;
}

.mr-27px,
.mx-27px {
  margin-right: 27px !important;
}

.mr-28px,
.mx-28px {
  margin-right: 28px !important;
}

.mr-29px,
.mx-29px {
  margin-right: 29px !important;
}

.mr-30px,
.mx-30px {
  margin-right: 30px !important;
}

.mr-31px,
.mx-31px {
  margin-right: 31px !important;
}

.mr-32px,
.mx-32px {
  margin-right: 32px !important;
}

.mr-33px,
.mx-33px {
  margin-right: 33px !important;
}

.mr-34px,
.mx-34px {
  margin-right: 34px !important;
}

.mr-35px,
.mx-35px {
  margin-right: 35px !important;
}

.mr-36px,
.mx-36px {
  margin-right: 36px !important;
}

.mr-37px,
.mx-37px {
  margin-right: 37px !important;
}

.mr-38px,
.mx-38px {
  margin-right: 38px !important;
}

.mr-39px,
.mx-39px {
  margin-right: 39px !important;
}

.mr-40px,
.mx-40px {
  margin-right: 40px !important;
}

.mr-41px,
.mx-41px {
  margin-right: 41px !important;
}

.mr-42px,
.mx-42px {
  margin-right: 42px !important;
}

.mr-43px,
.mx-43px {
  margin-right: 43px !important;
}

.mr-44px,
.mx-44px {
  margin-right: 44px !important;
}

.mr-45px,
.mx-45px {
  margin-right: 45px !important;
}

.mr-46px,
.mx-46px {
  margin-right: 46px !important;
}

.mr-47px,
.mx-47px {
  margin-right: 47px !important;
}

.mr-48px,
.mx-48px {
  margin-right: 48px !important;
}

.mr-49px,
.mx-49px {
  margin-right: 49px !important;
}

.mr-50px,
.mx-50px {
  margin-right: 50px !important;
}

.mr-51px,
.mx-51px {
  margin-right: 51px !important;
}

.mr-52px,
.mx-52px {
  margin-right: 52px !important;
}

.mr-53px,
.mx-53px {
  margin-right: 53px !important;
}

.mr-54px,
.mx-54px {
  margin-right: 54px !important;
}

.mr-55px,
.mx-55px {
  margin-right: 55px !important;
}

.mr-56px,
.mx-56px {
  margin-right: 56px !important;
}

.mr-57px,
.mx-57px {
  margin-right: 57px !important;
}

.mr-58px,
.mx-58px {
  margin-right: 58px !important;
}

.mr-59px,
.mx-59px {
  margin-right: 59px !important;
}

.mr-60px,
.mx-60px {
  margin-right: 60px !important;
}

.mr-61px,
.mx-61px {
  margin-right: 61px !important;
}

.mr-62px,
.mx-62px {
  margin-right: 62px !important;
}

.mr-63px,
.mx-63px {
  margin-right: 63px !important;
}

.mr-64px,
.mx-64px {
  margin-right: 64px !important;
}

.mr-65px,
.mx-65px {
  margin-right: 65px !important;
}

.mr-66px,
.mx-66px {
  margin-right: 66px !important;
}

.mr-67px,
.mx-67px {
  margin-right: 67px !important;
}

.mr-68px,
.mx-68px {
  margin-right: 68px !important;
}

.mr-69px,
.mx-69px {
  margin-right: 69px !important;
}

.mr-70px,
.mx-70px {
  margin-right: 70px !important;
}

.mr-71px,
.mx-71px {
  margin-right: 71px !important;
}

.mr-72px,
.mx-72px {
  margin-right: 72px !important;
}

.mr-73px,
.mx-73px {
  margin-right: 73px !important;
}

.mr-74px,
.mx-74px {
  margin-right: 74px !important;
}

.mr-75px,
.mx-75px {
  margin-right: 75px !important;
}

.mr-76px,
.mx-76px {
  margin-right: 76px !important;
}

.mr-77px,
.mx-77px {
  margin-right: 77px !important;
}

.mr-78px,
.mx-78px {
  margin-right: 78px !important;
}

.mr-79px,
.mx-79px {
  margin-right: 79px !important;
}

.mr-80px,
.mx-80px {
  margin-right: 80px !important;
}

.mr-81px,
.mx-81px {
  margin-right: 81px !important;
}

.mr-82px,
.mx-82px {
  margin-right: 82px !important;
}

.mr-83px,
.mx-83px {
  margin-right: 83px !important;
}

.mr-84px,
.mx-84px {
  margin-right: 84px !important;
}

.mr-85px,
.mx-85px {
  margin-right: 85px !important;
}

.mr-86px,
.mx-86px {
  margin-right: 86px !important;
}

.mr-87px,
.mx-87px {
  margin-right: 87px !important;
}

.mr-88px,
.mx-88px {
  margin-right: 88px !important;
}

.mr-89px,
.mx-89px {
  margin-right: 89px !important;
}

.mr-90px,
.mx-90px {
  margin-right: 90px !important;
}

.mr-91px,
.mx-91px {
  margin-right: 91px !important;
}

.mr-92px,
.mx-92px {
  margin-right: 92px !important;
}

.mr-93px,
.mx-93px {
  margin-right: 93px !important;
}

.mr-94px,
.mx-94px {
  margin-right: 94px !important;
}

.mr-95px,
.mx-95px {
  margin-right: 95px !important;
}

.mr-96px,
.mx-96px {
  margin-right: 96px !important;
}

.mr-97px,
.mx-97px {
  margin-right: 97px !important;
}

.mr-98px,
.mx-98px {
  margin-right: 98px !important;
}

.mr-99px,
.mx-99px {
  margin-right: 99px !important;
}

.mr-100px,
.mx-100px {
  margin-right: 100px !important;
}

.mr-101px,
.mx-101px {
  margin-right: 101px !important;
}

.mr-102px,
.mx-102px {
  margin-right: 102px !important;
}

.mr-103px,
.mx-103px {
  margin-right: 103px !important;
}

.mr-104px,
.mx-104px {
  margin-right: 104px !important;
}

.mr-105px,
.mx-105px {
  margin-right: 105px !important;
}

.mr-106px,
.mx-106px {
  margin-right: 106px !important;
}

.mr-107px,
.mx-107px {
  margin-right: 107px !important;
}

.mr-108px,
.mx-108px {
  margin-right: 108px !important;
}

.mr-109px,
.mx-109px {
  margin-right: 109px !important;
}

.mr-110px,
.mx-110px {
  margin-right: 110px !important;
}

.mr-111px,
.mx-111px {
  margin-right: 111px !important;
}

.mr-112px,
.mx-112px {
  margin-right: 112px !important;
}

.mr-113px,
.mx-113px {
  margin-right: 113px !important;
}

.mr-114px,
.mx-114px {
  margin-right: 114px !important;
}

.mr-115px,
.mx-115px {
  margin-right: 115px !important;
}

.mr-116px,
.mx-116px {
  margin-right: 116px !important;
}

.mr-117px,
.mx-117px {
  margin-right: 117px !important;
}

.mr-118px,
.mx-118px {
  margin-right: 118px !important;
}

.mr-119px,
.mx-119px {
  margin-right: 119px !important;
}

.mr-120px,
.mx-120px {
  margin-right: 120px !important;
}

.mr-121px,
.mx-121px {
  margin-right: 121px !important;
}

.mr-122px,
.mx-122px {
  margin-right: 122px !important;
}

.mr-123px,
.mx-123px {
  margin-right: 123px !important;
}

.mr-124px,
.mx-124px {
  margin-right: 124px !important;
}

.mr-125px,
.mx-125px {
  margin-right: 125px !important;
}

.mr-126px,
.mx-126px {
  margin-right: 126px !important;
}

.mr-127px,
.mx-127px {
  margin-right: 127px !important;
}

.mr-128px,
.mx-128px {
  margin-right: 128px !important;
}

.mr-129px,
.mx-129px {
  margin-right: 129px !important;
}

.mr-130px,
.mx-130px {
  margin-right: 130px !important;
}

.mr-131px,
.mx-131px {
  margin-right: 131px !important;
}

.mr-132px,
.mx-132px {
  margin-right: 132px !important;
}

.mr-133px,
.mx-133px {
  margin-right: 133px !important;
}

.mr-134px,
.mx-134px {
  margin-right: 134px !important;
}

.mr-135px,
.mx-135px {
  margin-right: 135px !important;
}

.mr-136px,
.mx-136px {
  margin-right: 136px !important;
}

.mr-137px,
.mx-137px {
  margin-right: 137px !important;
}

.mr-138px,
.mx-138px {
  margin-right: 138px !important;
}

.mr-139px,
.mx-139px {
  margin-right: 139px !important;
}

.mr-140px,
.mx-140px {
  margin-right: 140px !important;
}

.mr-141px,
.mx-141px {
  margin-right: 141px !important;
}

.mr-142px,
.mx-142px {
  margin-right: 142px !important;
}

.mr-143px,
.mx-143px {
  margin-right: 143px !important;
}

.mr-144px,
.mx-144px {
  margin-right: 144px !important;
}

.mr-145px,
.mx-145px {
  margin-right: 145px !important;
}

.mr-146px,
.mx-146px {
  margin-right: 146px !important;
}

.mr-147px,
.mx-147px {
  margin-right: 147px !important;
}

.mr-148px,
.mx-148px {
  margin-right: 148px !important;
}

.mr-149px,
.mx-149px {
  margin-right: 149px !important;
}

.mr-150px,
.mx-150px {
  margin-right: 150px !important;
}

.mr-151px,
.mx-151px {
  margin-right: 151px !important;
}

.mr-152px,
.mx-152px {
  margin-right: 152px !important;
}

.mr-153px,
.mx-153px {
  margin-right: 153px !important;
}

.mr-154px,
.mx-154px {
  margin-right: 154px !important;
}

.mr-155px,
.mx-155px {
  margin-right: 155px !important;
}

.mr-156px,
.mx-156px {
  margin-right: 156px !important;
}

.mr-157px,
.mx-157px {
  margin-right: 157px !important;
}

.mr-158px,
.mx-158px {
  margin-right: 158px !important;
}

.mr-159px,
.mx-159px {
  margin-right: 159px !important;
}

.mr-160px,
.mx-160px {
  margin-right: 160px !important;
}

.mr-161px,
.mx-161px {
  margin-right: 161px !important;
}

.mr-162px,
.mx-162px {
  margin-right: 162px !important;
}

.mr-163px,
.mx-163px {
  margin-right: 163px !important;
}

.mr-164px,
.mx-164px {
  margin-right: 164px !important;
}

.mr-165px,
.mx-165px {
  margin-right: 165px !important;
}

.mr-166px,
.mx-166px {
  margin-right: 166px !important;
}

.mr-167px,
.mx-167px {
  margin-right: 167px !important;
}

.mr-168px,
.mx-168px {
  margin-right: 168px !important;
}

.mr-169px,
.mx-169px {
  margin-right: 169px !important;
}

.mr-170px,
.mx-170px {
  margin-right: 170px !important;
}

.mr-171px,
.mx-171px {
  margin-right: 171px !important;
}

.mr-172px,
.mx-172px {
  margin-right: 172px !important;
}

.mr-173px,
.mx-173px {
  margin-right: 173px !important;
}

.mr-174px,
.mx-174px {
  margin-right: 174px !important;
}

.mr-175px,
.mx-175px {
  margin-right: 175px !important;
}

.mr-176px,
.mx-176px {
  margin-right: 176px !important;
}

.mr-177px,
.mx-177px {
  margin-right: 177px !important;
}

.mr-178px,
.mx-178px {
  margin-right: 178px !important;
}

.mr-179px,
.mx-179px {
  margin-right: 179px !important;
}

.mr-180px,
.mx-180px {
  margin-right: 180px !important;
}

.mr-181px,
.mx-181px {
  margin-right: 181px !important;
}

.mr-182px,
.mx-182px {
  margin-right: 182px !important;
}

.mr-183px,
.mx-183px {
  margin-right: 183px !important;
}

.mr-184px,
.mx-184px {
  margin-right: 184px !important;
}

.mr-185px,
.mx-185px {
  margin-right: 185px !important;
}

.mr-186px,
.mx-186px {
  margin-right: 186px !important;
}

.mr-187px,
.mx-187px {
  margin-right: 187px !important;
}

.mr-188px,
.mx-188px {
  margin-right: 188px !important;
}

.mr-189px,
.mx-189px {
  margin-right: 189px !important;
}

.mr-190px,
.mx-190px {
  margin-right: 190px !important;
}

.mr-191px,
.mx-191px {
  margin-right: 191px !important;
}

.mr-192px,
.mx-192px {
  margin-right: 192px !important;
}

.mr-193px,
.mx-193px {
  margin-right: 193px !important;
}

.mr-194px,
.mx-194px {
  margin-right: 194px !important;
}

.mr-195px,
.mx-195px {
  margin-right: 195px !important;
}

.mr-196px,
.mx-196px {
  margin-right: 196px !important;
}

.mr-197px,
.mx-197px {
  margin-right: 197px !important;
}

.mr-198px,
.mx-198px {
  margin-right: 198px !important;
}

.mr-199px,
.mx-199px {
  margin-right: 199px !important;
}

.mr-200px,
.mx-200px {
  margin-right: 200px !important;
}

// negative values
.mr-minus-35px {
  margin-right: -35px;
}
