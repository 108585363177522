.ml-0px,
.mx-0px {
  margin-left: 0px !important;
}

.ml-1px,
.mx-1px {
  margin-left: 1px !important;
}

.ml-2px,
.mx-2px {
  margin-left: 2px !important;
}

.ml-3px,
.mx-3px {
  margin-left: 3px !important;
}

.ml-4px,
.mx-4px {
  margin-left: 4px !important;
}

.ml-5px,
.mx-5px {
  margin-left: 5px !important;
}

.ml-6px,
.mx-6px {
  margin-left: 6px !important;
}

.ml-7px,
.mx-7px {
  margin-left: 7px !important;
}

.ml-8px,
.mx-8px {
  margin-left: 8px !important;
}

.ml-9px,
.mx-9px {
  margin-left: 9px !important;
}

.ml-10px,
.mx-10px {
  margin-left: 10px !important;
}

.ml-11px,
.mx-11px {
  margin-left: 11px !important;
}

.ml-12px,
.mx-12px {
  margin-left: 12px !important;
}

.ml-13px,
.mx-13px {
  margin-left: 13px !important;
}

.ml-14px,
.mx-14px {
  margin-left: 14px !important;
}

.ml-15px,
.mx-15px {
  margin-left: 15px !important;
}

.ml-16px,
.mx-16px {
  margin-left: 16px !important;
}

.ml-17px,
.mx-17px {
  margin-left: 17px !important;
}

.ml-18px,
.mx-18px {
  margin-left: 18px !important;
}

.ml-19px,
.mx-19px {
  margin-left: 19px !important;
}

.ml-20px,
.mx-20px {
  margin-left: 20px !important;
}

.ml-21px,
.mx-21px {
  margin-left: 21px !important;
}

.ml-22px,
.mx-22px {
  margin-left: 22px !important;
}

.ml-23px,
.mx-23px {
  margin-left: 23px !important;
}

.ml-24px,
.mx-24px {
  margin-left: 24px !important;
}

.ml-25px,
.mx-25px {
  margin-left: 25px !important;
}

.ml-26px,
.mx-26px {
  margin-left: 26px !important;
}

.ml-27px,
.mx-27px {
  margin-left: 27px !important;
}

.ml-28px,
.mx-28px {
  margin-left: 28px !important;
}

.ml-29px,
.mx-29px {
  margin-left: 29px !important;
}

.ml-30px,
.mx-30px {
  margin-left: 30px !important;
}

.ml-31px,
.mx-31px {
  margin-left: 31px !important;
}

.ml-32px,
.mx-32px {
  margin-left: 32px !important;
}

.ml-33px,
.mx-33px {
  margin-left: 33px !important;
}

.ml-34px,
.mx-34px {
  margin-left: 34px !important;
}

.ml-35px,
.mx-35px {
  margin-left: 35px !important;
}

.ml-36px,
.mx-36px {
  margin-left: 36px !important;
}

.ml-37px,
.mx-37px {
  margin-left: 37px !important;
}

.ml-38px,
.mx-38px {
  margin-left: 38px !important;
}

.ml-39px,
.mx-39px {
  margin-left: 39px !important;
}

.ml-40px,
.mx-40px {
  margin-left: 40px !important;
}

.ml-41px,
.mx-41px {
  margin-left: 41px !important;
}

.ml-42px,
.mx-42px {
  margin-left: 42px !important;
}

.ml-43px,
.mx-43px {
  margin-left: 43px !important;
}

.ml-44px,
.mx-44px {
  margin-left: 44px !important;
}

.ml-45px,
.mx-45px {
  margin-left: 45px !important;
}

.ml-46px,
.mx-46px {
  margin-left: 46px !important;
}

.ml-47px,
.mx-47px {
  margin-left: 47px !important;
}

.ml-48px,
.mx-48px {
  margin-left: 48px !important;
}

.ml-49px,
.mx-49px {
  margin-left: 49px !important;
}

.ml-50px,
.mx-50px {
  margin-left: 50px !important;
}

.ml-51px,
.mx-51px {
  margin-left: 51px !important;
}

.ml-52px,
.mx-52px {
  margin-left: 52px !important;
}

.ml-53px,
.mx-53px {
  margin-left: 53px !important;
}

.ml-54px,
.mx-54px {
  margin-left: 54px !important;
}

.ml-55px,
.mx-55px {
  margin-left: 55px !important;
}

.ml-56px,
.mx-56px {
  margin-left: 56px !important;
}

.ml-57px,
.mx-57px {
  margin-left: 57px !important;
}

.ml-58px,
.mx-58px {
  margin-left: 58px !important;
}

.ml-59px,
.mx-59px {
  margin-left: 59px !important;
}

.ml-60px,
.mx-60px {
  margin-left: 60px !important;
}

.ml-61px,
.mx-61px {
  margin-left: 61px !important;
}

.ml-62px,
.mx-62px {
  margin-left: 62px !important;
}

.ml-63px,
.mx-63px {
  margin-left: 63px !important;
}

.ml-64px,
.mx-64px {
  margin-left: 64px !important;
}

.ml-65px,
.mx-65px {
  margin-left: 65px !important;
}

.ml-66px,
.mx-66px {
  margin-left: 66px !important;
}

.ml-67px,
.mx-67px {
  margin-left: 67px !important;
}

.ml-68px,
.mx-68px {
  margin-left: 68px !important;
}

.ml-69px,
.mx-69px {
  margin-left: 69px !important;
}

.ml-70px,
.mx-70px {
  margin-left: 70px !important;
}

.ml-71px,
.mx-71px {
  margin-left: 71px !important;
}

.ml-72px,
.mx-72px {
  margin-left: 72px !important;
}

.ml-73px,
.mx-73px {
  margin-left: 73px !important;
}

.ml-74px,
.mx-74px {
  margin-left: 74px !important;
}

.ml-75px,
.mx-75px {
  margin-left: 75px !important;
}

.ml-76px,
.mx-76px {
  margin-left: 76px !important;
}

.ml-77px,
.mx-77px {
  margin-left: 77px !important;
}

.ml-78px,
.mx-78px {
  margin-left: 78px !important;
}

.ml-79px,
.mx-79px {
  margin-left: 79px !important;
}

.ml-80px,
.mx-80px {
  margin-left: 80px !important;
}

.ml-81px,
.mx-81px {
  margin-left: 81px !important;
}

.ml-82px,
.mx-82px {
  margin-left: 82px !important;
}

.ml-83px,
.mx-83px {
  margin-left: 83px !important;
}

.ml-84px,
.mx-84px {
  margin-left: 84px !important;
}

.ml-85px,
.mx-85px {
  margin-left: 85px !important;
}

.ml-86px,
.mx-86px {
  margin-left: 86px !important;
}

.ml-87px,
.mx-87px {
  margin-left: 87px !important;
}

.ml-88px,
.mx-88px {
  margin-left: 88px !important;
}

.ml-89px,
.mx-89px {
  margin-left: 89px !important;
}

.ml-90px,
.mx-90px {
  margin-left: 90px !important;
}

.ml-91px,
.mx-91px {
  margin-left: 91px !important;
}

.ml-92px,
.mx-92px {
  margin-left: 92px !important;
}

.ml-93px,
.mx-93px {
  margin-left: 93px !important;
}

.ml-94px,
.mx-94px {
  margin-left: 94px !important;
}

.ml-95px,
.mx-95px {
  margin-left: 95px !important;
}

.ml-96px,
.mx-96px {
  margin-left: 96px !important;
}

.ml-97px,
.mx-97px {
  margin-left: 97px !important;
}

.ml-98px,
.mx-98px {
  margin-left: 98px !important;
}

.ml-99px,
.mx-99px {
  margin-left: 99px !important;
}

.ml-100px,
.mx-100px {
  margin-left: 100px !important;
}

.ml-101px,
.mx-101px {
  margin-left: 101px !important;
}

.ml-102px,
.mx-102px {
  margin-left: 102px !important;
}

.ml-103px,
.mx-103px {
  margin-left: 103px !important;
}

.ml-104px,
.mx-104px {
  margin-left: 104px !important;
}

.ml-105px,
.mx-105px {
  margin-left: 105px !important;
}

.ml-106px,
.mx-106px {
  margin-left: 106px !important;
}

.ml-107px,
.mx-107px {
  margin-left: 107px !important;
}

.ml-108px,
.mx-108px {
  margin-left: 108px !important;
}

.ml-109px,
.mx-109px {
  margin-left: 109px !important;
}

.ml-110px,
.mx-110px {
  margin-left: 110px !important;
}

.ml-111px,
.mx-111px {
  margin-left: 111px !important;
}

.ml-112px,
.mx-112px {
  margin-left: 112px !important;
}

.ml-113px,
.mx-113px {
  margin-left: 113px !important;
}

.ml-114px,
.mx-114px {
  margin-left: 114px !important;
}

.ml-115px,
.mx-115px {
  margin-left: 115px !important;
}

.ml-116px,
.mx-116px {
  margin-left: 116px !important;
}

.ml-117px,
.mx-117px {
  margin-left: 117px !important;
}

.ml-118px,
.mx-118px {
  margin-left: 118px !important;
}

.ml-119px,
.mx-119px {
  margin-left: 119px !important;
}

.ml-120px,
.mx-120px {
  margin-left: 120px !important;
}

.ml-121px,
.mx-121px {
  margin-left: 121px !important;
}

.ml-122px,
.mx-122px {
  margin-left: 122px !important;
}

.ml-123px,
.mx-123px {
  margin-left: 123px !important;
}

.ml-124px,
.mx-124px {
  margin-left: 124px !important;
}

.ml-125px,
.mx-125px {
  margin-left: 125px !important;
}

.ml-126px,
.mx-126px {
  margin-left: 126px !important;
}

.ml-127px,
.mx-127px {
  margin-left: 127px !important;
}

.ml-128px,
.mx-128px {
  margin-left: 128px !important;
}

.ml-129px,
.mx-129px {
  margin-left: 129px !important;
}

.ml-130px,
.mx-130px {
  margin-left: 130px !important;
}

.ml-131px,
.mx-131px {
  margin-left: 131px !important;
}

.ml-132px,
.mx-132px {
  margin-left: 132px !important;
}

.ml-133px,
.mx-133px {
  margin-left: 133px !important;
}

.ml-134px,
.mx-134px {
  margin-left: 134px !important;
}

.ml-135px,
.mx-135px {
  margin-left: 135px !important;
}

.ml-136px,
.mx-136px {
  margin-left: 136px !important;
}

.ml-137px,
.mx-137px {
  margin-left: 137px !important;
}

.ml-138px,
.mx-138px {
  margin-left: 138px !important;
}

.ml-139px,
.mx-139px {
  margin-left: 139px !important;
}

.ml-140px,
.mx-140px {
  margin-left: 140px !important;
}

.ml-141px,
.mx-141px {
  margin-left: 141px !important;
}

.ml-142px,
.mx-142px {
  margin-left: 142px !important;
}

.ml-143px,
.mx-143px {
  margin-left: 143px !important;
}

.ml-144px,
.mx-144px {
  margin-left: 144px !important;
}

.ml-145px,
.mx-145px {
  margin-left: 145px !important;
}

.ml-146px,
.mx-146px {
  margin-left: 146px !important;
}

.ml-147px,
.mx-147px {
  margin-left: 147px !important;
}

.ml-148px,
.mx-148px {
  margin-left: 148px !important;
}

.ml-149px,
.mx-149px {
  margin-left: 149px !important;
}

.ml-150px,
.mx-150px {
  margin-left: 150px !important;
}

.ml-151px,
.mx-151px {
  margin-left: 151px !important;
}

.ml-152px,
.mx-152px {
  margin-left: 152px !important;
}

.ml-153px,
.mx-153px {
  margin-left: 153px !important;
}

.ml-154px,
.mx-154px {
  margin-left: 154px !important;
}

.ml-155px,
.mx-155px {
  margin-left: 155px !important;
}

.ml-156px,
.mx-156px {
  margin-left: 156px !important;
}

.ml-157px,
.mx-157px {
  margin-left: 157px !important;
}

.ml-158px,
.mx-158px {
  margin-left: 158px !important;
}

.ml-159px,
.mx-159px {
  margin-left: 159px !important;
}

.ml-160px,
.mx-160px {
  margin-left: 160px !important;
}

.ml-161px,
.mx-161px {
  margin-left: 161px !important;
}

.ml-162px,
.mx-162px {
  margin-left: 162px !important;
}

.ml-163px,
.mx-163px {
  margin-left: 163px !important;
}

.ml-164px,
.mx-164px {
  margin-left: 164px !important;
}

.ml-165px,
.mx-165px {
  margin-left: 165px !important;
}

.ml-166px,
.mx-166px {
  margin-left: 166px !important;
}

.ml-167px,
.mx-167px {
  margin-left: 167px !important;
}

.ml-168px,
.mx-168px {
  margin-left: 168px !important;
}

.ml-169px,
.mx-169px {
  margin-left: 169px !important;
}

.ml-170px,
.mx-170px {
  margin-left: 170px !important;
}

.ml-171px,
.mx-171px {
  margin-left: 171px !important;
}

.ml-172px,
.mx-172px {
  margin-left: 172px !important;
}

.ml-173px,
.mx-173px {
  margin-left: 173px !important;
}

.ml-174px,
.mx-174px {
  margin-left: 174px !important;
}

.ml-175px,
.mx-175px {
  margin-left: 175px !important;
}

.ml-176px,
.mx-176px {
  margin-left: 176px !important;
}

.ml-177px,
.mx-177px {
  margin-left: 177px !important;
}

.ml-178px,
.mx-178px {
  margin-left: 178px !important;
}

.ml-179px,
.mx-179px {
  margin-left: 179px !important;
}

.ml-180px,
.mx-180px {
  margin-left: 180px !important;
}

.ml-181px,
.mx-181px {
  margin-left: 181px !important;
}

.ml-182px,
.mx-182px {
  margin-left: 182px !important;
}

.ml-183px,
.mx-183px {
  margin-left: 183px !important;
}

.ml-184px,
.mx-184px {
  margin-left: 184px !important;
}

.ml-185px,
.mx-185px {
  margin-left: 185px !important;
}

.ml-186px,
.mx-186px {
  margin-left: 186px !important;
}

.ml-187px,
.mx-187px {
  margin-left: 187px !important;
}

.ml-188px,
.mx-188px {
  margin-left: 188px !important;
}

.ml-189px,
.mx-189px {
  margin-left: 189px !important;
}

.ml-190px,
.mx-190px {
  margin-left: 190px !important;
}

.ml-191px,
.mx-191px {
  margin-left: 191px !important;
}

.ml-192px,
.mx-192px {
  margin-left: 192px !important;
}

.ml-193px,
.mx-193px {
  margin-left: 193px !important;
}

.ml-194px,
.mx-194px {
  margin-left: 194px !important;
}

.ml-195px,
.mx-195px {
  margin-left: 195px !important;
}

.ml-196px,
.mx-196px {
  margin-left: 196px !important;
}

.ml-197px,
.mx-197px {
  margin-left: 197px !important;
}

.ml-198px,
.mx-198px {
  margin-left: 198px !important;
}

.ml-199px,
.mx-199px {
  margin-left: 199px !important;
}

.ml-200px,
.mx-200px {
  margin-left: 200px !important;
}

// percentage
.ml-30-percent {
  margin-left: 30% !important;
}
.ml-44-percent {
  margin-left: 44% !important;
}
.ml-85-percent {
  margin-left: 85% !important;
}
.ml-205-percent {
  margin-left: 205% !important;
}

// negative values
.ml-minus-16px {
  margin-left: -16px;
}
.ml-minus-22px {
  margin-left: -22px;
}
