.data-container {
  margin-right: 10px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-container2 {
  max-width: 250px;
  display: flex;
  align-items: center;
}
.data-container-2-text {
  max-width: 200px;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.data-container-2-icon {
  margin-right: 10px;
}

.data-flex-container {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  max-width: 260px;
}
.data-flex-container-2 {
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  word-break: break-all;
}

.data-sidepanel-flex-container {
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  /* width: calc(100%); */
  max-width: 380px;
}
.stage-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: rgb(235 237 243) !important;
}
.stage-dot-last {
  height: 8px;
  width: 24px;
  border-radius: 25px;
  display: inline-block;
  margin-right: 5px;
  /* background: rgb(235 237 243) !important; */
}
