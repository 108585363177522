// px
.w-200px-mx {
  max-width: 200px !important;
}
.w-250px-mx {
  max-width: 250px !important;
}
.w-290px-mx {
  max-width: 290px !important;
}
.w-310px-mx {
  max-width: 310px !important;
}
.w-350px-mx {
  max-width: 350px !important;
}
.w-400px-mx {
  max-width: 400px !important;
}
.w-410px-mx {
  max-width: 410px !important;
}
.w-440px-mx {
  max-width: 440px !important;
}
.w-500px-mx {
  max-width: 500px !important;
}
.w-600px-mx {
  max-width: 600px !important;
}
.w-980px-mx {
  max-width: 980px !important;
}
.w-1080px-mx {
  max-width: 1080px !important;
}

/* max width in vw */
.w-10vw-mx {
  max-width: 10vw !important;
}

.w-20vw-mx {
  max-width: 20vw !important;
}

.w-30vw-mx {
  max-width: 30vw !important;
}

.w-40vw-mx {
  max-width: 40vw !important;
}

.w-50vw-mx {
  max-width: 50vw !important;
}

.w-60vw-mx {
  max-width: 60vw !important;
}

.w-70vw-mx {
  max-width: 70vw !important;
}

.w-80vw-mx {
  max-width: 80vw !important;
}

.w-90vw-mx {
  max-width: 90vw !important;
}

.w-100vw-mx {
  max-width: 100vw !important;
}

// percent
.w-90-percent-mx {
  max-width: 90% !important;
}
.w-100-percent-mx {
  max-width: 100% !important;
}
